import * as React from 'react'

const defaultState = {
    contracts: [],
    contractFormDialogState: { 
        open: false, 
        dialogType: '', 
        selectedCustomer: null, 
        selectedContract: null 
    }
}

const ContractsContext = React.createContext()

function contractsReducer(state, action) {
    switch (action.type) {
        case 'SET_CONTRACTS': {
            const { contracts } = action.payload;
            return { ...state, contracts: contracts }
        }
       case 'SET_CONTRACT_FORM_DIALOG_STATE': {
            return { ...state, contractFormDialogState: action.payload }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function ContractsProvider({ children }) {
    const [state, dispatch] = React.useReducer(contractsReducer, defaultState)
    // NOTE: you *might* need to memoize this value
    // Learn more in http://kcd.im/optimize-context
    const value = { state, dispatch }
    return <ContractsContext.Provider value={value}>{children}</ContractsContext.Provider>
}

function useContracts() {
    const context = React.useContext(ContractsContext)
    if (context === undefined) {
        throw new Error('useCustomers must be used within a ContractsProvider')
    }
    return context
}

export { ContractsProvider, useContracts }