import React from 'react';
import PropTypes from 'prop-types';

import { CssBaseline, Container, Box, Typography, Tabs, Tab } from '@mui/material';

export default function DashboardPage() {
    return (
        <>
            <CssBaseline />
            <Container>
                <Typography variant="h4" component="h1" gutterBottom>
                    Your Account
                </Typography>
                <Typography variant="body1">Manage your account here</Typography>
                <Box mt={2}>
                    <BasicTabs />
                </Box>
            </Container>
        </>)
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                    <BasicTabs />
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function BasicTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Overview" {...a11yProps(0)} />
                    <Tab label="Your Licenses" {...a11yProps(1)} />
                    <Tab label="Support Portal" {...a11yProps(1)} />
                </Tabs>
            </Box>
        </Box>
    );
}