
import React, { useRef, useMemo } from 'react';
import Dialog from '../misc/Dialog';
import ContractForm from './ContractForm';
import { contractsApi } from '../contracts/ContractsAPI'
import { useKeycloak } from '@react-keycloak/web'
import { ContractMappers } from './ContractMappers'
import { useMisc } from '../misc/MiscContext'
import { useTranslation } from 'react-i18next';


export default function ContractFormDialog({ open, dialogType, onSave, onClose, customers, selectedCustomer, selectedContract }) {
    const { t } = useTranslation();
    const formRef = useRef();
    const { keycloak } = useKeycloak()
    const { dispatch: miscDispatch } = useMisc();

    const handleSaveDialogAdd = (data) => {
        const createContractRequestBody = ContractMappers.toContractCreateRequestFromFormData(data)
        contractsApi.createContract(createContractRequestBody, keycloak.token, ((response) => {
            miscDispatch({ type: 'SET_SNACKBAR_STATE', payload: { open: true, message: 'Contract added successfully', type: 'success' } });
            if (onSave) onSave();
        }), (error) => {
            miscDispatch({ type: 'SET_SNACKBAR_STATE', payload: { open: true, message: 'Adding contract failed', type: 'error' } });
        });

    };

    const handleSaveDialogEdit = (data) => {
        const updateContractRequestBody = ContractMappers.toContractCreateRequestFromFormData(data)
        contractsApi.updateContract(selectedContract.id, updateContractRequestBody, keycloak.token, ((response) => {
            miscDispatch({ type: 'SET_SNACKBAR_STATE', payload: { open: true, message: 'Contract updated successfully', type: 'success' } });
            if (onSave) onSave();
        }), (error) => {
            miscDispatch({ type: 'SET_SNACKBAR_STATE', payload: { open: true, message: 'Updating contract failed', type: 'error' } });
        });
    };

    const handleCloseDialog = () => {
        if (onClose) onClose();
    };

    const handleCancelDialog = () => {
        handleCloseDialog();
    };

    const formData = useMemo(() => {
        if (dialogType === "add") return ContractMappers.toContractFormDataDto({ customer: selectedCustomer })
        return dialogType === "edit" && ContractMappers.toContractFormDataDto({ contract: selectedContract, customer: selectedCustomer })
    }, [selectedCustomer, selectedContract, dialogType]);

    return (
        <Dialog
            title={dialogType === "add" ? t("contractFormDialog.addContract") : t("contractFormDialog.editContract")}
            open={open}
            onClose={handleCloseDialog}
            onSave={() => formRef.current.submitForm()}
            onCancel={handleCancelDialog}>
            <ContractForm
                reference={formRef}
                customers={customers}
                onSubmit={dialogType === "add" ? handleSaveDialogAdd : handleSaveDialogEdit}
                formData={formData}
            />
        </Dialog>
    );
}