import axios from 'axios'
import { config } from '../../Constants'

export const contractsApi = {
    getContracts,
    getContractsByCustomerId,
    createContract,
    updateContract,
    deleteContract,
    getLicenseKey,
}


async function getContracts(token, onCallback) {
    try {
        const response = await instance.get('/api/contracts', {
            headers: { 'Authorization': bearerAuth(token) }
        });
        const contracts = response.data
        if (onCallback) onCallback(contracts)
    } catch (error) {
        console.log(error)
    }
}

async function getContractsByCustomerId(customerId, token, onCallback) {
    try {
        const response = await instance.get(`/api/contracts/customer/${customerId}`, {
            headers: { 'Authorization': bearerAuth(token) }
        });
        const contracts = response.data
        if (onCallback) onCallback(contracts)
    } catch (error) {
        console.log(error)
    }
}

async function createContract(contract, token, onCallback, onError) { 
    try {
        const response = await instance.post('/api/contracts', contract, {
            headers: { 'Authorization': bearerAuth(token) }
        })
        const newContract = response.data
        if (onCallback) onCallback(newContract)
    } catch (error) {
        console.log(error)
        if (onError) onError(error)
    }
}

async function updateContract(id, contract, token, onCallback, onError) {
    try {
        const response = await instance.put(`/api/contracts/${id}`, contract, {
            headers: { 'Authorization': bearerAuth(token) }
        })
        const newContract = response.data
        if (onCallback) onCallback(newContract)
    } catch (error) {
        console.log(error)
        if (onError) onError(error)
    }
}

async function deleteContract(id, token, onCallback, onError) {
    try {
        const response = await instance.delete(`/api/contracts/${id}`, {
            headers: { 'Authorization': bearerAuth(token) }
        })
        if (onCallback) onCallback(response)
    } catch (error) {
        console.log(error)
        if (onError) onError(error)
    }
}

async function getLicenseKey(id, token, onCallback) {
    try {
        const response = await instance.get(`/api/contracts/generateLicenseKey/${id}`, {
            headers: { 'Authorization': bearerAuth(token) }
        })
        console.log(response)
        if (onCallback) onCallback(response)
    } catch (error) {
        console.log(error)
    }
}


// -- Axios

const instance = axios.create({
    baseURL: config.url.API_BASE_URL
})

instance.interceptors.response.use(response => {
    return response
}, function (error) {
    if (error.response.status === 404) {
        return { status: error.response.status }
    }
    return Promise.reject(error.response)
})

// -- Helper functions

function bearerAuth(token) {
    return `Bearer ${token}`
}