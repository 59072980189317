import axios from 'axios'
import { config } from '../../Constants'

export const usersApi = {
    getUserMe,
    getAllUsers,
    getUsersByCustomerId,
    createUser,
    updateUser,
    deleteUser,
    createUserByLogin,
}

async function getUserMe(token, onCallback) {
    try {
        const response = await instance.get(`/api/users/me`, {
            headers: { 'Authorization': bearerAuth(token) }
        })
        const user = response.data
        if (onCallback) onCallback(user)
    } catch (error) {
        console.log(error)
    }
}


async function getAllUsers(token, onCallback) {
    try {
        const response = await instance.get('/api/users', {
            headers: { 'Authorization': bearerAuth(token) }
        });
        const users = response.data
        if (onCallback) onCallback(users)
    } catch (error) {
        console.log(error)
    }
}

async function getUsersByCustomerId(customerId, token, onCallback) {
    try {
        const response = await instance.get(`/api/users/customer/${customerId}`, {
            headers: { 'Authorization': bearerAuth(token) }
        });
        const users = response.data
        if (onCallback) onCallback(users)
    } catch (error) {
        console.log(error)
    }
}

async function createUser(user, token, onCallback, onError) {
    try {
        const response = await instance.post('/api/users', user, {
            headers: { 'Authorization': bearerAuth(token) }
        })

        if (onCallback) onCallback(response)
    } catch (error) {
        console.log(error)
        if(onError) onError(error)
     
    }
}

async function updateUser(id, user, token, onCallback, onError) {
    try {
        const response = await instance.put(`/api/users/${id}`, user, {
            headers: { 'Authorization': bearerAuth(token) }
        })
        const newUser = response.data
        if (onCallback) onCallback(newUser)
    } catch (error) {
        console.log(error)
        if(onError) onError(error)
    }
}

async function deleteUser(id, token, onCallback, onError) {
    try {
        const response = await instance.delete(`/api/users/${id}`, {
            headers: { 'Authorization': bearerAuth(token) }
        })
        if (onCallback) onCallback(response)
    } catch (error) {
        console.log(error)
        if(onError) onError(error)
    }
}

async function createUserByLogin(user, token, onCallback, onError) {
    try {
        const response = await instance.post('/api/users/createByLogin', user, {
            headers: { 'Authorization': bearerAuth(token) }
        })

        if (onCallback) onCallback(response)
    } catch (error) {
        console.log(error)
        if(onError) onError(error)
     
    }
}



// -- Axios

const instance = axios.create({
    baseURL: config.url.API_BASE_URL
})

instance.interceptors.response.use(response => {
    return response
}, function (error) {
    if (error.response.status === 404) {
        return { status: error.response.status }
    }
    return Promise.reject(error.response)
})

// -- Helper functions

function bearerAuth(token) {
    return `Bearer ${token}`
}