import * as React from 'react';
import { PropTypes } from 'prop-types';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export function BasicSelect(props) {
    const { label, options, value, onChange } = props;
    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel sx={{zIndex: -1}} id={`simple-select-label-${label}`}>{label}</InputLabel>
                <Select
                    size="small"
                    labelId={`simple-select-label-${label}`}
                    id={`simple-select-${label}`}
                    value={value}
                    label="Age"
                    onChange={onChange}
                >
                    {options.map(option => (<MenuItem value={option.value}>{option.label}</MenuItem>))}
                </Select>
            </FormControl>
        </Box>
    );
}

BasicSelect.defaultProps = {
    label: '',
    options: [],
    value: '',
    onChange: () => { }
};

BasicSelect.propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default BasicSelect;