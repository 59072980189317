import * as React from 'react';
import { PropTypes } from "prop-types"
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export function MenuButton(props) {
  const { label, options, value, onChange } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenu = (event) => {
    handleClose();
    if(onChange) onChange(event.target.value);
  };

  return (
    <div>
      <Button
        id={`menu-button-${label}`}
        aria-controls={open ? `menu-button-${label}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Export
      </Button>
      <StyledMenu
        id={`menu-${label}`}
        MenuListProps={{
          'aria-labelledby': `menu-${label}`,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map(option => (
            <MenuItem key={option.value} onClick={handleClickMenu} value={option.value} disableRipple>
                {option.label}
            </MenuItem>
        ))}
        {/* <MenuItem onClick={handleClickMenu} disableRipple>
           All
        </MenuItem>
        <MenuItem onClick={handleClickMenu} disableRipple>
          Active
        </MenuItem>
        <MenuItem onClick={handleClickMenu} disableRipple>
           Due Soon and Expired Recently
        </MenuItem> */}
      </StyledMenu>
    </div>
  );
}

MenuButton.defaultProps = {
  label: '',
  options: [],
  value: '',
  onChange: () => { }
};

MenuButton.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default MenuButton;

