import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { TextField, Stack, Box, FormHelperText, FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import { Rowing } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const statusOptions = [
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' },
]

const typeOptions = [
    { value: 'Production', label: 'Production' },
    { value: 'Test', label: 'Test' },
]

const schema = yup.object({
    customer: yup.object().required('customer is required'),
    contract: yup.object().required('contract is required'),
    name: yup.string().required('name is required'),
    type: yup.string().required('type is required'),
    status: yup.string().required('status is required'),
    ipAddress: yup.string().required('ipAddress is required'),
}).required();

const InstanceForm = ({ onSubmit, reference, formData, customers }) => {
    const { t } = useTranslation();
    // This is only needed because our form submit button is outside the form. It exposes inner functions to ref used in parent component
    React.useImperativeHandle(reference, () => ({
        submitForm() {
            handleSubmit(onSubmit)();
        }
    }));

    const { control, handleSubmit, setValue, } = useForm({
        defaultValues: formData || {
            "customer": '',
            "contract": '',
            "name": '',
            "type": '',
            "status": '',
            "ipAddress": '',
        },
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const currentValueForcustomer = useWatch({ control, name: 'customer' });
    const contracts = currentValueForcustomer?.contracts || [];

    return (
        <form id="instance-form">
            <Stack spacing={2}>
                <Box sx={{ display: "flex", gap: "12px", minWidth: "500px" }}>
                    <Controller
                        name="customer"
                        control={control}
                        render={({ field, fieldState }) =>
                            <FormControl fullWidth >
                                <InputLabel id="licenseholder-label" error={fieldState.error}>{t("instanceForm.customer")}</InputLabel>
                                <Select
                                    {...field}
                                    value={field.value?.id}
                                    onChange={(event) => {
                                        const customer = customers.find((customer) => customer.id === event.target.value)
                                        if (customer) { setValue("customer", customer) }
                                        else { setValue("customer", "") }
                                    }}
                                    labelId="licenseholder-label"
                                    label={t("instanceForm.customer")}
                                    size="small"
                                    error={Boolean(fieldState.error)}
                                    helperText={fieldState.error && fieldState.error.message}
                                    required
                                >
                                    {customers.map((customer, index) => (
                                        <MenuItem key={`menu-item-${customer.id}-${index}`} value={customer.id}>{customer.name}</MenuItem>
                                    ))}
                                </Select>
                                {fieldState.error && fieldState.error.message && <FormHelperText id="my-helper-text-customer" error >{fieldState.error && fieldState.error.message}</FormHelperText>}
                            </FormControl>}
                    />
                </Box>

                <Box sx={{ display: "flex", gap: "12px", minWidth: "500px" }}>
                    <Controller
                        name="contract"
                        control={control}
                        render={({ field, fieldState }) =>
                            <FormControl fullWidth>
                                <InputLabel id="contract-label" error={fieldState.error}>{t("instanceForm.contract")}</InputLabel>
                                <Select
                                    {...field}
                                    value={field.value?.id}
                                    onChange={(event) => {
                                        const contract = contracts.find((contract) => contract.id === event.target.value)
                                        if (contract) { setValue("contract", contract) }
                                        else { setValue("contract", "") }
                                    }}
                                    labelId="contract-label"
                                    label={t("instanceForm.contract")}
                                    size="small"
                                    error={Boolean(fieldState.error)}
                                    helperText={fieldState.error && fieldState.error.message}
                                    required
                                >

                                    {contracts.map((contract) => (
                                        <MenuItem key={contract.id} value={contract.id}>{`Id: ${contract.id}; ${contract.type}; ${contract.startDate} until ${contract.endDate}`}</MenuItem>
                                    ))}
                                </Select>
                                {fieldState.error && fieldState.error.message && <FormHelperText id="my-helper-text-contract" error >{fieldState.error && fieldState.error.message}</FormHelperText>}
                            </FormControl>}
                    />
                </Box>

                <Box sx={{ display: "flex", gap: "12px", minWidth: "500px" }}>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field, fieldState }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label={t("instanceForm.name")}
                                variant="outlined"
                                size="small"
                                required
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error && fieldState.error.message}
                            />
                        )}
                    />
                </Box>

                <Box sx={{ display: "flex", gap: "12px", minWidth: "500px" }}>
                    <Controller
                        name="type"
                        control={control}
                        render={({ field, fieldState }) =>
                            <FormControl fullWidth>
                                <InputLabel id="contract-label" error={fieldState.error}>{t("instanceForm.type")}</InputLabel>
                                <Select
                                    {...field}
                                    labelId="contract-label"
                                    label={t("instanceForm.type")}
                                    size="small"
                                    error={Boolean(fieldState.error)}
                                    helperText={fieldState.error && fieldState.error.message}
                                >
                                    {
                                        typeOptions.map((typeOption) => (
                                            <MenuItem key={typeOption.value} value={typeOption.value}>{typeOption.label}</MenuItem>
                                        ))
                                    }
                                </Select>
                                {fieldState.error && fieldState.error.message && <FormHelperText id="my-helper-text-type" error >{fieldState.error && fieldState.error.message}</FormHelperText>}
                            </FormControl>}
                    />
                </Box>
                <Box sx={{ display: "flex", gap: "12px" }}>
                    <Controller
                        name="ipAddress"
                        control={control}
                        render={({ field, fieldState }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label={t("instanceForm.ipAddress")}
                                variant="outlined"
                                size="small"
                                required
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error && fieldState.error.message}
                            />
                        )}
                    />

                </Box>
                <Box sx={{ display: "flex", gap: "12px", minWidth: "500px" }}>
                    <Controller
                        name="status"
                        control={control}
                        render={({ field, fieldState }) =>
                            <FormControl fullWidth>
                                <InputLabel id="status-label" error={fieldState.error}>{t("instanceForm.status")}</InputLabel>
                                <Select
                                    {...field}
                                    labelId="status-label"
                                    label={t("instanceForm.status")}
                                    size="small"
                                    error={Boolean(fieldState.error)}
                                    helperText={fieldState.error && fieldState.error.message}
                                >
                                    {
                                        statusOptions.map((statusOption) => (
                                            <MenuItem key={statusOption.value} value={statusOption.value}>{statusOption.label}</MenuItem>
                                        ))
                                    }
                                </Select>
                                {fieldState.error && fieldState.error.message && <FormHelperText id="my-helper-text-status" error >{fieldState.error && fieldState.error.message}</FormHelperText>}
                            </FormControl>}
                    />
                </Box>
            </Stack>
        </form>
    );
};

export default InstanceForm;
