
export const InstanceMappers = {
    toInstanceFormDataDto,
    toInstanceCreateRequestFromFormData,
}


function toInstanceFormDataDto({ instance, customer, contract }) {
    if (instance && contract && customer) {
        return {
            customer: customer || "",
            contract: contract || "",
            name: instance.name || "",
            type: instance.type || "",
            status: instance.status || "",
            ipAddress: instance.ipAddress || "",
        }
    } else if (customer) { 
        return {
            customer: customer || "",
            contract: customer?.contracts.length > 0 ? customer.contracts[0] : "",
            name: `Demo instance for customer ${customer.name}`,
            type: "Test",
            status: "Active",
            ipAddress: "254",
        }
    } else {
        return {
            customer: "",
            contract: "",
            name: "",
            type: "",
            status: "",
            ipAddress: "",
        }
    }
}

function toInstanceCreateRequestFromFormData(formData) {
    return {
        contract: formData.contract,
        name: formData.name,
        type: formData.type,
        status: formData.status,
        ipAddress: formData.ipAddress.trim(),
    }
}

