import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useMisc } from './MiscContext';

// Custom Alert component using MuiAlert for accessibility
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomSnackbar() {
    const { state: miscState, dispatch: miscDispatch } = useMisc();
    const { snackbarState } = miscState;

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        miscDispatch({ type: 'SET_SNACKBAR_STATE', payload: { open: false, message: "", type: "success", duration: 6000 } });
    };

    return (
        <Snackbar
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            open={snackbarState.open}
            autoHideDuration={parseInt(snackbarState.duration, 10) || 6000}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity={snackbarState.type} sx={{ width: '100%' }}>
                {snackbarState.message}
            </Alert>
        </Snackbar>
    );
}