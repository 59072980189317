import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Stack, Box, FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

const schema = yup.object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Invalid email address').required('Email is required'),
    phoneNumber1: yup.string(),
    phoneNumber2: yup.string(),
}).required();


const UserForm = ({ dialogType, onSubmit, reference, formData, customers }) => {
    const { t } = useTranslation();

    // This is only needed because our form submit button is outside the form. It exposes inner functions to ref used in parent component
    React.useImperativeHandle(reference, () => ({
        submitForm() {
            handleSubmit(onSubmit)();
        }
    }));

    const { control, handleSubmit, setValue, } = useForm({
        defaultValues: formData || {
            "customer": '',
            "firstName": '',
            "lastName": '',
            "email": '',
            "phoneNumber1": '',
            "phoneNumber2": '',
            "isAdmin": false,
        },
        resolver: yupResolver(schema),
        mode: "onChange",
    });


    return (
        <form id="customer-form">
            <Stack spacing={2}>
                <Box sx={{ display: "flex", gap: "12px", minWidth: "500px" }}>
                    <Controller
                        name="customer"
                        control={control}
                        render={({ field }) =>
                            <FormControl fullWidth>
                                <InputLabel id="licenseholder-label">{t("userForm.customer")}</InputLabel>
                                <Select
                                    {...field}
                                    value={field.value?.id}
                                    onChange={(event) => {
                                        const customer = customers.find((customer) => customer.id === event.target.value)
                                        if (customer) { setValue("customer", customer) }
                                        else { setValue("customer", "") }
                                    }}
                                    labelId="licenseholder-label"
                                    label={t("userForm.customer")}
                                    size="small"
                                    required
                                >
                                    {customers.map((customer) => (
                                        <MenuItem key={customer.id} value={customer.id}>{customer.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>}
                    />
                </Box>

                <Box sx={{ display: "flex", gap: "12px" }}>
                    <Controller
                        name="firstName"
                        control={control}
                        render={({ field, fieldState }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label={t("userForm.firstName")}
                                variant="outlined"
                                size="small"
                                required
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error && fieldState.error.message}
                            />
                        )}
                    />
                    <Controller
                        name="lastName"
                        control={control}
                        render={({ field, fieldState }) => (
                            <TextField
                                {...field}
                                sx={{ minWidth: "250px" }}
                                label={t("userForm.lastName")}
                                variant="outlined"
                                size="small"
                                required
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error && fieldState.error.message}
                            />)}
                    />
                </Box>


                <Box sx={{ display: "flex", gap: "12px" }}>
                    <Controller
                        name="email"
                        control={control}
                        render={({ field, fieldState }) => (
                            <TextField
                                {...field}
                                disabled={dialogType === "edit"}
                                sx={{ minWidth: "250px" }}
                                label={t("userForm.emailAddress")}
                                variant="outlined"
                                size="small"
                                required
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error && fieldState.error.message}
                            />)}
                    />
                </Box>
                
                <Box sx={{ display: "flex", gap: "12px" }}>
                    <Controller
                        name="phoneNumber1"
                        control={control}
                        render={({ field, fieldState }) => (
                            <TextField
                                {...field}
                                sx={{ minWidth: "250px" }}
                                label={t("userForm.phoneNumber1")}
                                variant="outlined"
                                size="small"
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error && fieldState.error.message}
                            />)}
                    />
                    <Controller
                        name="phoneNumber2"
                        control={control}
                        render={({ field, fieldState }) => (
                            <TextField
                                {...field}
                                sx={{ minWidth: "250px" }}
                                label={t("userForm.phoneNumber2")}
                                variant="outlined"
                                size="small"
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error && fieldState.error.message}
                            />)}
                    />
                </Box>

                {/* <Controller
                    name="isAdmin"
                    control={control}
                    render={({ field }) => <FormControlLabel control={<Switch {...field} checked={field.value} />} label="Belongs to Alltena Company? (Be careful here)" />
                    } /> */}
            </Stack>
        </form>
    );
};


 //<Checkbox {...field} checked={field.value} />
export default UserForm;
