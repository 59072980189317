import * as React from 'react'

const defaultState = {
    instances: [],
    instanceFormDialogState: {
        open: false,
        dialogType: '',
        selectedCustomer: null,
        selectedContract: null,
        selectedInstance: null
    }
}

const InstancesContext = React.createContext()

function instancesReducer(state, action) {
    switch (action.type) {
        case 'SET_INSTANCES': {
            const { instances } = action.payload;
            return { ...state, instances: instances }
        }
        case 'SET_INSTANCE_FORM_DIALOG_STATE': {
            return { ...state, instanceFormDialogState: action.payload }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function InstancesProvider({ children }) {
    const [state, dispatch] = React.useReducer(instancesReducer, defaultState)
    // NOTE: you *might* need to memoize this value
    // Learn more in http://kcd.im/optimize-context
    const value = { state, dispatch }
    return <InstancesContext.Provider value={value}>{children}</InstancesContext.Provider>
}

function useInstances() {
    const context = React.useContext(InstancesContext)
    if (context === undefined) {
        throw new Error('useInstances must be used within a InstancesProvider')
    }
    return context
}

export { InstancesProvider, useInstances }