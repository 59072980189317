import axios from 'axios'
import { config } from '../../Constants'

export const instancesApi = {
    getAllInstances,
    getInstancesByCustomerId,
    createInstance,
    updateInstance,
    deleteInstance,
}

async function getAllInstances(token, onCallback) {
    try {
        const response = await instance.get('/api/instances', {
            headers: { 'Authorization': bearerAuth(token) }
        });
        const instances = response.data
        if (onCallback) onCallback(instances)
    } catch (error) {
        console.log(error)
    }
}

async function getInstancesByCustomerId(customerId, token, onCallback) {
    try {
        const response = await instance.get(`/api/instances/customer/${customerId}`, {
            headers: { 'Authorization': bearerAuth(token) }
        });

        const instances = response.data
        if (onCallback) onCallback(instances)
    } catch (error) {
        console.log(error)
    }
}

async function createInstance(instanceData, token, onCallback, onError) {
    try {
        const response = await instance.post('/api/instances', instanceData, {
            headers: { 'Authorization': bearerAuth(token) }
        })
        const newInstance = response.data
        if (onCallback) onCallback(newInstance)
    } catch (error) {
        console.log(error)
        if (onError) onError(error)
    }
}

async function updateInstance(instanceId, instanceData, token, onCallback, onError) {
    try {
        const response = await instance.put(`/api/instances/${instanceId}`, instanceData, {
            headers: { 'Authorization': bearerAuth(token) }
        })
        const updateInstance = response.data
        if (onCallback) onCallback(updateInstance)
    } catch (error) {
        console.log(error)
        if (onError) onError(error)
    }
}

async function deleteInstance(instanceId, token, onCallback, onError) {
    try {
        const response = await instance.delete(`/api/instances/${instanceId}`, {
            headers: { 'Authorization': bearerAuth(token) }
        })
        const deleteInstanceResponse = response.data
        if (onCallback) onCallback(deleteInstanceResponse)
    } catch (error) {
        console.log(error)
        if (onError) onError(error)
    }
}

const instance = axios.create({
    baseURL: config.url.API_BASE_URL
})

instance.interceptors.response.use(response => {
    return response
}, function (error) {
    if (error.response.status === 404) {
        return { status: error.response.status }
    }
    return Promise.reject(error.response)
})

// -- Helper functions

function bearerAuth(token) {
    return `Bearer ${token}`
}