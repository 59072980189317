import axios from 'axios'
import { config } from '../../Constants'

export const keyApi = {
    getLicenseKeyOld,
}

async function getLicenseKeyOld(licenseData, token, onCallback) {
    try {
        const response = await instance.post('/api/licensekeys/generateLicenseKeyOld', licenseData, {
            headers: { 'Authorization': bearerAuth(token) }
        })
        const licenseKey = response.data
        if (onCallback) onCallback(licenseKey)
    } catch (error) {
        console.log(error)
    }
}


// -- Axios
const instance = axios.create({
    baseURL: config.url.API_BASE_URL
})

instance.interceptors.response.use(response => {
    return response
}, function (error) {
    if (error.response.status === 404) {
        return { status: error.response.status }
    }
    return Promise.reject(error.response)
})

// -- Helper functions
function bearerAuth(token) {
    return `Bearer ${token}`
}