import React, { useRef, useMemo } from 'react';
import Dialog from '../misc/Dialog';
import UserForm from './UserForm';
import { usersApi } from '../users/UsersAPI'
import { useKeycloak } from '@react-keycloak/web'
import { UserMappers } from './UserMappers'
import { Typography } from '@mui/material';
import { useMisc } from '../misc/MiscContext'
import { useTranslation } from 'react-i18next';


export default function UserFormDialog({ open, dialogType, selectedUser, selectedCustomer, customers, error, onSave, onClose, onError }) {
    const { t } = useTranslation();
    const formRef = useRef();
    const { keycloak } = useKeycloak()
    const { state: miscState, dispatch: miscDispatch } = useMisc();


    const handleSaveDialogAdd = (data) => {
        const user = UserMappers.toNewUserRequestFromFormData(data);
        usersApi.createUser(user, keycloak.token, ((response) => {
            miscDispatch({ type: 'SET_SNACKBAR_STATE', payload: { open: true, message: 'User added successfully', type: 'success' } });
            if (onSave) onSave();
        }), (error) => {
            miscDispatch({ type: 'SET_SNACKBAR_STATE', payload: { open: true, message: 'Adding user failed', type: 'error' } });
            if (onError) onError(error);
        });
    };

    const handleSaveDialogEdit = (data) => {
        const user = UserMappers.toUpdateUserRequestFromFormData(data)
        usersApi.updateUser(selectedUser.id, user, keycloak.token, (() => {
            miscDispatch({ type: 'SET_SNACKBAR_STATE', payload: { open: true, message: 'User updated successfully', type: 'success' } });
            if (onSave) onSave();
        }), (error) => {
            miscDispatch({ type: 'SET_SNACKBAR_STATE', payload: { open: true, message: 'Updating user failed', type: 'error' } });
            if (onError) onError(error);
        });
    };

    const handleCloseDialog = () => {
        if (onClose) onClose();
    };

    const handleCancelDialog = () => {
        handleCloseDialog();
    };

    const formData = useMemo(() => {
        if (dialogType === "add") return UserMappers.toUserFormDataDto({ customer: selectedCustomer })
        return dialogType === "edit" && UserMappers.toUserFormDataDto({ user: selectedUser })
    }, [selectedCustomer, selectedUser]);


    return (
        <Dialog
            title={dialogType === "add" ? t("userFormDialog.addUser") : t("userFormDialog.editUser")}
            open={open}
            onClose={handleCloseDialog}
            onSave={() => formRef.current.submitForm()}
            onCancel={handleCancelDialog}>
            {error && <Typography variant="subtitle1" mb={2} color="error">{error.data?.message}</Typography>}
            <UserForm
                dialogType={dialogType}
                reference={formRef}
                customers={customers}
                onSubmit={dialogType === "add" ? handleSaveDialogAdd : handleSaveDialogEdit}
                formData={formData}
            />
        </Dialog>
    );
}