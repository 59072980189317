import axios from 'axios'
import { config } from '../../Constants'

export const commentsApi = {
    getCommentsByCustomerId,
    createComment,
    updateComment,
    deleteComment,
}

async function getCommentsByCustomerId(customerId, token, onCallback) {
    try {
        const response = await instance.get(`/api/comments/customer/${customerId}`, {
            headers: { 'Authorization': bearerAuth(token) }
        });
        const comments = response.data
        if (onCallback) onCallback(comments)
    } catch (error) {
        console.log(error)
    }
}

async function createComment(data, token, onCallback) {
    try {
        const response = await instance.post('/api/comments', data, {
            headers: { 'Authorization': bearerAuth(token) }
        })
        const comment = response.data
        if (onCallback) onCallback(comment)
    } catch (error) {
        console.log(error)
    }
}

async function updateComment(data, token, onCallback) {
    try {
        const response = await instance.put(`/api/comments/${data.id}`, data, {
            headers: { 'Authorization': bearerAuth(token) }
        })
        const comment = response.data
        if (onCallback) onCallback(comment)
    } catch (error) {
        console.log(error)
    }
}

async function deleteComment(id, token, onCallback) {
    try {
        const response = await instance.delete(`/api/comments/${id}`, {
            headers: { 'Authorization': bearerAuth(token) }
        })
        if (onCallback) onCallback(response)
    } catch (error) {
        console.log(error)
    }
}

// -- Axios

const instance = axios.create({
    baseURL: config.url.API_BASE_URL
})

instance.interceptors.response.use(response => {
    return response
}, function (error) {
    if (error.response.status === 404) {
        return { status: error.response.status }
    }
    return Promise.reject(error.response)
})

// -- Helper functions

function bearerAuth(token) {
    return `Bearer ${token}`
}