import * as React from 'react';
import {
    Box,
    ClickAwayListener,
    Collapse,
    Grow,
    IconButton,
    Paper,
    Popper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Chip,
    Divider
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import KeyIcon from '@mui/icons-material/Key';

import {
    Add,
    Edit,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
    MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import StorageIcon from '@mui/icons-material/Storage';
import ContactsIcon from '@mui/icons-material/Contacts';
import PropTypes from 'prop-types';
import { NavLink, } from 'react-router-dom';

import { MenuList, MenuItem } from '@mui/material';

import { getContractStatus, sortContracts, groupContracts } from './Constants';

function Row(props) {
    const { row,
        noRows,
        handleRowOpen,
        handleOpenCustomerDialog,
        handleOpenContractDialog,
        handleOpenUserDialog,
        // handleOpenInstanceDialog,
        handleOpenUserPicker,
        // handleDeleteCustomer,
        handleDeleteContract,
        // handleDeleteInstance,
        handleRemoveUser,
        // getLicenseKey
    } = props;
    const anchorRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const [menuOpen, setMenuOpen] = React.useState(false);

    React.useEffect(() => {
        if (open) {
            if (handleRowOpen) handleRowOpen(row)
        }
    }, [open])

    React.useEffect(() => {
        if (noRows === 1) setOpen(true);
        else setOpen(false);
    }, [noRows]);

    const handleToggle = () => {
        setMenuOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setMenuOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setMenuOpen(false);
        } else if (event.key === 'Escape') {
            setMenuOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [menuOpen]);

    const status = getContractStatus(row.endDate);
    let statusTableCell = null
    if (status === "Active") {
        statusTableCell = <Chip label={status} color="success" />
    } else if (status === "Expired") {
        statusTableCell = <Chip label={status} color="error" />
    } else if (status === "Due Soon") {
        statusTableCell = <Chip label={status} color="warning" />
    } else if (status === "Expired Recently") {
        statusTableCell = <Chip label={status} color="error" />
    } else {
        statusTableCell = <Chip label={status} color="info" />
    }

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {`ID: ${row.id}`}
                </TableCell>
                <TableCell component="th" scope="row">
                    {statusTableCell}
                </TableCell>
                <TableCell component="th" scope="row">
                    {`Type: ${row.type}`}
                </TableCell>
                <TableCell component="th" scope="row">
                    {`Customer: ${row.customer.name}`}
                </TableCell>
                <TableCell component="th" scope="row">
                    {`Start: ${row.startDate}`}
                </TableCell>
                <TableCell component="th" scope="row">
                    {`End: ${row.endDate}`}
                </TableCell>

                <TableCell component="th" scope="row">
                    {row.department}
                </TableCell>

                <TableCell align="right">{row.town}</TableCell>
                <TableCell align="right">{row.street}</TableCell>
                <TableCell align="right">{row.country}</TableCell>

                <TableCell align="right">
                    <NavLink
                        to={"/customers/" + row.customer.id}
                        className={({ isActive, isPending }) =>
                            isPending ? "pending" : isActive ? "active" : ""
                        }
                    >
                        Go To Customer
                    </NavLink>
                </TableCell>
                <TableCell align="right">
                    <div>
                        <IconButton color="primary" aria-label="add to shopping cart"
                            ref={anchorRef}
                            id="composition-button"
                            aria-controls={open ? 'composition-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}>
                            <MoreVertIcon />
                        </IconButton>
                        <Popper
                            style={{ zIndex: 1000 }}
                            open={menuOpen}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement="bottom-start"
                            transition
                            disablePortal
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                                    }}
                                >
                                    <Paper >
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList
                                                autoFocusItem={menuOpen}
                                                id="composition-menu"
                                                aria-labelledby="composition-button"
                                                onKeyDown={handleListKeyDown}
                                            >
                                                <MenuItem onClick={(e) => {
                                                    handleClose(e);
                                                    handleOpenContractDialog(row.customer, row);
                                                }}>Edit</MenuItem>
                                                <MenuItem onClick={(e) => {
                                                    handleDeleteContract(row.customer.id, row.id);
                                                    handleClose(e);
                                                }}>Delete</MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Box sx={{ display: "flex", alignItems: "end", gap: 1 }}>
                                <Box>
                                    <HistoryEduIcon sx={{ fontSize: 32 }} />
                                </Box>
                                <Typography variant="h6" gutterBottom component="div" sx={{ marginTop: 2 }}>
                                    Contract details
                                </Typography>
                                <Box sx={{ flexGrow: 1 }} />
                                <Box>
                                    <IconButton color="primary" aria-label='Edit customer' onClick={() => {
                                        handleOpenContractDialog(row.customer, row);
                                    }}>
                                        <EditIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Start</TableCell>
                                        <TableCell>End</TableCell>
                                        <TableCell>Version</TableCell>
                                        <TableCell>User Counts</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={`table-row-${row.id}`}>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.startDate}</TableCell>
                                        <TableCell>{row.endDate}</TableCell>
                                        <TableCell>{row.versionNumber}</TableCell>
                                        <TableCell>{`Task: ${row.noTask}, Service: ${row.noService}, Project: ${row.noProject}`}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                            <Box sx={{ display: "flex", alignItems: "end", gap: 1 }}>
                                <Box>
                                    <ContactsIcon sx={{ fontSize: 32 }} />
                                </Box>
                                <Typography variant="h6" gutterBottom component="div" sx={{ marginTop: 2 }}>
                                    Contacts
                                </Typography>
                                <Box sx={{ flexGrow: 1 }} />
                                <Box>
                                    <IconButton color="primary" aria-label='Edit customer' onClick={() => {
                                        handleOpenUserPicker({ open: true, selectedCustomer: row.customer });
                                    }}>
                                        <AddIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>First Name</TableCell>
                                        <TableCell>Last Name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Phone Number 1</TableCell>
                                        <TableCell>Phone Number 2</TableCell>
                                        {/* <TableCell>Admin</TableCell> */}
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                {row.contacts.map((contact) =>
                                    <TableBody>
                                        <TableRow key={"row-user-details"}>
                                            <TableCell>{contact.id}</TableCell>
                                            <TableCell>{contact.firstName}</TableCell>
                                            <TableCell>{contact.lastName}</TableCell>
                                            <TableCell>{contact.email}</TableCell>
                                            <TableCell>{contact.phoneNumber1}</TableCell>
                                            <TableCell>{contact.phoneNumber2}</TableCell>
                                            {/* <TableCell>{contact.isAdmin ? "Yes" : "No"}</TableCell> */}
                                            <TableCell>
                                                <IconButton color="primary" aria-label='Edit user' onClick={() => {
                                                    handleOpenUserDialog(row.customer, contact);
                                                }}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton color="primary" aria-label='Remove user' onClick={() => {
                                                    handleRemoveUser(row.customer.id, contact.id);
                                                }}>
                                                    <PersonRemoveIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}

                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    );
}

Row.propTypes = {
    row: PropTypes.shape({

    }).isRequired,
};


export default function ContractsTable(props) {
    const {
        contracts,
        groupBy,
        sortBy,
        handleRowOpen,
        handleOpenUserDialog,
        handleOpenUserPicker,
        handleOpenCustomerDialog,
        handleOpenContractDialog,
        handleDeleteContract,
        handleRemoveUser,
    } = props;
    const rows = contracts;
    const sortedRows = sortContracts(rows, sortBy);
    const groupedRows = groupContracts(sortedRows, groupBy);

    return (
        groupBy ? Object.keys(groupedRows).map((key) => {
            return (
                <>
                    <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
                        <Divider textAlign="left" ><Chip label={key} /></Divider>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableBody>
                                {groupedRows[key].map((row) => (
                                    <Row
                                        key={row.name}
                                        row={row}
                                        noRows={rows.length}
                                        handleRowOpen={handleRowOpen}
                                        handleOpenUserDialog={handleOpenUserDialog}
                                        handleOpenUserPicker={handleOpenUserPicker}
                                        handleOpenCustomerDialog={handleOpenCustomerDialog}
                                        handleOpenContractDialog={handleOpenContractDialog}
                                        handleDeleteContract={handleDeleteContract}
                                        handleRemoveUser={handleRemoveUser}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )

        }) :
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableBody>
                        {sortedRows.map((row) => (
                            <Row
                                key={row.name}
                                row={row}
                                noRows={rows.length}
                                handleRowOpen={handleRowOpen}
                                handleOpenUserDialog={handleOpenUserDialog}
                                handleOpenUserPicker={handleOpenUserPicker}
                                handleOpenCustomerDialog={handleOpenCustomerDialog}
                                handleOpenContractDialog={handleOpenContractDialog}
                                handleDeleteContract={handleDeleteContract}
                                handleRemoveUser={handleRemoveUser}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
    );
}
