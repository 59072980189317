import React, { useState } from 'react';
import { Card, CardContent, Typography, Avatar, Button, TextField, CardActions, Grid, Tooltip } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

function Comment({ currentUserId, createdBy, text, avatarUrl, creationDate, onSave, onDelete }) {
    const { t } = useTranslation();
    const [isEditing, setIsEditing] = useState(false);
    const [editedText, setEditedText] = useState(text);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = () => {
        setIsEditing(false);
        onSave(editedText);
    };

    const handleChange = (event) => {
        setEditedText(event.target.value);
    };

    return (
        <Card sx={{ mt: 1 }}>
            <CardContent>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <Tooltip title={`${createdBy.username} ${createdBy.email}`}>
                            <Avatar alt="User Avatar" src={avatarUrl} />
                        </Tooltip>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="caption" color="textSecondary">
                            {createdBy.firstName} {createdBy.lastName}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                            {t('comment.createdOn')} {creationDate}
                        </Typography>

                        {isEditing ? (
                            <TextField
                                multiline
                                maxRows={4}
                                fullWidth
                                variant="outlined"
                                value={editedText}
                                onChange={handleChange}
                            />
                        ) : (
                            <Typography variant="body1">{text}</Typography>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
            {createdBy.id === currentUserId && (
                <CardActions>
                    {isEditing ? (
                        <>
                            <Button size="small" color="primary" variant="contained" onClick={handleSave}>
                                {t('comment.save')}
                            </Button>
                            <Button size="small" color="primary" onClick={handleSave}>
                                {t('comment.cancel')}
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button size="small" color="primary" startIcon={<EditIcon />} onClick={handleEdit}>
                                {t('comment.edit')}
                            </Button>
                            <Button size="small" color="primary" startIcon={<DeleteIcon />} onClick={onDelete}>
                                {t('comment.delete')}
                            </Button>
                        </>
                    )}
                </CardActions>
            )
            }

        </Card>
    );
}

export default Comment;
