import axios from 'axios'
import { config } from '../../../Constants'

export const searchApi = {
   searchBy,
}

async function searchBy(searchTerm, token, onCallback) {
    try {
        const response = await instance.get(`/api/search/${searchTerm}`, {
            headers: { 'Authorization': bearerAuth(token) },
        });
        const results = response.data
        if (onCallback) onCallback(results)
    } catch (error) {
        console.log(error)
    }
}

// -- Axios
const instance = axios.create({
    baseURL: config.url.API_BASE_URL
})

instance.interceptors.response.use(response => {
    return response
}, function (error) {
    if (error.response.status === 404) {
        return { status: error.response.status }
    }
    return Promise.reject(error.response)
})

// -- Helper functions

function bearerAuth(token) {
    return `Bearer ${token}`
}