import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Stack, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CustomerForm = ({ onSubmit, reference, formData }) => {
    const { t } = useTranslation();
    const schema = yup.object({
        name: yup.string().required(t('customerForm.customerNameIsRequired')),
        department: yup.string(),
        street: yup.string(),
        town: yup.string(),
        zipCode: yup.string(),
        country: yup.string(),
    }).required();
    // This is only needed because our form submit button is outside the form. It exposes inner functions to ref used in parent component
    React.useImperativeHandle(reference, () => ({
        submitForm() {
            handleSubmit(onSubmit)();
        }
    }));
    const { control, handleSubmit, formState, setValue } = useForm({
        defaultValues: formData || {
            "name": '',
            "department": '',
            "street": '',
            "town": '',
            "zipCode": '',
            "country": '',
        },
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    return (
        <form id="customer-form">
            <Stack spacing={2}>
                {/* Customer Name */}
                <Box sx={{ display: "flex", gap: "12px", minWidth: "500px" }}>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field, fieldState }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label={t("customerForm.name")}
                                variant="outlined"
                                size="small"
                                required
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error && fieldState.error.message}
                            />
                        )}
                    />
                </Box>

                {/* Customer Department */}
                <Box sx={{ display: "flex", gap: "12px", minWidth: "500px" }}>
                    <Controller
                        name="department"
                        control={control}
                        render={({ field, fieldState }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label={t("customerForm.department")}
                                variant="outlined"
                                size="small"
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error && fieldState.error.message}
                            />
                        )}
                    />
                </Box>

                {/* Customer Address */}
                <Box sx={{ display: "flex", gap: "12px" }}>
                    <Controller
                        name="street"
                        control={control}
                        render={({ field, fieldState }) => (
                            <TextField
                                {...field}
                                sx={{ minWidth: "250px" }}
                                label={t("customerForm.street")}
                                variant="outlined"
                                size="small"
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error && fieldState.error.message}
                            />)}
                    />
                    <Controller
                        name="town"
                        control={control}
                        render={({ field, fieldState }) => (
                            <TextField
                                {...field}
                                sx={{ minWidth: "250px" }}
                                label={t("customerForm.city")}
                                variant="outlined"
                                size="small"
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error && fieldState.error.message}
                            />)}
                    />
                </Box>
                <Box sx={{ display: "flex", gap: "12px" }}>
                    <Controller
                        name="zipCode"
                        control={control}
                        render={({ field, fieldState }) => (
                            <TextField
                                {...field}
                                sx={{ minWidth: "250px" }}
                                label={t("customerForm.zipCode")}
                                variant="outlined"
                                size="small"
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error && fieldState.error.message}
                            />)}
                    />
                    <Controller
                        name="country"
                        control={control}
                        render={({ field, fieldState }) => (
                            <TextField
                                {...field}
                                sx={{ minWidth: "250px" }}
                                label={t("customerForm.country")}
                                variant="outlined"
                                size="small"
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error && fieldState.error.message}
                            />)}
                    />
                </Box>
            </Stack>
        </form>
    );
};

export default CustomerForm;
