import * as React from 'react';
import * as yup from "yup"
import dayjs from 'dayjs';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Stack, TextField, FormControl, FormHelperText, Select, InputLabel, MenuItem, Divider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/en-gb';
import { useTranslation } from 'react-i18next';

function emptyStringToNull(value, originalValue) {
    if (typeof originalValue === 'string' && originalValue === '') {
        return null;
    }
    return value;
}


const schema = yup.object({
    customer: yup.object().required("This field is required"),
    type: yup.string().required("This field is required"),
    versionNumber: yup.string().required("This field is required"),
    noTask: yup.number("Must be a number").min(0).transform(emptyStringToNull).nullable(),
    noProject: yup.number("Must be a number").min(0).transform(emptyStringToNull).nullable(),
    noService: yup.number("Must be a number").min(0).transform(emptyStringToNull).nullable(),
    startDate: yup.string().required("This field is required"),
    daysValid: yup.string().required("This field is required"),
    endDate: yup.string().required("This field is required"),
}).required();


export function ContractForm({ onSubmit, reference, customers, formData }) {
   const { t } = useTranslation();

    React.useImperativeHandle(reference, () => ({
        submitForm() {
            handleSubmit(onSubmit)();
        }
    }));

    const { control, handleSubmit, formState, setValue, watch } = useForm({
        defaultValues: formData || {
            customer: '',
            type: '',
            versionNumber: '',
            noTask: '',
            noProject: '',
            noService: '',
            startDate: '',
            daysValid: '',
            endDate: '',
        },
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const watchIpFields = watch(["ipAddress1", "ipAddress2", "ipAddress3", "ipAddress4", "ipAddress5", "daysValid"])
    const watchDateFields = watch(["startDate", "daysValid", "endDate"])

    return (
        <form id="contract-form" >
            <Stack spacing={3}>

                <Box sx={{ display: "flex", gap: "12px", minWidth: "500px" }}>
                    <Controller
                        name="customer"
                        control={control}
                        render={({ field, fieldState }) =>
                            <FormControl fullWidth >
                                <InputLabel id="licenseholder-label" error={fieldState.error}>{t("contractForm.customer")}</InputLabel>
                                <Select
                                    {...field}
                                    value={field.value?.id}
                                    onChange={(event) => {
                                        const customer = customers.find((customer) => customer.id === event.target.value)
                                        if (customer) { setValue("customer", customer) }
                                        else { setValue("customer", "") }
                                    }}
                                    labelId="licenseholder-label"
                                    label={t("contractForm.customer")}
                                    size="small"
                                    error={Boolean(fieldState.error)}
                                    helperText={fieldState.error && fieldState.error.message}
                                    required
                                >
                                    {customers.map((customer) => (
                                        <MenuItem key={customer.id} value={customer.id}>{customer.name}</MenuItem>
                                    ))}
                                </Select>
                                {fieldState.error && fieldState.error.message && <FormHelperText id="my-helper-text-customer" error >{fieldState.error && fieldState.error.message}</FormHelperText>}
                            </FormControl>}
                    />
                </Box>

                <Box sx={{ width: "500px" }}>
                    <Controller
                        name="type"
                        control={control}
                        render={({ field }) =>
                            <FormControl fullWidth>
                                <InputLabel id="product-label">{t("contractForm.type")}</InputLabel>
                                <Select
                                    {...field}
                                    labelId="product-label"
                                    label={t("contractForm.type")}
                                    size="small"
                                    required
                                >
                                    <MenuItem key={"Cloud"} value={"Cloud-SaaS"}>Cloud-SaaS</MenuItem>
                                    <MenuItem key={"Cloud-Test"} value={"Cloud-Test"}>Cloud-Test</MenuItem>
                                    <MenuItem key={"On-Prem"} value={"On-Prem"}>On-Prem</MenuItem>
                                    <MenuItem key={"On-Prem-Test"} value={"On-Prem-Test"}>On-Prem-Test</MenuItem>
                                </Select>
                            </FormControl>}
                    />
                </Box>
                <Box sx={{ width: "500px" }}>
                    <Controller
                        name="versionNumber"
                        control={control}
                        render={({ field }) =>
                            <FormControl fullWidth>
                                <InputLabel id="version-label">{t("contractForm.version")}</InputLabel>
                                <Select
                                    {...field}
                                    labelId="version-label"
                                    label={t("contractForm.version")}
                                    size="small"
                                >
                                    <MenuItem key={"Latest"} value={"Latest"}>Latest</MenuItem>
                                    <MenuItem key={"8.0"} value={"8.0"}>8.0</MenuItem>
                                    <MenuItem key={"7.0"} value={"7.0"}>7.0</MenuItem>
                                    <MenuItem key={"6.0"} value={"6.0"}>6.0</MenuItem>
                                    <MenuItem key={"5.6"} value={"5.6"}>5.6</MenuItem>
                                    <MenuItem key={"5.5"} value={"5.5"}>5.5</MenuItem>
                                    <MenuItem key={"5.3"} value={"5.3"}>5.3</MenuItem>
                                    <MenuItem key={"5.1"} value={"5.1"}>5.1</MenuItem>
                                    <MenuItem key={"5.0"} value={"5.0"}>5.0</MenuItem>
                                    <MenuItem key={"4.1"} value={"4.1"}>4.1</MenuItem>
                                    <MenuItem key={"4.0"} value={"4.0"}>4.0</MenuItem>
                                    <MenuItem key={"3.8"} value={"3.8"}>3.8</MenuItem>
                                    <MenuItem key={"3.7"} value={"3.7"}>3.7</MenuItem>
                                    <MenuItem key={"3.6"} value={"3.6"}>3.6</MenuItem>
                                    <MenuItem key={"3.5"} value={"3.5"}>3.5</MenuItem>
                                    <MenuItem key={"3.4"} value={"3.4"}>3.4</MenuItem>
                                    <MenuItem key={"3.3"} value={"3.3"}>3.3</MenuItem>
                                    <MenuItem key={"3.2"} value={"3.2"}>3.2</MenuItem>
                                    <MenuItem key={"3.1"} value={"3.1"}>3.1</MenuItem>
                                    <MenuItem key={"3.0"} value={"3.0"}>3.0</MenuItem>
                                    <MenuItem key={"2.2"} value={"2.2"}>2.2</MenuItem>
                                </Select>
                            </FormControl>}
                    />
                </Box>

                <Divider textAlign="left">{t("contractForm.licenses")}</Divider>
                <Controller
                    name="noTask"
                    control={control}
                    render={({ field, fieldState }) => <TextField {...field} error={Boolean(fieldState.error)} helperText={Boolean(fieldState.error) && fieldState.error.message} label={t("contractForm.numberOfTaskUsers")} variant="outlined" size="small" required />
                    } />
                <Controller
                    name="noProject"
                    control={control}
                    render={({ field, fieldState }) => <TextField {...field} error={Boolean(fieldState.error)} helperText={Boolean(fieldState.error) && fieldState.error.message} label={t("contractForm.numberOfProjectUsers")} variant="outlined" size="small" />
                    } />
                <Controller
                    name="noService"
                    control={control}
                    render={({ field, fieldState }) => <TextField {...field} error={Boolean(fieldState.error)} helperText={Boolean(fieldState.error) && fieldState.error.message} label={t("contractForm.numberOfServiceUsers")} variant="outlined" size="small" />
                    } />
                <Divider textAlign="left">{t("contractForm.validity")}</Divider>
                <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => {
                        const fieldProbs = { ...field, value: dayjs(field.value) }
                        return (
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
                                <DatePicker {...fieldProbs} format="DD.MM.YYYY" label={t("contractForm.validFrom")}
                                    onChange={(e) => {
                                        const startDate = dayjs(e);
                                        const daysValid = watchDateFields[1];
                                        const endDate = startDate.add(daysValid, 'day');
                                        setValue("startDate", startDate.format("YYYY-MM-DD"));
                                        setValue("endDate", endDate.format("YYYY-MM-DD"));
                                    }}
                                />
                            </LocalizationProvider>
                        )
                    }

                    } />
                <Controller
                    name="daysValid"
                    control={control}
                    render={({ field, fieldState }) => <TextField {...field} error={Boolean(fieldState.error)} label={t("contractForm.validForXDays")} variant="outlined" size="small" required helperText={Boolean(fieldState.error) && fieldState.error.message}
                        onChange={(e) => {
                            const startDate = dayjs(watchDateFields[0]);
                            const daysValid = e.target.value;
                            const endDate = startDate.add(daysValid, 'day');
                            setValue("daysValid", daysValid);
                            setValue("endDate", endDate.format("YYYY-MM-DD"));
                        }}
                    />
                    } />
                <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => {
                        const fieldProbs = { ...field, value: dayjs(field.value) }
                        const minDate = dayjs(watchDateFields[0]);
                        return (
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
                                <DatePicker {...fieldProbs} format="DD.MM.YYYY" disablePast minDate={minDate} label={t("contractForm.validUntil")}
                                    onChange={(e) => {
                                        const startDate = dayjs(watchDateFields[0]);
                                        const endDate = dayjs(e)
                                        const daysValid = endDate.diff(startDate, 'day');
                                        setValue("daysValid", daysValid);
                                        setValue("endDate", endDate.format("YYYY-MM-DD"));
                                    }}
                                />
                            </LocalizationProvider>
                        )
                    }
                    } />
            </Stack>
        </form>
    );
}

export default ContractForm;