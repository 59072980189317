import * as React from 'react';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';

// Styled components using the styled utility from Material-UI
const SearchPaper = styled(Paper)(({ theme }) => ({
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    boxShadow: `0px 0px 2px ${theme.palette.grey[500]}`,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    flex: 1,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    padding: 10,
}));

// Generic Search Input Component
const SearchInput = ({ defaultValue = '', placeholder = 'Search...', onSearchChange }) => {
    return (
        <SearchPaper>
            <StyledInputBase
                placeholder={placeholder}
                inputProps={{ 'aria-label': 'search' }}
                onChange={(e) => onSearchChange && onSearchChange(e.target.value)}
            />
            <StyledIconButton type="button" aria-label="search">
                <SearchIcon />
            </StyledIconButton>
        </SearchPaper>
    );
};

export default function CustomSearchInputComponent({ placeholder, onSearchChange }) {

    return (
        < SearchInput placeholder={placeholder} onSearchChange={onSearchChange} />
    );
}
