import * as React from 'react';
import dayjs from 'dayjs';
import {
    Avatar,
    Box,
    ClickAwayListener,
    Collapse,
    Grow,
    IconButton,
    Paper,
    Popper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Chip,
    Tooltip,
    Divider,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import KeyIcon from '@mui/icons-material/Key';

import {
    Edit,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
    MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import StorageIcon from '@mui/icons-material/Storage';
import ContactsIcon from '@mui/icons-material/Contacts';
import PropTypes from 'prop-types';
import { NavLink, } from 'react-router-dom';
import { MenuList, MenuItem } from '@mui/material';
import { getContractStatus } from '../contracts/Constants';
import { sortInststances, groupInstances } from './Constants';

function Row(props) {
    const { row, noRows, handleRowOpen, handleOpenCustomerDialog, handleOpenContractDialog, handleOpenUserDialog, handleOpenInstanceDialog, handleOpenUserPicker, handleDeleteCustomer, handleDeleteContract, handleDeleteInstance, handleRemoveUser, getLicenseKey } = props;
    const anchorRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const [menuOpen, setMenuOpen] = React.useState(false);

    React.useEffect(() => {
        if (open) {
            if (handleRowOpen) handleRowOpen(row)
        }
    }, [open])

    React.useEffect(() => {
        if (noRows === 1) setOpen(true);
        else setOpen(false);
    }, [noRows]);

    const handleToggle = () => {
        setMenuOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setMenuOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setMenuOpen(false);
        } else if (event.key === 'Escape') {
            setMenuOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [menuOpen]);


    const contract = row.contract;
    let contractStatusTableCell = null
    const status = getContractStatus(row.contract?.endDate);
    if (status === "Active") {
        contractStatusTableCell = <Chip label={status} color="success" />
    } else if (status === "Expired") {
        contractStatusTableCell = <Chip label={status} color="error" />
    } else if (status === "Due Soon") {
        contractStatusTableCell = <Chip label={status} color="warning" />
    } else if (status === "Expired Recently") {
        contractStatusTableCell = <Chip label={status} color="error" />
    } else {
        contractStatusTableCell = <Chip label={status} color="info" />
    }


    const instance = row;
    let instanceStatusTableCell = null
    if (instance.status === "Active") {
        instanceStatusTableCell = <Chip label={instance.status} color="success" />
    }
    else if (instance.status === "Inactive") {
        instanceStatusTableCell = <Chip label={instance.status} color="info" />
    }
    else {
        instanceStatusTableCell = <Chip label={instance.status} color="info" />
    }


    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {`ID: ${row.id}`}
                </TableCell>
                <TableCell component="th" scope="row">
                    {instanceStatusTableCell}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.type}
                </TableCell>

                <TableCell component="th" scope="row">
                    {`Customer:  ${row.customer.name}`}
                </TableCell>

                <TableCell component="th" scope="row">
                    {row.ipAddress}
                </TableCell>

                <TableCell align="right">
                    <Tooltip title={row.contract?.endDate}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <Typography variant="body" gutterBottom component="div">
                                Contract:
                            </Typography>

                            {contractStatusTableCell}
                        </Box>
                    </Tooltip>

                </TableCell>

                <TableCell align="right">{row.town}</TableCell>
                <TableCell align="right">{row.street}</TableCell>
                <TableCell align="right">{row.country}</TableCell>
                <TableCell align="right">
                    <NavLink
                        to={"/customers/" + row.customer.id}
                        className={({ isActive, isPending }) =>
                            isPending ? "pending" : isActive ? "active" : ""
                        }
                    >
                        Go To Customer
                    </NavLink>
                </TableCell>
                <TableCell align="right">
                    <div>
                        <IconButton color="primary" aria-label="add to shopping cart"
                            ref={anchorRef}
                            id="composition-button"
                            aria-controls={open ? 'composition-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}>
                            <MoreVertIcon />
                        </IconButton>
                        <Popper
                            style={{ zIndex: 1000 }}
                            open={menuOpen}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement="bottom-start"
                            transition
                            disablePortal
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                                    }}
                                >
                                    <Paper >
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList
                                                autoFocusItem={menuOpen}
                                                id="composition-menu"
                                                aria-labelledby="composition-button"
                                                onKeyDown={handleListKeyDown}
                                            >
                                                <MenuItem key="menu-item-instance-edit" onClick={(e) => {
                                                    handleClose(e);
                                                    handleOpenInstanceDialog(row.customer, row.contract, row);
                                                    // handleOpenCustomerDialog(row);
                                                }}>Edit</MenuItem>
                                                <MenuItem onClick={(e) => {
                                                    handleDeleteInstance(row.id)
                                                    handleClose(e);
                                                }}>Delete</MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Box sx={{ display: "flex", alignItems: "end", gap: 1 }}>
                                <Box>
                                    <StorageIcon sx={{ fontSize: 32 }} />
                                </Box>
                                <Typography variant="h6" gutterBottom component="div" sx={{ marginTop: 2 }}>
                                    Instance details
                                </Typography>
                                <Box sx={{ flexGrow: 1 }} />
                                <Box>
                                    <IconButton color="primary" variant="filled" aria-label='Edit customer' onClick={() => {
                                        handleOpenInstanceDialog(row.customer, row.contract,  row);
                                    }}>
                                        <EditIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            {/* Customer Address Details */}
                            <Table size="small" aria-label="customer details">

                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Status</TableCell>

                                        <TableCell>Type</TableCell>
                                        <TableCell>Name</TableCell>

                                        <TableCell>Ip Addresses</TableCell>
                                    
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    <TableRow key={"row-instance-details"}>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{instanceStatusTableCell}</TableCell>

                                        <TableCell>{row.type}</TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.ipAddress}</TableCell>
                                       
                                    </TableRow>
                                </TableBody>
                            </Table>

                            {/* Contract Details Table */}
                            <Box sx={{ display: "flex", alignItems: "end", gap: 1 }}>
                                <Box>
                                    <HistoryEduIcon sx={{ fontSize: 32 }} />
                                </Box>
                                <Typography variant="h6" gutterBottom component="div" sx={{ marginTop: 2 }}>
                                    Contract
                                </Typography>
                                <Box sx={{ flexGrow: 1 }} />
                                
                            </Box>
                            <Table size="small" aria-label="contract details">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Start</TableCell>
                                        <TableCell>End</TableCell>
                                        <TableCell>Version</TableCell>
                                        <TableCell>User Counts</TableCell>
                                       
                                    </TableRow>
                                </TableHead>

                                <TableBody>

                                    <TableRow key={`contract-id-${contract.id}`}>
                                        <TableCell>{contract.id}</TableCell>
                                        <TableCell>{contractStatusTableCell}</TableCell>
                                        <TableCell>{contract.startDate}</TableCell>
                                        <TableCell>{contract.endDate}</TableCell>
                                        <TableCell>{contract.versionNumber}</TableCell>
                                        <TableCell>{`Task: ${contract.noTask}, Service: ${contract.noService}, Project: ${contract.noProject}`}</TableCell>
                                        
                                    </TableRow>
                                </TableBody>
                            </Table>

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

Row.propTypes = {
    row: PropTypes.shape({

    }).isRequired,
};


export default function InstancesTable(props) {
    const {
        instances,
        sortBy,
        groupBy,
        handleRowOpen,
        handleOpenCustomerDialog,
        handleOpenContractDialog,
        handleOpenUserDialog,
        handleOpenInstanceDialog,
        handleOpenUserPicker,
        handleDeleteCustomer,
        handleDeleteContract,
        handleDeleteInstance,
        handleRemoveUser,
        getLicenseKey,
    } = props;


    const sortedRows = sortInststances(instances, sortBy);
    const groupedRows = groupInstances(sortedRows, groupBy);


    return (groupBy ? Object.keys(groupedRows).map((key) => {
        return (
        <>
            <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
                <Divider textAlign="left" ><Chip label={key} /></Divider>
            </Box>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableBody>
                        {groupedRows[key].map((row, index) => (
                            <Row
                                key={`instance-row-${row.id}-${index}`}
                                row={row}
                                noRows={sortedRows.length}
                                handleRowOpen={handleRowOpen}
                                handleOpenCustomerDialog={handleOpenCustomerDialog}
                                handleOpenContractDialog={handleOpenContractDialog}
                                handleOpenUserDialog={handleOpenUserDialog}
                                handleOpenInstanceDialog={handleOpenInstanceDialog}
                                handleOpenUserPicker={handleOpenUserPicker}
                                handleDeleteCustomer={handleDeleteCustomer}
                                handleDeleteContract={handleDeleteContract}
                                handleDeleteInstance={handleDeleteInstance}
                                handleRemoveUser={handleRemoveUser}
                                getLicenseKey={getLicenseKey}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>)
    }) :
        (<TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableBody>
                    {sortedRows.map((row, index) => (
                        <Row
                            key={`instance-row-${row.id}-${index}`}
                            row={row}
                            noRows={sortedRows.length}
                            handleRowOpen={handleRowOpen}
                            handleOpenCustomerDialog={handleOpenCustomerDialog}
                            handleOpenContractDialog={handleOpenContractDialog}
                            handleOpenUserDialog={handleOpenUserDialog}
                            handleOpenInstanceDialog={handleOpenInstanceDialog}
                            handleOpenUserPicker={handleOpenUserPicker}
                            handleDeleteCustomer={handleDeleteCustomer}
                            handleDeleteContract={handleDeleteContract}
                            handleDeleteInstance={handleDeleteInstance}
                            handleRemoveUser={handleRemoveUser}
                            getLicenseKey={getLicenseKey}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>)
    );
    

}
