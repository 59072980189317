import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Box, Tab, Typography, Paper } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';



const SearchResultItem = (props) => {
  const { title, subtitle, description, handleClickOnSearchResult, subtitleDestination } = props;
  return (
    <Box sx={{
      my: 1,
      mx: 2,
      px: 1,
      py: 1,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'grey.100',
        borderRadius: 2,
      },
    }}
      onClick={
        () => handleClickOnSearchResult()
      }
    >
      <Typography variant="subtitle1" component="h2" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        {title}
        <Box sx={{
          zIndex: 500000,
          backgroundColor: 'grey.300',
          p: 0.5,
          borderRadius: 2,
          '&:hover': {
            backgroundColor: 'grey.400',
          },
        }} >
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            <NavLink to={subtitleDestination} style={{ textDecoration: 'none' }}>{subtitle}</NavLink>
          </Typography>
        </Box>

      </Typography>
      <Typography variant="body2">{description}</Typography> {/* Smaller body text for density */}
    </Box>
  );
};

const SearchMegaMenu = (props) => {
  const { t } = useTranslation();
  const { searchResults, handleClickOnLink } = props;
  const [selectedTab, setSelectedTab] = useState('customers');

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    for (let category in searchResults) {
      if (searchResults[category].length > 0) {
        setSelectedTab(category);
        break;
      }
    }
  }, [searchResults]);


  return (
    <Paper elevation={3} sx={{ p: 2, width: 600, height: 500, margin: '20px auto' }}>
      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTab} aria-label="search results tabs" variant="scrollable" scrollButtons="auto">
            {Object.keys(searchResults).map((key) => {
              const label =  t("appBar.searchMegaMenu." + key.toLowerCase());

              return (
                <Tab key={key} label={`${label} (${searchResults[key].length})`} value={key} />
              )
            }
            )}
          </TabList>
        </Box>
        <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
          {Object.keys(searchResults).map((category) => {
            return (
              <TabPanel key={category} value={category} sx={{ p: 0, maxHeight: 400 }}>
                {searchResults[category].length > 0 ? (
                  searchResults[category].map((item, index) => {
                    let title = "";
                    let description = "";
                    let destination = "";
                    let subtitleDestination = "";
                    let subtitle = "";
                    if (category === "customers") {
                      title = `${item?.name}`;
                      description = `${item?.street}, ${item?.town}, ${item?.zipCode}, ${item?.country}, ${item?.department}`;
                      destination = `/customers/${item?.id}`;
                    } else if (category === "users") {
                      title = item?.firstName + " " + item?.lastName;
                      subtitle = `${t("appBar.searchMegaMenu.goToCustomer")}: ${item.customer?.name}`;
                      description = item.email;
                      destination = `/users/${item.id}`;
                      subtitleDestination = `/customers/${item.customer?.id}`;
                    }
                    return (
                      <SearchResultItem key={index} title={title} subtitle={subtitle} description={description} handleClickOnSearchResult={() => handleClickOnLink(destination)} subtitleDestination={subtitleDestination} />
                    )
                  })
                ) : (
                  <Box sx={{ display: 'flex', justifyContent: 'center', p: 2, fontStyle: 'italic' }}>
                    <Typography sx={{ p: 1 }}>
                      {
                        t("appBar.searchMegaMenu.noResults")
                      }
                    </Typography>
                  </Box>
                )}
              </TabPanel>
            );
          }
          )}
        </Box>
      </TabContext>
    </Paper>
  );
};

SearchMegaMenu.defaultProps = {
  searchResults: {
    Customer: [],
    User: [],
    Contract: [],
    Instance: [],
  },
};

SearchMegaMenu.propTypes = {
  searchResults: PropTypes.any
};

export default SearchMegaMenu;
