export function groupUsers(users, groupBy) {
    // Handle the 'No grouping' case
    if (!groupBy || groupBy === '') {
        return users;
    }
    return users.reduce((acc, user) => {
        let key;
        // Determine the key based on the grouping option
        switch (groupBy) {
            case 'customer':
                key = user.customer?.name;
                break;
            default:
                throw new Error('Unsupported grouping option');
        }

        // Initialize the group if it doesn't already exist
        if (!acc[key]) {
            acc[key] = [];
        }
        // Add the contract to the appropriate group
        acc[key].push(user);
        return acc;
    }, {});
}

export function jsonToCSV(jsonData) {
    // Define the header with customer information first
    const headers = [
        'First Name',
        'Last Name',
        'User Name',
        'Email',
        'Phone Number',
        'Phone Numer 2',
        'Customer Name',
        'Customer Department',
        'Customer Street',
        'Customer Town',
        'Customer Postcode',

    ];
    // Convert JSON data to CSV rows with customer information first
    const rows = jsonData.map(user => [
        user.firstName,
        user.lastName,
        user.username,
        user.email,
        user.phoneNumber1,
        user.phoneNumber2,
        user.customer?.name,
        user.customer?.department,
        user.customer?.street,
        user.customer?.town,
        user.customer?.zipCode,

    ].join(','));
    // Combine headers and rows, and add newline characters
    const csvString = [headers.join(','), ...rows].join('\n');
    return csvString;
}