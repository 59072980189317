import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';

export const ContractMappers = {
    toContractFormDataDto,
    toContractCreateRequestFromFormData,
}


const DEFAULT_DAYS_VALID = 30;


function toContractFormDataDto({customer, contract}) {
    if (customer && contract) {
        return {
            customer: customer || "",
            type: contract.type || "",
            versionNumber: contract.versionNumber || "",
            noTask: contract.noTask || "",
            noProject: contract.noProject || "",
            noService: contract.noService || "",
            startDate: dayjs(contract.startDate).format('YYYY-MM-DD') || "",
            daysValid: dayjs(contract.endDate).diff(dayjs(new Date()), 'day'),
            endDate: dayjs(contract.endDate).format('YYYY-MM-DD') || "",
        }
    } else if (customer) {
        return {
            customer: customer || "",
            type: 'Cloud-Test',
            versionNumber: 'Latest',
            noTask: '10',
            noProject: '10',
            noService: '10',
            startDate: dayjs(new Date()).format('YYYY-MM-DD'),
            daysValid: DEFAULT_DAYS_VALID,
            endDate: dayjs().add(DEFAULT_DAYS_VALID, 'day').format("YYYY-MM-DD"),
        }
    } else {
        return {
            customer: "",
            type: 'Cloud-Test',
            versionNumber: 'Latest',
            noTask: '10',
            noProject: '10',
            noService: '10',
            startDate: dayjs(new Date()).format('YYYY-MM-DD'),
            daysValid: DEFAULT_DAYS_VALID,
            endDate: dayjs().add(DEFAULT_DAYS_VALID, 'day').format("YYYY-MM-DD"),
        }
    }
}

function toContractCreateRequestFromFormData(formData) {
    return {
        customer: formData.customer,
        startDate: formData.startDate,
        endDate: formData.endDate,
        type: formData.type,
        versionNumber: formData.versionNumber,
        noTask: formData.noTask,
        noProject: formData.noProject,
        noService: formData.noService,
    }
}