import React, { useRef } from 'react';
import Dialog from '../misc/Dialog';
import CustomerForm from './CustomerForm';
import { customersApi } from '../customers/CustomersAPI'
import { useKeycloak } from '@react-keycloak/web'
import { useMisc } from '../misc/MiscContext'
import { useTranslation } from 'react-i18next';


export default function CustomerFormDialog({ open, dialogType, selectedCustomer, onSave, onClose }) {
    const formRef = useRef();
    const { keycloak } = useKeycloak()
    const { state: miscState, dispatch: miscDispatch } = useMisc();
    const { t } = useTranslation();


    const handleSaveDialogAdd = (data) => {
        customersApi.createCustomer(data, keycloak.token, ((customers) => {
            miscDispatch({ type: 'SET_SNACKBAR_STATE', payload: { open: true, message: 'Customer added successfully', type: 'success' } });
            if (onSave) onSave();
        }), (error) => {
            miscDispatch({ type: 'SET_SNACKBAR_STATE', payload: { open: true, message: 'Adding customer failed', type: 'error' } });
        });
       
    };

    const handleSaveDialogEdit = (data) => {
        customersApi.updateCustomer(data.id, data, keycloak.token, ((updatedCustomer) => {
            miscDispatch({ type: 'SET_SNACKBAR_STATE', payload: { open: true, message: 'Customer updated successfully', type: 'success' } });
            if (onSave) onSave(updatedCustomer);
        }), (error) => {
            miscDispatch({ type: 'SET_SNACKBAR_STATE', payload: { open: true, message: 'Updating customer failed', type: 'error' } });
        });
    };

    const handleCloseDialog = () => {
        if (onClose) onClose();
    };

    const handleCancelDialog = () => {
        handleCloseDialog();
    };

    return (
        <Dialog
            title={dialogType === "add" ? t('customerFormDialog.addCustomer') : t('customerFormDialog.editCustomer')}
            open={open}
            onClose={handleCloseDialog}
            onSave={() => formRef.current.submitForm()}
            onCancel={handleCancelDialog}>
            <CustomerForm
                reference={formRef}
                onSubmit={dialogType === "add" ? handleSaveDialogAdd : handleSaveDialogEdit}
                formData={dialogType === "edit" && selectedCustomer}
            />
        </Dialog>
    );
}