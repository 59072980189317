
import * as React from 'react';
import { Suspense } from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import reportWebVitals from './reportWebVitals';
import './i18n';

// import './index.css';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    //   <React.StrictMode>
    <ThemeProvider theme={theme}>
        <header>
            <meta name="viewport" content="initial-scale=1, width=device-width" />
        </header>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Suspense fallback="...is loading">
            <App />
        </Suspense>
        
    </ThemeProvider>,
    //   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
