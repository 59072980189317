import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import {
    Box,
    CssBaseline,
    Toolbar,
    Typography,
    IconButton,
    Menu,
    Avatar,
    Tooltip,
    MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NavigationDrawer from './navigationDrawer/navigationDrawer';
import SearchAppBar from './search/search';
import UserFormDialog from '../user/UserFormDialog';
import { useKeycloak } from '@react-keycloak/web'
import { useUsers } from '../users/UsersContext';
import { useCustomers } from '../customers/CustomersContext';
import { usersApi } from '../users/UsersAPI';
import { customersApi } from '../customers/CustomersAPI';
import { useTranslation } from 'react-i18next';


const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export default function Navbar(props) {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(true);
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const { keycloak } = useKeycloak()
    const { state: userState, dispatch: userDispatch } = useUsers();
    const { users, userFormDialogState } = userState;
    const { state: customerState, dispatch: customerDispatch } = useCustomers();
    const { customers, customerFormDialogState } = customerState;

    const loadUsers = () => {
        usersApi.getAllUsers(keycloak.token, (users) => {
            userDispatch({ type: 'SET_USERS', payload: { users } })
        });
    }

    const loadCustomers = () => {
        customersApi.getCustomers(keycloak.token, (customers) => {
            customerDispatch({ type: 'SET_CUSTOMERS', payload: { customers } })
        });
    }


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const settings = [
        {
            label: t("appBar.profile"), onClick: () => {
                userDispatch({ type: 'SET_USER_FORM_DIALOG_STATE', payload: { open: true, dialogType: 'profile', selectedCustomer: null, selectedUser: userState.me, error: "" } })
            }
        },
        {
            label:t("appBar.logout"), onClick: () => {
                keycloak.logout()
            }
        },
    ];

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar sx={{ gap: 2 }}>

                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <img src={process.env.PUBLIC_URL + "/images/licman-icon.png"} width="48" height="48" alt="Allegra Icon" />
                    <Typography variant="h6" noWrap component="div">
                        {t('appBar.appName')}
                    </Typography>
                    {userState.me?.roles.includes("INTERNAL") &&
                        <Box sx={{ display: "flex", flexGrow: 1 }}>
                            <SearchAppBar />
                        </Box>}
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting.label} onClick={() => {
                                    handleCloseUserMenu();
                                    setting.onClick();
                                }}>
                                    <Typography textAlign="center">{setting.label}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <NavigationDrawer
                me={userState.me}
                open={open}
                handleDrawerClose={handleDrawerClose}
            />
            <Main open={open}>
                <Box sx={{ flexGrow: 1, p: 3 }} />
            </Main>
            <UserFormDialog
                open={userFormDialogState.open}
                dialogType={userFormDialogState.dialogType}
                customers={customers}
                selectedCustomer={userFormDialogState.selectedCustomer}
                selectedUser={userFormDialogState.selectedUser}
                error={userFormDialogState.error}
                onClose={() => {
                    userDispatch({ type: 'SET_USER_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null, selectedUser: null, error: "" } })
                }}
                onSave={() => {
                    loadUsers();
                    userDispatch({ type: 'SET_USER_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null, selectedUser: null, error: "" } })
                }}
                onError={(error) => {
                    userDispatch({ type: 'SET_USER_FORM_DIALOG_STATE', payload: { open: true, dialogType: userFormDialogState.dialogType, selectedCustomer: userFormDialogState.selectedCustomer, selectedUser: userFormDialogState.selectedUser, error: error } })
                }}
            />
        </Box>
    );
}