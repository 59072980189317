import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useMisc } from './MiscContext';


export default function ConfirmationDialog() {
  const { state: miscState, dispatch: miscDispatch } = useMisc();
  const { confirmationDialogState } = miscState;
  const { open, title, description, confirmButtonText, onConfirm } = confirmationDialogState;

  const onCancel = () => {
    miscDispatch({ type: 'SET_CONFIRMATION_DIALOG_STATE', payload: { open: false, title: '', description: '', confirmButtonText: '', onConfirm: null } });
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  const handleConfirm = () => {
    if (onConfirm) onConfirm();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        {description &&
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {description}
            </DialogContentText>
          </DialogContent>
        }
        <DialogActions>
          <Button variant={"contained"} color="error" onClick={handleConfirm} autoFocus>
            {confirmButtonText}
          </Button>
          <Button variant={"contained"} onClick={handleCancel}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}