import * as React from 'react'

const defaultState = {
  customers: [],
  customerFormDialogState: {
    open: false,
    dialogType: '',
    selectedCustomer: null
  }
}

const CustomersContext = React.createContext()

function customersReducer(state, action) {
  switch (action.type) {
    case 'SET_CUSTOMERS': {
      const { customers } = action.payload;
      return { ...state, customers: customers }
    }
    case 'SET_CUSTOMER_CONTRACTS': {
      const { customerId, contracts } = action.payload
      const customer = state.customers.find(customer => customer.id === customerId)
      const updatedCustomer = { ...customer, contracts }
      const updatedCustomers = state.customers.map(customer => {
        if (customer.id === updatedCustomer.id) return updatedCustomer
        return customer
      })
      return { ...state, customers: updatedCustomers }
    }
    case 'SET_CUSTOMER_USERS': {
      const { customerId, users } = action.payload
      const customer = state.customers.find(customer => customer.id === customerId)
      const updatedCustomer = { ...customer, users }
      const updatedCustomers = state.customers.map(customer => {
        if (customer.id === updatedCustomer.id) return updatedCustomer
        return customer
      })
      return { ...state, customers: updatedCustomers }
    }
    case 'SET_CUSTOMER_INSTANCES': {
      const { customerId, instances } = action.payload
      const customer = state.customers.find(customer => customer.id === customerId)
      const updatedCustomer = { ...customer, instances }
      const updatedCustomers = state.customers.map(customer => {
        if (customer.id === updatedCustomer.id) return updatedCustomer
        return customer
      })
      return { ...state, customers: updatedCustomers }
    }

    case 'SET_CUSTOMER_COMMENTS': {
      const { customerId, comments } = action.payload
      const customer = state.customers.find(customer => customer.id === customerId)
      const updatedCustomer = { ...customer, comments }
      const updatedCustomers = state.customers.map(customer => {
        if (customer.id === updatedCustomer.id) return updatedCustomer
        return customer
      })
      return { ...state, customers: updatedCustomers }
    }

    case 'SET_CUSTOMER_FORM_DIALOG_STATE': {
      return { ...state, customerFormDialogState: action.payload }
    }

    case 'SET_CUSTOMER_ATTACHMENTS': {
      const { customerId, attachments } = action.payload
      const customer = state.customers.find(customer => customer.id === customerId)
      const updatedCustomer = { ...customer, attachments }
      const updatedCustomers = state.customers.map(customer => {
        if (customer.id === updatedCustomer.id) return updatedCustomer
        return customer
      })
      return { ...state, customers: updatedCustomers }
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function CustomersProvider({ children }) {
  const [state, dispatch] = React.useReducer(customersReducer, defaultState)
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch }
  return <CustomersContext.Provider value={value}>{children}</CustomersContext.Provider>
}

function useCustomers() {
  const context = React.useContext(CustomersContext)
  if (context === undefined) {
    throw new Error('useCustomers must be used within a CustomersProvider')
  }
  return context
}

export { CustomersProvider, useCustomers }