import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import { usersApi } from '../users/UsersAPI';
import { useKeycloak } from '@react-keycloak/web'
import { useTranslation } from 'react-i18next';


export function ComboBox(props) {
    const { t } = useTranslation();
    const { options, onChange } = props;
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');

    return (
        <Autocomplete
            id="combo-box-user-picker"
            options={options}
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
                if (onChange) onChange(event, newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName} ${option.email}`}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <Avatar>{`${option?.firstName?.charAt(0)}${option?.lastName?.charAt(0)}`}</Avatar>
                    </Box>
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"

                    >
                        <Typography noWrap>
                            {`${option.firstName} ${option.lastName}`}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" noWrap>
                            {option.email}
                        </Typography>
                        {option.phoneNumber1 &&
                            <Typography variant="body2" color="text.secondary" noWrap>
                                {option.phoneNumber1}
                            </Typography>}
                    </Stack>
                </Box>


            )}
            renderInput={(params) =>
                <Box sx={{ paddingTop: 1, width: 500 }}>
                    <TextField {...params} placeholder={t("userPicker.searchByEmailOrName")} label={t("userPicker.users")} />
                </Box>
            }
        />
    );
}


export default function UserPicker(props) {
    const { t } = useTranslation();
    const { onAdd, onCreate, onCancel, open } = props;
    const [users, setUsers] = React.useState([]);
    const [selectedUser, setSelectedUser] = React.useState(null);
    const { keycloak } = useKeycloak()

    React.useEffect(() => {
        usersApi.getAllUsers(keycloak.token, (users) => {
            setUsers(users);
        });
    }, []);

    const handleChange = (event, newValue) => {
        setSelectedUser(newValue);
    };

    const handleAdd = () => {
        if (onAdd) onAdd(selectedUser);
        setSelectedUser(null);
    };

    const handleCreate = () => {
        if (onCreate) onCreate();
        setSelectedUser(null);
    }

    const handleCancel = () => {
        if (onCancel) onCancel();
        setSelectedUser(null);
    };

    return (
        <Dialog
            open={open}
        >
            <DialogTitle>{t("userPicker.pickUser")}</DialogTitle>
            <DialogContent>
                <ComboBox options={users} onChange={handleChange} />
                <DialogContentText sx={{ marginTop: 2, display: "flex", gap: 1 }}>
                    <Typography>
                        {t("userPicker.cantFindUser")}
                    </Typography>
                    <Link href="#" underline="none" onClick={() => { handleCreate(); }}>
                    {t("userPicker.createANewOne")}
                    </Link>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    handleCancel();
                }}>{t("userPicker.cancel")}</Button>
                <Button variant="contained" disabled={!selectedUser} onClick={() => {
                    handleAdd();
                }}>{t("userPicker.add")}</Button>

            </DialogActions>
        </Dialog>
    );
}