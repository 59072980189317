import * as React from 'react'

const defaultState = {
    confirmationDialogState: {
        open: false,
        title: '',
        description: '',
        confirmButtonText: '',
        onConfirm: null
    },
    snackbarState: {
        open: false,
        message: '',
        type: 'success',
        duration: 6000,
    }
}


const MiscContext = React.createContext()

function miscReducer(state, action) {
    switch (action.type) {
        case 'SET_CONFIRMATION_DIALOG_STATE': {
            return { ...state, confirmationDialogState: action.payload }
        }
        case 'SET_SNACKBAR_STATE': {
            return { ...state, snackbarState: action.payload }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function MiscProvider({ children }) {
    const [state, dispatch] = React.useReducer(miscReducer, defaultState)
    // NOTE: you *might* need to memoize this value
    // Learn more in http://kcd.im/optimize-context
    const value = { state, dispatch }
    return <MiscContext.Provider value={value}>{children}</MiscContext.Provider>
}

function useMisc() {
    const context = React.useContext(MiscContext)
    if (context === undefined) {
        throw new Error('useMisc must be used within a MiscProvider')
    }
    return context
}

export { MiscProvider, useMisc }