import React, { useMemo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline';
import { Stack, Box, Container, Typography, Button } from '@mui/material';
import HistoryIcon from '@mui/icons-material/HistoryEdu';
import AddIcon from '@mui/icons-material/Add';
import ContractsTable from './ContractsTable';
import CustomerFormDialog from '../customer/CustomerFormDialog';
import ContractFormDialog from '../contract/ContractFormDialog';
import UserFormDialog from '../user/UserFormDialog';
import UserPicker from '../user/UserPicker';
import { customersApi } from '../customers/CustomersAPI'
import { contractsApi } from '../contracts/ContractsAPI';
import { usersApi } from '../users/UsersAPI';
import { useKeycloak } from '@react-keycloak/web'
import { useCustomers } from '../customers/CustomersContext'
import { useContracts } from '../contracts/ContractsContext'
import { useInstances } from '../instances/InstancesContext'
import { useUsers } from '../users/UsersContext'
import { useMisc } from '../misc/MiscContext'
import BasicSelect from '../misc/BasicSelect';
import MenuButton from '../misc/MenuButton';
import { download } from '../../utils/Constants';
import { getContractStatus } from './Constants';
import SearchInput from '../misc/SearchInput';
import { getEntryById, searchEntries, jsonToCSV } from '../../utils/Constants';


const GROUPING_OPTIONS = [
    { value: '', label: 'No grouping' },
    { value: 'customer', label: 'Customer' },
    { value: 'status', label: 'Status' },
    { value: 'type', label: 'Type' },
];

const SORTING_OPTIONS = [
    { value: '', label: 'No sorting' },
    { value: 'status', label: 'Status' },
    { value: 'endDate', label: 'End Date' },
    { value: 'type', label: 'Type' },
];

export default function ContractsPage() {
    const { keycloak } = useKeycloak() // Keycloak instance for auth.
    const { contractId } = useParams(); // Retrieve the customer ID from the URL if available.

    // State and dispatch functions from context for managing global state.
    const { state: customerState, dispatch: customerDispatch } = useCustomers();
    const { state: contractState, dispatch: contractDispatch } = useContracts();
    const { state: instanceState, dispatch: instanceDispatch } = useInstances();
    const { state: userState, dispatch: userDispatch } = useUsers();
    const { state: miscState, dispatch: miscDispatch } = useMisc();

    // Local component state for managing UI elements search text.
    const [searchText, setSearchText] = useState('');
    const [groupBy, setGroupBy] = useState('status');
    const [sortBy, setSortBy] = useState('endDate');

    // Destructuring state for easier access to specific parts of the state.
    const { customers, customerFormDialogState } = customerState;
    const { contracts, contractFormDialogState } = contractState; // Customer specific contracts are loaded when a customer is opened. So we don't need to load them here.
    const { users, userFormDialogState, userPickerDialogState } = userState;

    const loadCustomers = () => {
        customersApi.getCustomers(keycloak.token, (customers) => {
            customerDispatch({ type: 'SET_CUSTOMERS', payload: { customers } })
        });
    }

    // Loading and setting data functions.
    const loadContracts = () => {
        contractsApi.getContracts(keycloak.token, (contracts) => {
            contractDispatch({ type: 'SET_CONTRACTS', payload: { contracts } })
        });
    }

    const loadUsers = () => {
        usersApi.getAllUsers(keycloak.token, (users) => {
            userDispatch({ type: 'SET_USERS', payload: { users } })
        });
    }

    // Load customers on component mount.
    useEffect(() => {
        loadContracts();
        loadCustomers();
        loadUsers();
    }, []);


    // Denormalise the contracts with customers and contacts.
    const contractsWithCustomersAndContacts = useMemo(() => {
        return contracts.map(contract => {
            const customer = customers.filter(customer => customer.id === contract.customer.id)[0];
            const contacts = users.filter(user => user.customer?.id === customer?.id);
            return { ...contract, customer: customer, contacts: contacts }
        });
    }, [contracts, customers, users]);


    //Search and filter functionality. It only searches the contract entity. Because the other entities are loaded when a contract is opened, they are not searched here.
    const filteredContracts = useMemo(() => {
        const filteredCustomersById = contractId ? getEntryById(contractsWithCustomersAndContacts, contractId) : contractsWithCustomersAndContacts;
        return searchEntries(filteredCustomersById, searchText);
    }, [contracts, users, customers, contractId, searchText]);


    const handleSearch = (searchTerm) => {
        setSearchText(searchTerm);
    };


    // Dialog open and close functions.
    const handleOpenUserDialog = (selectedCustomer = null, selectedUser = null) => {
        userDispatch({ type: 'SET_USER_FORM_DIALOG_STATE', payload: { open: true, dialogType: selectedUser ? "edit" : "add", selectedCustomer: selectedCustomer, selectedUser: selectedUser, error: "" } })
    }

    const handleOpenCustomerDialog = (selectedCustomer = null) => {
        customerDispatch({ type: 'SET_CUSTOMER_FORM_DIALOG_STATE', payload: { open: true, dialogType: selectedCustomer ? "edit" : "add", selectedCustomer: selectedCustomer } })
    }
    const handleOpenContractDialog = (selectedCustomer = null, selectedContract = null) => {
        contractDispatch({ type: 'SET_CONTRACT_FORM_DIALOG_STATE', payload: { open: true, dialogType: selectedContract ? "edit" : "add", selectedCustomer: selectedCustomer, selectedContract: selectedContract } })
    }
    const handleOpenUserPicker = ({ open = false, selectedCustomer = null, selectedUser = null }) => {
        userDispatch({ type: 'SET_USER_PICKER_DIALOG_STATE', payload: { open, selectedCustomer, selectedUser } })
    }

    const handleRemoveUser = (customerId, userId) => {
        customersApi.removeCustomerContact(customerId, userId, keycloak.token, ((customers) => {
            loadUsers();
        }));
    };

    const handleDeleteContract = (customerID, contractId) => {
        miscDispatch({
            type: 'SET_CONFIRMATION_DIALOG_STATE', payload: {
                open: true,
                title: "Delete Contract And Connected Instances",
                description: "Are you sure you want to delete this contract? This will also delete all instances associated with this contract.",
                confirmButtonText: "Delete",
                onConfirm: () => {
                    contractsApi.deleteContract(contractId, keycloak.token, (() => {
                        loadContracts();
                    }));
                    miscDispatch({ type: 'SET_CONFIRMATION_DIALOG_STATE', payload: { open: false, title: '', description: '', confirmButtonText: '', onConfirm: null } });
                }
            }
        });
    };

    const handleUserPickerAdd = (selectedUser) => {
        const selectedCustomer = userPickerDialogState.selectedCustomer;
        customersApi.addCustomerContact(selectedCustomer.id, selectedUser.id, keycloak.token, (customers) => {
            loadUsers();
        });
        handleOpenUserPicker({});
    }

    const handleUserPickerCreate = () => {
        const selectedCustomer = userPickerDialogState.selectedCustomer;
        handleOpenUserPicker({});
        handleOpenUserDialog(selectedCustomer);
    }

    const handleUserPickerCancel = () => {
        handleOpenUserPicker({});
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Container>
                <Stack sx={{ height: '100vh' }} >
                    <Box sx={{ display: "flex", gap: 1 }}>
                        <HistoryIcon sx={{ fontSize: 40 }} />
                        <Typography variant="h4" gutterBottom>
                            {contractId && typeof filteredContracts[0] !== 'undefined' ? "Contract " + "ID:" + filteredContracts[0].id + " " + filteredContracts[0].customer.name : "Contracts"}
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Button onClick={() => handleOpenContractDialog()} variant="contained" startIcon={<AddIcon />}>
                            Add Contract
                        </Button>
                    </Box>
                    <Typography variant="body1" gutterBottom>
                        Manage contracts here
                    </Typography>
                    <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                        <SearchInput defaultValue="" placeholder="Type to search..." onSearchChange={handleSearch} />
                    </Box>
                    <Box sx={{ marginTop: 1, display: "flex", gap: 2 }}>
                        <BasicSelect
                            label="Sort by"
                            value={sortBy}
                            options={SORTING_OPTIONS}
                            onChange={(e) => setSortBy(e.target.value)}
                        />
                        <BasicSelect
                            label="Group by"
                            value={groupBy}
                            options={GROUPING_OPTIONS}
                            onChange={(e) => setGroupBy(e.target.value)}
                        />
                        <Box sx={{ flexGrow: 1 }} />
                        <MenuButton
                            label="Export"
                            options={[
                                { value: '1', label: 'All' },
                                { value: '2', label: 'Active' },
                                { value: '3', label: 'Expired' },
                                { value: '4', label: 'Due Soon and Expired Recently' },
                            ]}
                            onChange={(value) => {
                                let filteredContracts = [];
                                let fileName = 'contracts';
                                if (value == '2') {
                                    filteredContracts = contractsWithCustomersAndContacts.filter(contract => getContractStatus(contract.endDate) === 'Active');
                                    fileName = 'active_contracts';
                                } else if (value == '3') {
                                    filteredContracts = contractsWithCustomersAndContacts.filter(contract => getContractStatus(contract.endDate) === 'Expired');
                                    fileName = 'due_soon_and_expired_contracts';
                                }
                                else if (value == '4') {
                                    filteredContracts = contractsWithCustomersAndContacts.filter(contract => getContractStatus(contract.endDate) === 'Due Soon' || getContractStatus(contract.endDate) === 'Expired Recently');
                                    fileName = 'due_soon_and_expired_contracts';
                                } else {
                                    filteredContracts = [...contractsWithCustomersAndContacts];
                                    fileName = 'all_contracts';
                                }
                                const csv = jsonToCSV(filteredContracts);
                                const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(csv);
                                const date = new Date();
                                download(dataStr, fileName + "-" + date.getFullYear() + '-' + date.getMonth() + '-' + date.getDay() + '-' + date.getHours() + ':' + date.getMinutes() + '.csv');
                            }}
                        />
                    </Box>
                    <Box sx={{ marginTop: 2 }}>
                        <ContractsTable
                            contracts={filteredContracts}
                            groupBy={groupBy}
                            sortBy={sortBy}
                            handleRemoveUser={handleRemoveUser}
                            handleOpenContractDialog={handleOpenContractDialog}
                            handleOpenCustomerDialog={handleOpenCustomerDialog}
                            handleOpenUserDialog={handleOpenUserDialog}
                            handleOpenUserPicker={handleOpenUserPicker}
                            handleDeleteContract={handleDeleteContract}

                        />
                    </Box>
                </Stack>
            </Container>

            <UserPicker
                open={userPickerDialogState.open}
                onAdd={handleUserPickerAdd}
                onCreate={handleUserPickerCreate}
                onCancel={handleUserPickerCancel}
            />

            <UserFormDialog
                open={userFormDialogState.open}
                dialogType={userFormDialogState.dialogType}
                customers={customers}
                selectedCustomer={userFormDialogState.selectedCustomer}
                selectedUser={userFormDialogState.selectedUser}
                error={userFormDialogState.error}
                onClose={() => {
                    userDispatch({ type: 'SET_USER_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null, selectedUser: null, error: "" } })
                }}
                onSave={() => {
                    loadUsers();
                    userDispatch({ type: 'SET_USER_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null, selectedUser: null, error: "" } })
                }}
                onError={(error) => {
                    userDispatch({ type: 'SET_USER_FORM_DIALOG_STATE', payload: { open: true, dialogType: userFormDialogState.dialogType, selectedCustomer: userFormDialogState.selectedCustomer, selectedUser: userFormDialogState.selectedUser, error: error } })
                }}
            />

            <CustomerFormDialog
                open={customerFormDialogState.open}
                dialogType={customerFormDialogState.dialogType}
                selectedCustomer={customerFormDialogState.selectedCustomer}
                onClose={() => {
                    customerDispatch({ type: 'SET_CUSTOMER_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null } })
                }}
                onSave={() => {
                    loadContracts()
                    customerDispatch({ type: 'SET_CUSTOMER_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null } })
                }}
            />
            <ContractFormDialog
                open={contractFormDialogState.open}
                dialogType={contractFormDialogState.dialogType}
                customers={customers}
                selectedCustomer={contractFormDialogState.selectedCustomer}
                selectedContract={contractFormDialogState.selectedContract}
                onClose={() => {
                    contractDispatch({ type: 'SET_CONTRACT_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null, selectedContract: null } })
                }}
                onSave={() => {
                    loadContracts();
                    contractDispatch({ type: 'SET_CONTRACT_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null, selectedContract: null } })
                }}
            />
        </React.Fragment>
    );
}