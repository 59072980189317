const prod = {
  url: {
    BASE_URL: '/',
    KEYCLOAK_BASE_URL: "https://lm.alltena.com/sso",
    KEYCLOAK_REALM: "customers",
    KEYCLOAK_CLIENT_ID: "license-manager",
    API_BASE_URL: 'https://lm.alltena.com/licman',
    OMDB_BASE_URL: 'https://www.omdbapi.com',
    AVATARS_DICEBEAR_URL: 'https://api.dicebear.com/6.x'
  }
}

// const dev = {
//   url: {
//     BASE_URL: '/',
//     KEYCLOAK_BASE_URL: "https://lm.alltena.com/sso",
//     KEYCLOAK_REALM: "customers",
//     KEYCLOAK_CLIENT_ID: "license-manager",
//     API_BASE_URL: 'http://localhost:9080',
//     OMDB_BASE_URL: 'https://www.omdbapi.com',
//     AVATARS_DICEBEAR_URL: 'https://api.dicebear.com/6.x'
//   }
// }

const dev = {
  url: {
    BASE_URL: '/',
    KEYCLOAK_BASE_URL: "http://localhost:8080",
    KEYCLOAK_REALM: "company-services",
    KEYCLOAK_CLIENT_ID: "license-app",
    API_BASE_URL: 'http://localhost:9080',
    OMDB_BASE_URL: 'https://www.omdbapi.com',
    AVATARS_DICEBEAR_URL: 'https://api.dicebear.com/6.x'
  }
}

export const config = process.env.NODE_ENV === 'development' ? dev : prod
