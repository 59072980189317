import React, { useState, useRef } from 'react';
import {
  Table, TableBody, TableCell, TableHead, TableRow,
  IconButton, Typography, Box
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useTranslation } from 'react-i18next';


function AttachmentsTable({ attachments = [], uploadFile = () => { }, downloadFile = () => { }, deleteFile = () => { } }) {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const newAttachment = {
        id: Date.now(),
        name: file.name,
        file: URL.createObjectURL(file)
      };
      uploadFile(file);
    }
  };

  const handleFileDownload = (fileId) => {
    if (downloadFile) downloadFile(fileId);
  };

  const handleDeleteAttachment = (fileId) => {
    if (deleteFile) deleteFile(fileId);
  };

  const handleAddAttachment = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "end", gap: 1 }}>
        <Box>
          <AttachFileIcon sx={{ fontSize: 32 }} />
        </Box>
        <Typography variant="h6" gutterBottom component="div" sx={{ marginTop: 2 }}>
          {t('attachments.attachments')}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <Box>
            <IconButton color="primary" aria-label="add attachment" onClick={handleAddAttachment}>
              <AddIcon />
            </IconButton>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </Box>
        </Box>
      </Box>
      <Table size="small" aria-label="attachment details">
        <TableHead>
          <TableRow>
            <TableCell>{t('attachments.name')}</TableCell>
            <TableCell>{t('attachments.uploadedOn')}</TableCell>
            <TableCell align="right">{t('customersTable.contacts.actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attachments.map((attachment) => (
            <TableRow key={attachment.id}>
              <TableCell>{attachment.fileName}</TableCell>
              <TableCell>{attachment.createdAt}</TableCell>
              <TableCell align="right">
                <IconButton color="primary" onClick={() => handleDeleteAttachment(attachment.id)}>
                  <DeleteIcon />
                </IconButton>
                <IconButton color="primary" onClick={() => handleFileDownload(attachment.id)}>
                  <DownloadIcon />
                </IconButton>
              </TableCell>
            
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default AttachmentsTable;
