import React, { useMemo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline';
import { Stack, Box, Container, Typography, Button } from '@mui/material';
import HistoryIcon from '@mui/icons-material/HistoryEdu';
import AddIcon from '@mui/icons-material/Add';
import StorageIcon from '@mui/icons-material/Storage';
import InstancesTable from './InstancesTable';
import CustomerFormDialog from '../customer/CustomerFormDialog';
import ContractFormDialog from '../contract/ContractFormDialog';
import InstanceFormDialog from '../instance/InstanceFormDialog';
import { customersApi } from '../customers/CustomersAPI'
import { contractsApi } from '../contracts/ContractsAPI';
import { instancesApi } from '../instances/InstancesAPI';
import { useKeycloak } from '@react-keycloak/web'
import { useCustomers } from '../customers/CustomersContext'
import { useContracts } from '../contracts/ContractsContext'
import { useInstances } from '../instances/InstancesContext'
import { useUsers } from '../users/UsersContext'
import { useMisc } from '../misc/MiscContext'
import BasicSelect from '../misc/BasicSelect';
import MenuButton from '../misc/MenuButton';
import { download } from '../../utils/Constants';
// import { getContractStatus } from './Constants';
import SearchInput from '../misc/SearchInput';
import { getEntryById, searchEntries, jsonToCSV } from '../../utils/Constants';


const GROUPING_OPTIONS = [
    { value: '', label: 'No grouping'},
    { value: 'customer', label: 'Customer' },
    { value: 'status', label: 'Status' },
    { value: 'type', label: 'Type' },
];

const SORTING_OPTIONS = [ 
    { value: '', label: 'No sorting' },
    { value: 'status', label: 'Status' },
    { value: 'type', label: 'Type' },
];

export default function InstancesPage() {
    const { keycloak } = useKeycloak() // Keycloak instance for auth.
    const { instanceId } = useParams(); // Retrieve the customer ID from the URL if available.

    // State and dispatch functions from context for managing global state.
    const { state: customerState, dispatch: customerDispatch } = useCustomers();
    const { state: contractState, dispatch: contractDispatch } = useContracts();
    const { state: instanceState, dispatch: instanceDispatch } = useInstances();
    const { state: userState, dispatch: userDispatch } = useUsers();
    const { state: miscState, dispatch: miscDispatch } = useMisc();

    // Local component state for managing UI elements search text.
    const [searchText, setSearchText] = useState('');
    const [groupBy, setGroupBy] = useState('status');
    const [sortBy, setSortBy] = useState('status');

    // Destructuring state for easier access to specific parts of the state.
    const { customers, customerFormDialogState } = customerState;
    const { contracts, contractFormDialogState } = contractState; // Customer specific contracts are loaded when a customer is opened. So we don't need to load them here.
    const { instances, instanceFormDialogState } = instanceState;


    const loadInstances = () => {
        instancesApi.getAllInstances(keycloak.token, (instances) => {
            instanceDispatch({ type: 'SET_INSTANCES', payload: { instances } })
        });
    }


    const loadCustomers = () => {
        customersApi.getCustomers(keycloak.token, (customers) => {
            customerDispatch({ type: 'SET_CUSTOMERS', payload: { customers } })
        });
    }

    // Loading and setting data functions.
    const loadContracts = () => {
        contractsApi.getContracts(keycloak.token, (contracts) => {
            contractDispatch({ type: 'SET_CONTRACTS', payload: { contracts } })
        });
    }

    // Load instances, cusomters and contracts on component mount.
    useEffect(() => {
        loadInstances();
        loadCustomers();
        loadContracts();
    }, []);

    const customersWithContracts = useMemo(() => {
        return customers.map(customer => {
            const customerContracts = contracts.filter(contract => contract.customer.id === customer.id);
            return { ...customer, contracts: customerContracts }
        
        });
    }, [customers, contracts])

    const instancesWithCustomersAndContracts = useMemo(() => {
        return instances.map(instance => {
            const contract = contracts.find(contract => contract.id === instance.contract.id);
            const customer = customersWithContracts.find(customer => customer.id === contract.customer.id);
            return { ...instance, customer: customer, contract: contract }
        });
    }, [instances, customers, contracts]);

    //Search and filter functionality. It only searches the contract entity. Because the other entities are loaded when a contract is opened, they are not searched here.
    const filteredInstances = useMemo(() => {
        const filteredInstancesById = instanceId ? getEntryById(instancesWithCustomersAndContracts, instanceId) : instancesWithCustomersAndContracts;
        return searchEntries(filteredInstancesById, searchText);
    }, [instances, instanceId, searchText]);


    const handleSearch = (searchTerm) => {
        setSearchText(searchTerm);
    };

    // Dialog open and close functions.

    const handleOpenInstanceDialog = (selectedCustomer = null, selectedContract = null, selectedInstance = null) => {
        instanceDispatch({ type: 'SET_INSTANCE_FORM_DIALOG_STATE', payload: { open: true, dialogType: selectedInstance ? "edit" : "add", selectedCustomer: selectedCustomer, selectedContract: selectedContract, selectedInstance: selectedInstance } })
    }

    const handleOpenCustomerDialog = (selectedCustomer = null) => {
        customerDispatch({ type: 'SET_CUSTOMER_FORM_DIALOG_STATE', payload: { open: true, dialogType: selectedCustomer ? "edit" : "add", selectedCustomer: selectedCustomer } })
    }
    const handleOpenContractDialog = (selectedCustomer = null, selectedContract = null) => {
        contractDispatch({ type: 'SET_CONTRACT_FORM_DIALOG_STATE', payload: { open: true, dialogType: selectedContract ? "edit" : "add", selectedCustomer: selectedCustomer, selectedContract: selectedContract } })
    }

    const handleDeleteInstance = (instanceId) => {
        miscDispatch({
            type: 'SET_CONFIRMATION_DIALOG_STATE', payload: {
                open: true,
                title: "Delete Instance",
                description: "Are you sure you want to delete this instance?",
                confirmButtonText: "Delete",
                onConfirm: () => {
                    instancesApi.deleteInstance(instanceId, keycloak.token, (() => {
                        loadInstances();
                    }));
                    miscDispatch({ type: 'SET_CONFIRMATION_DIALOG_STATE', payload: { open: false, title: '', description: '', confirmButtonText: '', onConfirm: null } });
                }
            }
        });
    };

    const handleDeleteContract = (customerID, contractId) => {
        miscDispatch({
            type: 'SET_CONFIRMATION_DIALOG_STATE', payload: {
                open: true,
                title: "Delete Contract And Connected Instances",
                description: "Are you sure you want to delete this contract? This will also delete all instances associated with this contract.",
                confirmButtonText: "Delete",
                onConfirm: () => {
                    contractsApi.deleteContract(contractId, keycloak.token, (() => {
                        loadContracts();
                    }));
                    miscDispatch({ type: 'SET_CONFIRMATION_DIALOG_STATE', payload: { open: false, title: '', description: '', confirmButtonText: '', onConfirm: null } });
                }
            }
        });
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <Container>
                <Stack sx={{ height: '100vh' }} >
                    <Box sx={{ display: "flex", gap: 1 }}>
                        <StorageIcon sx={{ fontSize: 40 }} />
                        <Typography variant="h4" gutterBottom>
                            {instanceId && typeof filteredInstances[0] !== 'undefined' ? "Instance " + "ID:" + filteredInstances[0].id + " " + filteredInstances[0].name : "Instances"}
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Button onClick={() => handleOpenInstanceDialog()} variant="contained" startIcon={<AddIcon />}>
                            Add Instance
                        </Button>
                    </Box>
                    <Typography variant="body1" gutterBottom>
                        Manage instances here
                    </Typography>
                    <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                        <SearchInput defaultValue="" placeholder="Type to search..." onSearchChange={handleSearch} />
                    </Box>
                    <Box sx={{ marginTop: 1, display: "flex", gap: 2 }}>
                        <BasicSelect
                            label="Sort by"
                            value={sortBy}
                            options={SORTING_OPTIONS}
                            onChange={(e) => setSortBy(e.target.value)}
                        />
                        <BasicSelect
                            label="Group by"
                            value={groupBy}
                            options={GROUPING_OPTIONS}
                            onChange={(e) => setGroupBy(e.target.value)}
                        />
                        <Box sx={{ flexGrow: 1 }} />
                        <MenuButton
                            label="Export"
                            options={[
                                { value: '1', label: 'Active' },
                                { value: '2', label: 'Inactive' },
                                { value: '3', label: 'All' },
                            ]}
                            onChange={(value) => {
                                let filteredInstances = [];
                                let fileName = 'instances';
                                if (value == '2') {
                                    filteredInstances = instances.filter(instance => instance.status === 'Active');
                                    fileName = 'active_instances';
                                } else if (value == '3') {
                                    filteredInstances =  instances.filter(instance => instance.status === 'Inactive');
                                    fileName = 'inactive_instances';
                                } else {
                                    filteredInstances = [...instances];
                                    fileName = 'all_instances';
                                }
                                const csv = jsonToCSV(filteredInstances);
                                const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(csv);
                                const date = new Date();
                                download(dataStr, fileName + "-" + date.getFullYear() + '-' + date.getMonth() + '-' + date.getDay() + '-' + date.getHours() + ':' + date.getMinutes() + '.csv');
                            }}
                        />
                    </Box>
                    <Box sx={{ marginTop: 2 }}>
                        <InstancesTable
                            instances={filteredInstances}
                            groupBy={groupBy}
                            sortBy={sortBy}
                            handleOpenInstanceDialog={handleOpenInstanceDialog}
                            handleOpenContractDialog={handleOpenContractDialog}
                            handleOpenCustomerDialog={handleOpenCustomerDialog}
                            handleDeleteContract={handleDeleteContract}
                            handleDeleteInstance={handleDeleteInstance}

                        />
                    </Box>
                </Stack>
            </Container>
            <CustomerFormDialog
                open={customerFormDialogState.open}
                dialogType={customerFormDialogState.dialogType}
                selectedCustomer={customerFormDialogState.selectedCustomer}
                onClose={() => {
                    customerDispatch({ type: 'SET_CUSTOMER_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null } })
                }}
                onSave={() => {
                    loadContracts()
                    customerDispatch({ type: 'SET_CUSTOMER_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null } })
                }}
            />
            <ContractFormDialog
                open={contractFormDialogState.open}
                dialogType={contractFormDialogState.dialogType}
                customers={customers}
                selectedCustomer={contractFormDialogState.selectedCustomer}
                selectedContract={contractFormDialogState.selectedContract}
                onClose={() => {
                    contractDispatch({ type: 'SET_CONTRACT_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null, selectedContract: null } })
                }}
                onSave={() => {
                    loadContracts();
                    contractDispatch({ type: 'SET_CONTRACT_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null, selectedContract: null } })
                }}
            />

            <InstanceFormDialog
                open={instanceFormDialogState.open}
                dialogType={instanceFormDialogState.dialogType}
                customers={customersWithContracts}
                selectedCustomer={instanceFormDialogState.selectedCustomer}
                selectedContract={instanceFormDialogState.selectedContract}
                selectedInstance={instanceFormDialogState.selectedInstance}
                onClose={() => {
                    instanceDispatch({ type: 'SET_INSTANCE_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null, selectedContract: null, selectedInstance: null } })
                }}
                onSave={() => {
                    loadInstances();
                    instanceDispatch({ type: 'SET_INSTANCE_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null, selectedContract: null, selectedInstance: null } })
                }}
            />
        </React.Fragment>
    );
}