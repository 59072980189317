import * as React from 'react'

const defaultState = {
    me: null,
    users: [],
    userFormDialogState: {
        open: false,
        dialogType: '',
        selectedCustomer: null,
        selectedUser: null,
        error: ''
    },
    userPickerDialogState: {
        open: false,
        selectedCustomer: null,
        selectedUser: null
    },
}

const UsersContext = React.createContext()

function usersReducer(state, action) {
    switch (action.type) {
        case 'SET_ME': {
            const { me } = action.payload;
            return { ...state, me: me }
        }
        case 'SET_USERS': {
            const { users } = action.payload;
            return { ...state, users: users }
        }
        case 'SET_USER_FORM_DIALOG_STATE': {
            return { ...state, userFormDialogState: action.payload }
        }
        case 'SET_USER_PICKER_DIALOG_STATE': {
            return { ...state, userPickerDialogState: action.payload }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function UsersProvider({ children }) {
    const [state, dispatch] = React.useReducer(usersReducer, defaultState)
    // NOTE: you *might* need to memoize this value
    // Learn more in http://kcd.im/optimize-context
    const value = { state, dispatch }
    return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
}

function useUsers() {
    const context = React.useContext(UsersContext)
    if (context === undefined) {
        throw new Error('useUsers must be used within a UsersProvider')
    }
    return context
}

export { UsersProvider, useUsers }