export function sortInststances(instances, sortBy) {
    // Handle the 'No sorting' case
    if (!sortBy || sortBy === '') {
        return instances; // return the original array without sorting
    }

    // Clone the contracts to avoid mutating the original array
    let sortedInstances = [...instances];

    sortedInstances.sort((a, b) => {
        if (sortBy === 'status') {
            // Assuming getStatusByEndDate is defined and returns 'Expired' or 'Active'
            let statusA = a.status;
            let statusB = b.status;
            return statusA.localeCompare(statusB);
        } else if (sortBy === 'type') {
            return a.type.localeCompare(b.type); // alphabetical order
        }
        // Default case: should not be reached if sortBy is validated
        return 0;
    });

    return sortedInstances;
}

export function groupInstances(instances, groupBy) {
    // Handle the 'No grouping' case
    if (!groupBy || groupBy === '') {
        return instances;
    }
    return instances.reduce((acc, instance) => {
        let key;
        // Determine the key based on the grouping option
        switch (groupBy) {
            case 'customer':
                key = instance.customer.name;
                break;
            case 'status':
                key = instance.status;
                break;
            case 'type':
                key = instance.type;
                break;
            default:
                throw new Error('Unsupported grouping option');
        }

        // Initialize the group if it doesn't already exist
        if (!acc[key]) {
            acc[key] = [];
        }
        // Add the contract to the appropriate group
        acc[key].push(instance);
        return acc;
    }, {});
}