import React, { useMemo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline';
import { Stack, Box, Container, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchInput from '../misc/SearchInput';
import CustomersTable from './CustomersTable';
import CustomerFormDialog from '../customer/CustomerFormDialog';
import ContractFormDialog from '../contract/ContractFormDialog';
import UserFormDialog from '../user/UserFormDialog';
import InstanceFormDialog from '../instance/InstanceFormDialog';
import UserPicker from '../user/UserPicker';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { customersApi } from './CustomersAPI'
import { contractsApi } from '../contracts/ContractsAPI';
import { usersApi } from '../users/UsersAPI';
import { instancesApi } from '../instances/InstancesAPI';
import { commentsApi } from '../comments/CommentsAPI';
//import { searchCustomers, getCustomerById } from './helpers/Search';
import { useKeycloak } from '@react-keycloak/web'
import { useCustomers } from './CustomersContext'
import { useContracts } from '../contracts/ContractsContext'
import { useInstances } from '../instances/InstancesContext'
import { useUsers } from '../users/UsersContext'
import { useMisc } from '../misc/MiscContext'
import { getEntryById, searchEntries } from '../../utils/Constants';
import { useTranslation } from 'react-i18next';
import { TramRounded } from '@mui/icons-material';

// import Button from '@mui/material-next/Button';
// import { CssVarsProvider } from '@mui/material-next/styles';

export default function CustomersPage() {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak() // Keycloak instance for auth.
    const { customerId } = useParams(); // Retrieve the customer ID from the URL if available.

    // State and dispatch functions from context for managing global state.
    const { state: customerState, dispatch: customerDispatch } = useCustomers();
    const { state: contractState, dispatch: contractDispatch } = useContracts();
    const { state: instanceState, dispatch: instanceDispatch } = useInstances();
    const { state: userState, dispatch: userDispatch } = useUsers();
    const { state: miscState, dispatch: miscDispatch } = useMisc();

    // Local component state for managing UI elements search text.
    const [searchText, setSearchText] = useState('');

    // Destructuring state for easier access to specific parts of the state.
    const { customers, customerFormDialogState } = customerState;
    const { contracts, contractFormDialogState } = contractState; // Customer specific contracts are loaded when a customer is opened. So we don't need to load them here.
    const { instances, instanceFormDialogState } = instanceState; // Customer specific instances are loaded when a customer is opened. So we don't need to load them here.
    const { me, users, userFormDialogState, userPickerDialogState } = userState; // Customer specific users are loaded when a customer is opened. So we don't need to load them here.
    const { confirmationDialogState } = miscState; // Confirmation dialog state is managed in the MiscContext.

    // Loading and setting data functions.
    const loadCustomers = () => {
        customersApi.getCustomers(keycloak.token, (customers) => {
            customerDispatch({ type: 'SET_CUSTOMERS', payload: { customers } })
        });
    }
    const loadCustomerComments = (customerId) => {
        commentsApi.getCommentsByCustomerId(customerId, keycloak.token, (comments) => {
            customerDispatch({ type: 'SET_CUSTOMER_COMMENTS', payload: { customerId, comments } })
        }
        );
    }
    const loadCustomerContracts = (customerId) => {
        contractsApi.getContractsByCustomerId(customerId, keycloak.token, (contracts) => {
            customerDispatch({ type: 'SET_CUSTOMER_CONTRACTS', payload: { customerId, contracts } })
        });
    }
    const loadCustomerContacts = (customerId) => {
        usersApi.getUsersByCustomerId(customerId, keycloak.token, (users) => {
            customerDispatch({ type: 'SET_CUSTOMER_USERS', payload: { customerId, users } })
        });
    }
    const loadCustomerInstances = (customerId) => {
        instancesApi.getInstancesByCustomerId(customerId, keycloak.token, (instances) => {
            customerDispatch({ type: 'SET_CUSTOMER_INSTANCES', payload: { customerId, instances } })
        });
    }

    const loadFiles = (customerId) => {
        customersApi.getFiles(customerId, keycloak.token, (attachments) => {
            customerDispatch({ type: 'SET_CUSTOMER_ATTACHMENTS', payload: { customerId, attachments } })
        });
    }

    console.log(customers)

    // Load customers on component mount.
    useEffect(() => {
        loadCustomers();
    }, []);

    const handleRowOpen = (customer) => {
        const customerId = customer.id
        loadCustomerComments(customerId);
        loadCustomerContracts(customerId);
        loadCustomerContacts(customerId);
        loadCustomerInstances(customerId);
        loadFiles(customerId);
    }

    // Search and filter functionality. It only searches the customer entity. Because the other entities are loaded when a customer is opened, they are not searched here.
    const filteredCustomers = useMemo(() => {
        const filteredCustomersById = customerId ? getEntryById(customers, customerId) : customers;
        return searchEntries(filteredCustomersById, searchText);
    }, [customers, customerId, searchText]);

    const handleSearch = (searchTerm) => {
        setSearchText(searchTerm);
    };

    // Dialog open and close functions.
    const handleOpenCustomerDialog = (selectedCustomer = null) => {
        customerDispatch({ type: 'SET_CUSTOMER_FORM_DIALOG_STATE', payload: { open: true, dialogType: selectedCustomer ? "edit" : "add", selectedCustomer: selectedCustomer } })
    }
    const handleOpenContractDialog = (selectedCustomer = null, selectedContract = null) => {
        contractDispatch({ type: 'SET_CONTRACT_FORM_DIALOG_STATE', payload: { open: true, dialogType: selectedContract ? "edit" : "add", selectedCustomer: selectedCustomer, selectedContract: selectedContract } })
    }
    const handleOpenUserDialog = (selectedCustomer = null, selectedUser = null) => {
        userDispatch({ type: 'SET_USER_FORM_DIALOG_STATE', payload: { open: true, dialogType: selectedUser ? "edit" : "add", selectedCustomer: selectedCustomer, selectedUser: selectedUser } })
    }
    const handleOpenInstanceDialog = (selectedCustomer = null, selectedContract, selectedInstance = null) => {
        instanceDispatch({ type: 'SET_INSTANCE_FORM_DIALOG_STATE', payload: { open: true, dialogType: selectedInstance ? "edit" : "add", selectedCustomer: selectedCustomer, selectedContract: selectedContract, selectedInstance: selectedInstance } })
    }
    const handleOpenUserPicker = ({ open = false, selectedCustomer = null, selectedUser = null }) => {
        userDispatch({ type: 'SET_USER_PICKER_DIALOG_STATE', payload: { open, selectedCustomer, selectedUser } })
    }

    // User picker dialog functions.
    const handleUserPickerAdd = (selectedUser) => {
        const selectedCustomer = userPickerDialogState.selectedCustomer;
        customersApi.addCustomerContact(selectedCustomer.id, selectedUser.id, keycloak.token, (customers) => {
            loadCustomerContacts(selectedCustomer.id);
        });
        handleOpenUserPicker({});
    }
    const handleUserPickerCreate = () => {
        const selectedCustomer = userPickerDialogState.selectedCustomer;
        handleOpenUserPicker({});
        handleOpenUserDialog(selectedCustomer);
    }

    const handleUserPickerCancel = () => {
        handleOpenUserPicker({});
    }


    const handleCommentsChange = (customerId) => {
        loadCustomerComments(customerId);
    }

    // Delete functions and user remove functions, uses confirmation dialog.
    const handleDeleteCustomer = (customerId) => {
        miscDispatch({
            type: 'SET_CONFIRMATION_DIALOG_STATE', payload: {
                open: true,
                title: "Delete Customer",
                description: "Are you sure you want to delete this customer?",
                confirmButtonText: "Delete",
                onConfirm: () => {
                    customersApi.deleteCustomer(customerId, keycloak.token, ((customers) => {
                        customerDispatch({ type: 'SET_CUSTOMERS', payload: { customers } })
                    }));
                    miscDispatch({ type: 'SET_CONFIRMATION_DIALOG_STATE', payload: { open: false, title: '', description: '', confirmButtonText: '', onConfirm: null } });
                }
            }
        });
    };
    const handleRemoveUser = (customerId, userId) => {
        customersApi.removeCustomerContact(customerId, userId, keycloak.token, ((customers) => {
            loadCustomerContacts(customerId);
        }));
    };
    const handleDeleteContract = (customerID, contractId) => {
        miscDispatch({
            type: 'SET_CONFIRMATION_DIALOG_STATE', payload: {
                open: true,
                title: "Delete Contract And Connected Instances",
                description: "Are you sure you want to delete this contract? This will also delete all instances associated with this contract.",
                confirmButtonText: "Delete",
                onConfirm: () => {
                    contractsApi.deleteContract(contractId, keycloak.token, (() => {
                        loadCustomerContracts(customerID);
                        loadCustomerInstances(customerID);
                    }));
                    miscDispatch({ type: 'SET_CONFIRMATION_DIALOG_STATE', payload: { open: false, title: '', description: '', confirmButtonText: '', onConfirm: null } });
                }
            }
        });
    };
    const handleDeleteInstance = (customerId, instanceId) => {
        miscDispatch({
            type: 'SET_CONFIRMATION_DIALOG_STATE', payload: {
                open: true,
                title: "Delete Instance",
                description: "Are you sure you want to delete this instance?",
                confirmButtonText: "Delete",
                onConfirm: () => {
                    instancesApi.deleteInstance(instanceId, keycloak.token, (() => {
                        loadCustomerInstances(customerId);
                    }));
                    miscDispatch({ type: 'SET_CONFIRMATION_DIALOG_STATE', payload: { open: false, title: '', description: '', confirmButtonText: '', onConfirm: null } });
                }
            }
        });
    };

    // TODO: Implement this function.
    const getLicenseKey = (contractId) => {
        contractsApi.getLicenseKey(contractId, keycloak.token, () => {

        });
    }


    const handleUploadAttachment = (customerId, file) => {
        if (file && customerId) {
            customersApi.uploadFile(customerId, file, keycloak.token,
                (uploadedFile) => {
                    console.log('File uploaded:', uploadedFile)
                    loadFiles(customerId);
                },
                (error) => console.log('Upload error:', error)
            );
          
        } else {
            console.log('Please select a file and provide a customer ID');
        }
    };

    const handleDownloadAttachment = (customerId, fileId) => {
        if (customerId && fileId) {
            customersApi.downloadFile(customerId, fileId, keycloak.token,
                (file) => console.log('File downloaded:', file),
                (error) => console.log('Download error:', error)
            );
        } else {
            console.log('Please provide a customer ID and file ID');
        }
    };

    const handleDeleteAttachment = (customerId, fileId) => {
        if (customerId && fileId) {
            miscDispatch({
                type: 'SET_CONFIRMATION_DIALOG_STATE', payload: {
                    open: true,
                    title: "Delete File",
                    description: "Are you sure you want to delete this file?",
                    confirmButtonText: "Delete",
                    onConfirm: () => {
                        customersApi.deleteFile(customerId, fileId, keycloak.token,
                            (response) => {
                                console.log('File deleted:', response);
                                loadFiles(customerId);
                            },
                            (error) => console.log('Delete error:', error)
                        );
                        miscDispatch({ type: 'SET_CONFIRMATION_DIALOG_STATE', payload: { open: false, title: '', description: '', confirmButtonText: '', onConfirm: null } });
                       
                    }
                }
            });
           
        } else {
            console.log('Please provide a customer ID and file ID');
        }
    };


    return (
        <React.Fragment>
            <CssBaseline />
            <Container>
                <Stack sx={{ height: '100vh' }} >
                    <Box sx={{ display: "flex", gap: 1 }}>
                        <MapsHomeWorkIcon sx={{ fontSize: 40 }} />
                        <Typography variant="h4" gutterBottom>
                            {customerId && typeof filteredCustomers[0] !== 'undefined' ? t("customersView.customer") + " " + filteredCustomers[0].name : t("customersView.title")}
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        {/* <CssVarsProvider> */}
                        <Button onClick={() => handleOpenCustomerDialog()} variant="contained" startIcon={<AddIcon />}>
                            {t("customersView.addCustomer")}
                        </Button>
                        {/* </CssVarsProvider> */}
                    </Box>
                    <Typography variant="body1" gutterBottom>
                        {t("customersView.description")}
                    </Typography>
                    <Box sx={{ marginTop: 2 }}>
                        <SearchInput defaultValue="" placeholder={t("customersView.search")} onSearchChange={handleSearch} />
                    </Box>
                    <Box sx={{ marginTop: 4 }}>
                        <CustomersTable
                            me={me}
                            customers={filteredCustomers}
                            handleRowOpen={handleRowOpen}
                            handleCommentsChange={handleCommentsChange}
                            handleOpenCustomerDialog={handleOpenCustomerDialog}
                            handleOpenContractDialog={handleOpenContractDialog}
                            handleOpenUserDialog={handleOpenUserDialog}
                            handleOpenUserPicker={handleOpenUserPicker}
                            handleOpenInstanceDialog={handleOpenInstanceDialog}
                            handleDeleteCustomer={handleDeleteCustomer}
                            handleDeleteContract={handleDeleteContract}
                            handleDeleteInstance={handleDeleteInstance}
                            handleRemoveUser={handleRemoveUser}
                            handleUploadAttachment={handleUploadAttachment}
                            handleDownloadAttachment={handleDownloadAttachment}
                            handleDeleteAttachment={handleDeleteAttachment}
                            getLicenseKey={getLicenseKey}
                        />
                    </Box>
                </Stack>
            </Container>
            <CustomerFormDialog
                open={customerFormDialogState.open}
                dialogType={customerFormDialogState.dialogType}
                selectedCustomer={customerFormDialogState.selectedCustomer}
                onClose={() => {
                    customerDispatch({ type: 'SET_CUSTOMER_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null } })
                }}
                onSave={() => {
                    const customerId = customerFormDialogState.selectedCustomer ? customerFormDialogState.selectedCustomer.id : null;
                    customerDispatch({ type: 'SET_CUSTOMER_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null } })
                    loadCustomers();
                    loadCustomerContracts(customerId)
                    loadCustomerInstances(customerId)
                    loadCustomerContacts(customerId)
                }}
            />
            <ContractFormDialog
                open={contractFormDialogState.open}
                dialogType={contractFormDialogState.dialogType}
                customers={customers}
                selectedCustomer={contractFormDialogState.selectedCustomer}
                selectedContract={contractFormDialogState.selectedContract}
                onClose={() => {
                    contractDispatch({ type: 'SET_CONTRACT_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null, selectedContract: null } })
                }}
                onSave={() => {
                    loadCustomerContracts(contractFormDialogState.selectedCustomer.id)
                    contractDispatch({ type: 'SET_CONTRACT_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null, selectedContract: null } })
                }}
            />
            <UserPicker
                open={userPickerDialogState.open}
                onAdd={handleUserPickerAdd}
                onCreate={handleUserPickerCreate}
                onCancel={handleUserPickerCancel}
            />
            <UserFormDialog
                open={userFormDialogState.open}
                dialogType={userFormDialogState.dialogType}
                customers={customers}
                selectedCustomer={userFormDialogState.selectedCustomer}
                selectedUser={userFormDialogState.selectedUser}
                error={userFormDialogState.error}
                onClose={() => {
                    userDispatch({ type: 'SET_USER_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null, selectedUser: null, error: "" } })
                }}
                onSave={() => {
                    loadCustomerContacts(userFormDialogState.selectedCustomer.id)
                    userDispatch({ type: 'SET_USER_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null, selectedUser: null, error: "" } })
                }}
                onError={(error) => {
                    userDispatch({ type: 'SET_USER_FORM_DIALOG_STATE', payload: { open: true, dialogType: userFormDialogState.dialogType, selectedCustomer: userFormDialogState.selectedCustomer, selectedUser: userFormDialogState.selectedUser, error: error } })
                }}
            />
            <InstanceFormDialog
                open={instanceFormDialogState.open}
                dialogType={instanceFormDialogState.dialogType}
                customers={customers}
                selectedContract={instanceFormDialogState.selectedContract}
                selectedCustomer={instanceFormDialogState.selectedCustomer}
                selectedInstance={instanceFormDialogState.selectedInstance}
                onClose={() => {
                    instanceDispatch({ type: 'SET_INSTANCE_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null, selectedInstance: null } })
                }}
                onSave={() => {
                    loadCustomerInstances(instanceFormDialogState.selectedCustomer.id)
                    instanceDispatch({ type: 'SET_INSTANCE_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null, selectedInstance: null } })
                }}
            />
        </React.Fragment>
    );
}