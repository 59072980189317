import * as React from 'react';
import {
    Avatar,
    Box,
    ClickAwayListener,
    Collapse,
    Grow,
    IconButton,
    Paper,
    Popper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Chip,
    Divider
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
    MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { NavLink, } from 'react-router-dom';
import { MenuList, MenuItem } from '@mui/material';
import { groupUsers } from './Constants';
import { useTranslation } from 'react-i18next';

function Row(props) {
    const { t } = useTranslation();
    const { row,
        noRows,
        handleRowOpen,
        handleOpenUserDialog,
        handleOpenCustomerDialog,
        handleDeleteUser,
    } = props;
    const anchorRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const [menuOpen, setMenuOpen] = React.useState(false);

    React.useEffect(() => {
        if (open) {
            if (handleRowOpen) handleRowOpen(row)
        }
    }, [open])

    React.useEffect(() => {
        if (noRows === 1) setOpen(true);
        else setOpen(false);
    }, [noRows]);

    const handleToggle = () => {
        setMenuOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setMenuOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setMenuOpen(false);
        } else if (event.key === 'Escape') {
            setMenuOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [menuOpen]);


    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {`ID: ${row.id}`}
                </TableCell>
                <TableCell component="th" scope="row">
                    <Avatar >
                        {`${row.firstName?.charAt(0).toUpperCase()}${row.lastName?.charAt(0).toUpperCase()}`}
                    </Avatar>
                </TableCell>
                <TableCell component="th" scope="row">
                    {`${t("usersTable.customer.customer")}: ${row.customer?.name}`}
                </TableCell>

                <TableCell component="th" scope="row">
                    {`${t("usersTable.name")}: ${row.firstName} ${row.lastName}`}
                </TableCell>
                <TableCell component="th" scope="row">
                    {`${t("usersTable.email")}: ${row.email}`}
                </TableCell>
                <TableCell align="right">{row.town}</TableCell>
                <TableCell align="right">{row.street}</TableCell>
                <TableCell align="right">{row.country}</TableCell>
                <TableCell align="right">
                    {row.customer &&
                        <NavLink
                            to={"/customers/" + row.customer?.id}
                            className={({ isActive, isPending }) =>
                                isPending ? "pending" : isActive ? "active" : ""
                            }
                        >
                            {t("usersTable.goToCustomer")}
                        </NavLink>}
                </TableCell>
                <TableCell align="right">
                    <div>
                        <IconButton color="primary" aria-label="add to shopping cart"
                            ref={anchorRef}
                            id="composition-button"
                            aria-controls={open ? 'composition-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}>
                            <MoreVertIcon />
                        </IconButton>
                        <Popper
                            style={{ zIndex: 1000 }}
                            open={menuOpen}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement="bottom-start"
                            transition
                            disablePortal
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                                    }}
                                >
                                    <Paper >
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList
                                                autoFocusItem={menuOpen}
                                                id="composition-menu"
                                                aria-labelledby="composition-button"
                                                onKeyDown={handleListKeyDown}
                                            >
                                                <MenuItem onClick={(e) => {
                                                    handleClose(e);
                                                    handleOpenUserDialog(row.customer, row);

                                                }}>{t("usersTable.menu.edit")}</MenuItem>
                                                <MenuItem onClick={(e) => {
                                                    handleDeleteUser(row.id);
                                                    handleClose(e);
                                                }}>{t("usersTable.menu.delete")}</MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Box sx={{ display: "flex", alignItems: "end", gap: 1 }}>
                                <Box>
                                    <GroupIcon sx={{ fontSize: 32 }} />
                                </Box>
                                <Typography variant="h6" gutterBottom component="div" sx={{ marginTop: 2 }}>
                                    {t("usersTable.details.title")}
                                </Typography>
                                <Box sx={{ flexGrow: 1 }} />
                                <Box>
                                    <IconButton color="primary" aria-label='Edit customer' onClick={() => {
                                        handleOpenUserDialog(row.customer, row);
                                    }}>
                                        <EditIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>{t("usersTable.details.firstName")}</TableCell>
                                        <TableCell>{t("usersTable.details.lastName")}</TableCell>
                                        <TableCell>{t("usersTable.details.email")}</TableCell>
                                        <TableCell>{t("usersTable.details.phone1")}</TableCell>
                                        <TableCell>{t("usersTable.details.phone2")}</TableCell>
                                        {/* <TableCell>Is Admin?</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={`table-row-${row.id}`}>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.firstName}</TableCell>
                                        <TableCell>{row.lastName}</TableCell>
                                        <TableCell>{row.email}</TableCell>
                                        <TableCell>{row.phoneNumber1}</TableCell>
                                        <TableCell>{row.phoneNumber2}</TableCell>
                                        {/* <TableCell>{row.isAdmin ? "Yes" : "No"}</TableCell> */}
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Box sx={{ display: "flex", alignItems: "end", gap: 1 }}>
                                <Box>
                                    <MapsHomeWorkIcon sx={{ fontSize: 32 }} />
                                </Box>
                                <Typography variant="h6" gutterBottom component="div" sx={{ marginTop: 2 }}>
                                    {t("usersTable.customer.customer")}
                                </Typography>
                                <Box sx={{ flexGrow: 1 }} />
                                <Box>
                                    {row.customer &&
                                        <IconButton color="primary" aria-label='Edit customer' onClick={() => {
                                            handleOpenCustomerDialog(row.customer);
                                        }}>
                                            <EditIcon />
                                        </IconButton>}
                                </Box>
                            </Box>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t("usersTable.customer.name")}</TableCell>
                                        <TableCell>{t("usersTable.customer.department")}</TableCell>
                                        <TableCell>{t("usersTable.customer.street")}</TableCell>
                                        <TableCell>{t("usersTable.customer.city")}</TableCell>
                                        <TableCell>{t("usersTable.customer.zipCode")}</TableCell>
                                        <TableCell>{t("usersTable.customer.country")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={"row-compay-address"}>
                                        <TableCell>{row.customer?.name}</TableCell>
                                        <TableCell>{row.customer?.department}</TableCell>
                                        <TableCell>{row.customer?.street}</TableCell>
                                        <TableCell>{row.customer?.town}</TableCell>
                                        <TableCell>{row.customer?.zipCode}</TableCell>
                                        <TableCell>{row.customer?.country}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

        </React.Fragment >
    );
}

export default function UsersTable(props) {
    const {
        users,
        groupBy,
        handleRowOpen,
        handleOpenUserDialog,
        handleOpenCustomerDialog,
        handleDeleteUser,
    } = props;
    const rows = users;
    const groupedRows = groupUsers(rows, groupBy);
    // const sortedRows = sortContracts(rows, sortBy);
    // const groupedRows = groupContracts(sortedRows, groupBy);

    return (
        groupBy ? Object.keys(groupedRows).map((key) => {
            return (
                <>
                    <Box key={`user-${key}-table-headline`} sx={{ paddingTop: 2, paddingBottom: 2 }}>
                        <Divider textAlign="left" ><Chip label={key} /></Divider>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableBody>
                                {groupedRows[key].map((row) => (
                                    <Row
                                        key={`user-${row.id}-table-row`}
                                        row={row}
                                        noRows={rows.length}
                                        handleRowOpen={handleRowOpen}
                                        handleOpenUserDialog={handleOpenUserDialog}
                                        handleOpenCustomerDialog={handleOpenCustomerDialog}
                                        handleDeleteUser={handleDeleteUser}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )
        }) :
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableBody>
                        {rows.map((row) => (
                            <Row
                                key={`user-${row.id}-table-row`}
                                row={row}
                                noRows={rows.length}
                                handleRowOpen={handleRowOpen}
                                handleOpenUserDialog={handleOpenUserDialog}
                                handleOpenCustomerDialog={handleOpenCustomerDialog}
                                handleDeleteUser={handleDeleteUser}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
    );


}
