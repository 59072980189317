import React from 'react';
import { NavLink } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import MapsHomeWorkIconOutlined from '@mui/icons-material/MapsHomeWorkOutlined';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import HistoryEduIconOutlined from '@mui/icons-material/HistoryEduOutlined';
import StorageIcon from '@mui/icons-material/Storage';
import StorageIconOutlined from '@mui/icons-material/StorageOutlined';
import GroupIcon from '@mui/icons-material/Group';
import GroupIconOutlined from '@mui/icons-material/GroupOutlined';
import KeyIcon from '@mui/icons-material/Key';
import AccountIcon from '@mui/icons-material/AccountCircle';
import AccountIconOutlined from '@mui/icons-material/AccountCircleOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
    useLocation
} from "react-router-dom";
import { useTranslation } from 'react-i18next';


const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


export function NavigationDrawer(props) {
    const { t } = useTranslation();
    const { me, open, handleDrawerClose } = props;
    let location = useLocation();
    const theme = useTheme();

    return (
        <Drawer
            variant="permanent"
            open={open}
        >
            <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
                <ListItem as={NavLink} end to="/" key="drawer-home" disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            {
                                location.pathname === "/" ? <AccountIcon /> : <AccountIconOutlined />
                            }
                        </ListItemIcon>
                        <ListItemText primary={t("translation:appBar.navigationDrawer.home")} />
                    </ListItemButton>
                </ListItem>
                {me?.roles.includes("INTERNAL") && <>
                    <ListItem as={NavLink} end to="/customers" key="drawer-customers" disablePadding>
                        <ListItemButton >
                            <ListItemIcon>
                                {
                                    location.pathname === "/customers" ? <MapsHomeWorkIcon /> : <MapsHomeWorkIconOutlined />
                                }
                            </ListItemIcon>
                            <ListItemText primary={t("translation:appBar.navigationDrawer.customers")} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem as={NavLink} end to="/users" key="drawer-users" disablePadding>
                        <ListItemButton>

                            <ListItemIcon sx={{ color: "primary" }}>
                                {
                                    location.pathname === "/users" ? <GroupIcon /> : <GroupIconOutlined />
                                }

                            </ListItemIcon>
                            <ListItemText primary={t("translation:appBar.navigationDrawer.users")} />
                        </ListItemButton>
                    </ListItem>

                    <ListItem as={NavLink} end to="/contracts" key="drawer-contracts" disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                {
                                    location.pathname === "/contracts" ? <HistoryEduIcon /> : <HistoryEduIconOutlined />
                                }
                            </ListItemIcon>
                            <ListItemText primary={t("translation:appBar.navigationDrawer.contracts")} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem as={NavLink} end to="/instances" key="drawer-instances" disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                {
                                    location.pathname === "/instances" ? <StorageIcon /> : <StorageIconOutlined />
                                }
                            </ListItemIcon>
                            <ListItemText primary={t("translation:appBar.navigationDrawer.instances")} />
                        </ListItemButton>
                    </ListItem>
                </>}
            </List>
            {me?.roles.includes("INTERNAL") && <>
                <Divider />
                <List>
                    <ListItem as={NavLink} end to="/keyGenerator" key="drawer-key-generator" disablePadding>
                        <ListItemButton  >
                            <ListItemIcon>
                                <KeyIcon />
                            </ListItemIcon>
                            <ListItemText primary={t("translation:appBar.navigationDrawer.keyGenerator")} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </>
            }
        </Drawer>
    );
}

export default NavigationDrawer;