import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DialogComponent = ({ title, children, onSave, onCancel, onClose, open}) => {
    const { t } = useTranslation();
    const handleSave = () => {
        if (onSave) onSave();
    };

    const handleCancel = () => {
        if (onCancel) onCancel();
    };
    
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <Box>
                <DialogContent>{children}</DialogContent>
            </Box>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    {t('misc.dialog.cancel')}
                </Button>
                <Button onClick={handleSave} variant="contained" color="primary">
                    {t('misc.dialog.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogComponent;
