import * as React from 'react';
import * as yup from "yup"
import dayjs from 'dayjs';
import { useKeycloak } from '@react-keycloak/web'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"
import { Paper, Typography, Container, Box, Stack, TextField, FormControl, FormControlLabel, Select, InputLabel, MenuItem, Button, Divider, Checkbox } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/en-gb';
import { keyApi } from './keyAPI';
import { download } from '../../utils/Constants';
import { useTranslation } from 'react-i18next';

const DEFAULT_DAYS_VALID = 30;




export default function KeyGenerator() {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak()
  const [licenseKey, setLicenseKey] = React.useState(t("licenseGenerator.createKeyAndCopyFromHere"));
  const [isOldVersion, setIsOldVersion] = React.useState(false);

  const schema = yup.object({
    licenseholder: yup.string().required(t("licenseGenerator.thisFieldIsRequired")),
    product: yup.string().required(t("licenseGenerator.thisFieldIsRequired")),
    version: yup.string().required(t("licenseGenerator.thisFieldIsRequired")),
    ip_1: yup.string().required(t("licenseGenerator.thisFieldIsRequired")),
    noFullUsers: yup.string().when("$isOldVersion", (context) => {
      const isOldVersion = context[0];
      if(isOldVersion === true) return yup.string().required(t("licenseGenerator.thisFieldIsRequired"));
      return yup.string();
    }),
    noTask: yup.string().when("$isOldVersion", (context) => {
      const isOldVersion = context[0];
      if(isOldVersion === false) return yup.string().required(t("licenseGenerator.thisFieldIsRequired"));
      return yup.string();
    }),
    daysValid: yup.number("Must be a number").positive().integer().required(t("licenseGenerator.thisFieldIsRequired")),
  }).required();

  const { control, formState, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      licenseholder: 'Steinbeis GmbH & Co. KG für Technologietransfer',
      product: 'Allegra',
      version: '8.0',
      ip_1: '',
      ip_2: '',
      ip_3: '',
      ip_4: '',
      ip_5: '',
      concurrent: false,
      noFullUsers: '',
      noClients: '',
      noGantt: '',
      noWiki: '',
      noALM: '',
      noTeamgeist: '',
      noTask: '',
      noProject: '',
      noService: '',
      floating: false,
      daysValid: DEFAULT_DAYS_VALID,
      validUntil: dayjs().add(DEFAULT_DAYS_VALID, 'day'),
    },
    context: {
      isOldVersion: isOldVersion,
    },
    resolver: yupResolver(schema),
  });

  const watchVersion = watch("version") // you can also target specific fields by their names

  React.useEffect(() => {
    if (Number(Array.from(watchVersion)[0]) < 8) {
      setIsOldVersion(true);
    } else {
      setIsOldVersion(false);
    }
  }, [watchVersion])


  const onSubmit = data => {
    const transformedData = { ...data }
    transformedData.validUntil = transformedData.validUntil.format("YYYY-MM-DD");
    keyApi.getLicenseKeyOld(transformedData, keycloak.token, ((licenseKey) => {
      try {
        const licenseKeyClearText = licenseKey[0]
        const licenseKeyEncrypted = licenseKey[1];
        const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(licenseKeyClearText + "\n" + licenseKeyEncrypted);
        setLicenseKey(licenseKeyClearText + "\n" + licenseKeyEncrypted);
        let fileName = ""
        if (transformedData.ip_2.lnegth > 0 || transformedData.ip_3.length > 0 || transformedData.ip_4.length > 0 || transformedData.ip_5.length > 0) {
          fileName = "license-" + "multiple-ips" + "-" + dayjs().format("YYYY-MM-DD-HH-mm");
        } else {
          fileName = "license-" + transformedData.ip_1 + "-" + dayjs().format("YYYY-MM-DD-HH-mm");
        }
        download(dataStr, fileName + ".txt")
      } catch (error) {
        console.log(error)
      }
    }));
  }

  return (
    <Container maxWidth="sm">
      <Paper style={{ padding: "16px 32px 32px 32px" }} elevation={3}>
        <Box sx={{ my: 4 }}>
          <Typography style={{ textAlign: "center", marginBottom: "16px" }} variant="h5" gutterBottom>
            {t('licenseGenerator.title')}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Divider textAlign="left">{
                t('licenseGenerator.nameAndVersion')
              }</Divider>
              <Controller
                name="licenseholder"
                control={control}
                render={({ field, fieldState }) => <TextField {...field}
                  error={Boolean(fieldState.error)}
                  helperText={Boolean(fieldState.error) && fieldState.error.message}
                  required
                  label={t('licenseGenerator.licenseHolder')}
                  variant="outlined"
                  size="small" />
                }
              />
              <Controller
                name="product"
                control={control}
                render={({ field }) =>
                  <FormControl fullWidth>
                    <InputLabel id="product-label">{t('licenseGenerator.product')}</InputLabel>
                    <Select
                      {...field}
                      labelId="product-label"
                      label={t('licenseGenerator.product')}
                      size="small"
                    >
                      <MenuItem key={"Allegra"} value={"Allegra"}>Allegra</MenuItem>
                    </Select>
                  </FormControl>}
              />
              <Controller
                name="version"
                control={control}
                render={({ field }) =>
                  <FormControl fullWidth>
                    <InputLabel id="version-label">{t('licenseGenerator.version')}</InputLabel>
                    <Select
                      {...field}
                      labelId="version-label"
                      label={t('licenseGenerator.version')}
                      size="small"
                    >
                      <MenuItem key={"8.0"} value={"8.0"}>8.0</MenuItem>
                      <MenuItem key={"7.0"} value={"7.0"}>7.0</MenuItem>
                      <MenuItem key={"6.0"} value={"6.0"}>6.0</MenuItem>
                      <MenuItem key={"5.6"} value={"5.6"}>5.6</MenuItem>
                      <MenuItem key={"5.5"} value={"5.5"}>5.5</MenuItem>
                      <MenuItem key={"5.3"} value={"5.3"}>5.3</MenuItem>
                      <MenuItem key={"5.1"} value={"5.1"}>5.1</MenuItem>
                      <MenuItem key={"5.0"} value={"5.0"}>5.0</MenuItem>
                      <MenuItem key={"4.1"} value={"4.1"}>4.1</MenuItem>
                      <MenuItem key={"4.0"} value={"4.0"}>4.0</MenuItem>
                      <MenuItem key={"3.8"} value={"3.8"}>3.8</MenuItem>
                      <MenuItem key={"3.7"} value={"3.7"}>3.7</MenuItem>
                      <MenuItem key={"3.6"} value={"3.6"}>3.6</MenuItem>
                      <MenuItem key={"3.5"} value={"3.5"}>3.5</MenuItem>
                      <MenuItem key={"3.4"} value={"3.4"}>3.4</MenuItem>
                      <MenuItem key={"3.3"} value={"3.3"}>3.3</MenuItem>
                      <MenuItem key={"3.2"} value={"3.2"}>3.2</MenuItem>
                      <MenuItem key={"3.1"} value={"3.1"}>3.1</MenuItem>
                      <MenuItem key={"3.0"} value={"3.0"}>3.0</MenuItem>
                      <MenuItem key={"2.2"} value={"2.2"}>2.2</MenuItem>
                    </Select>
                  </FormControl>}
              />
              <Divider textAlign="left">{t('licenseGenerator.ipAddresses')}</Divider>
              <Controller
                name="ip_1"
                control={control}
                render={({ field, fieldState }) => <TextField {...field} error={Boolean(fieldState.error)} label={t('licenseGenerator.1stIpAddress')} variant="outlined" size="small" required helperText={Boolean(fieldState.error) && fieldState.error.message} />
                } />
              {formState.dirtyFields.ip_1 &&
                <Controller
                  name="ip_2"
                  control={control}
                  render={({ field }) => <TextField {...field} label={t('licenseGenerator.2ndIpAddress')} variant="outlined" size="small" />
                  } />
              }
              {formState.dirtyFields.ip_2 &&
                <Controller
                  name="ip_3"
                  control={control}
                  render={({ field }) => <TextField {...field} label={t('licenseGenerator.3rdIpAddress')} variant="outlined" size="small" />
                  } />
              }
              {formState.dirtyFields.ip_3 &&
                <Controller
                  name="ip_4"
                  control={control}
                  render={({ field }) => <TextField {...field} label={t('licenseGenerator.4thIpAddress')} variant="outlined" size="small" />
                  } />}
              {formState.dirtyFields.ip_4 &&
                <Controller
                  name="ip_5"
                  control={control}
                  render={({ field }) => <TextField {...field} label={t('licenseGenerator.5thIpAddress')} variant="outlined" size="small" />
                  } />}
              {Number(Array.from(watchVersion)[0]) < 8 &&
                <Controller
                  name="concurrent"
                  control={control}
                  render={({ field }) => <FormControlLabel control={<Checkbox {...field} />} label={t('licenseGenerator.concurrent')} />
                  } />
              }
              <Divider textAlign="left">{t('licenseGenerator.licenses')}</Divider>

              {isOldVersion &&
                <>
                  <Controller
                    name="noFullUsers"
                    control={control}
                    render={({ field, fieldState }) => <TextField {...field} error={Boolean(fieldState.error)} label={t('licenseGenerator.noFullUsers')} variant="outlined" size="small" required helperText={Boolean(fieldState.error) && fieldState.error.message} />
                    } />
                 
                  <Controller
                    name="noGantt"
                    control={control}
                    render={({ field }) => <TextField {...field} label={t('licenseGenerator.noGantt')} variant="outlined" size="small" />
                    } />
                  <Controller
                    name="noWiki"
                    control={control}
                    render={({ field }) => <TextField {...field} label={t('licenseGenerator.noWiki')} variant="outlined" size="small" />
                    } />
                  <Controller
                    name="noALM"
                    control={control}
                    render={({ field }) => <TextField {...field} label={t('licenseGenerator.noAlm')} variant="outlined" size="small" />
                    } />
                  <Controller
                    name="noTeamgeist"
                    control={control}
                    render={({ field }) => <TextField {...field} label={t('licenseGenerator.noTeamgeist')} variant="outlined" size="small" />
                    } />
                </>}

              {!isOldVersion &&
                <>
                  <Controller
                    name="noTask"
                    control={control}
                    render={({ field, fieldState }) => <TextField {...field} error={Boolean(fieldState.error)} helperText={Boolean(fieldState.error) && fieldState.error.message} label={t('licenseGenerator.noAllegraTask')} variant="outlined" size="small" />
                    } />
                  <Controller
                    name="noProject"
                    control={control}
                    render={({ field, fieldState }) => <TextField {...field} error={Boolean(fieldState.error)} helperText={Boolean(fieldState.error) && fieldState.error.message} label={t('licenseGenerator.noAllegraProject')} variant="outlined" size="small" />
                    } />

                  <Controller
                    name="noService"
                    control={control}
                    render={({ field, fieldState }) => <TextField {...field} error={Boolean(fieldState.error)} helperText={Boolean(fieldState.error) && fieldState.error.message} label={t('licenseGenerator.noAllegraService')}variant="outlined" size="small" />
                    } />
                </>}

                <Controller
                    name="noClients"
                    control={control}
                    render={({ field }) => <TextField {...field} label={t('licenseGenerator.noClients')} variant="outlined" size="small" />
                    } />

              {isOldVersion &&
                <Controller
                  name="floating"
                  control={control}
                  render={({ field }) => <FormControlLabel control={<Checkbox {...field} />} label={t('licenseGenerator.floating')} />
                  } />
              }
              <Divider textAlign="left">{t('licenseGenerator.validity')}</Divider>
              <Controller
                name="daysValid"
                control={control}
                render={({ field, fieldState }) => <TextField {...field} error={Boolean(fieldState.error)} label={t('licenseGenerator.validForXDays')} variant="outlined" size="small" required helperText={Boolean(fieldState.error) && fieldState.error.message}
                  onChange={(e) => {
                    setValue("daysValid", e.target.value);
                    setValue("validUntil", dayjs().add(e.target.value, 'day'));
                  }}
                />
                } />
              <Controller
                name="validUntil"
                control={control}
                render={({ field }) => <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
                  <DatePicker {...field} format="DD.MM.YYYY" disablePast label={t('licenseGenerator.validUntil')}
                    onChange={(e) => {
                      setValue("validUntil", e);
                      setValue("daysValid", dayjs(e).diff(dayjs(), 'day'));
                    }}
                  />
                </LocalizationProvider>
                } />

              <Button variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>{t('licenseGenerator.createKey')}</Button>
              <TextField
                id="license-key"
                multiline
                rows={4}
                value={licenseKey}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Stack>
          </form>
        </Box>
      </Paper>
    </Container>
  );
}