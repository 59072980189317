import { green, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#322560',
    },
    secondary: {
      main: '#290E0E',
    },

    success: {
      main: green.A200,
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;