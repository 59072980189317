import React from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import { Dimmer, Header, Icon } from 'semantic-ui-react';
import { config } from './Constants';
import { usersApi } from './components/users/UsersAPI';
import { useUsers } from './components/users/UsersContext';
import AppBar from './components/appbar/appbar';
import Key from './components/keyGenerator/keyGenerator';
import PrivateRoute from './components/misc/PrivateRoute';
import DashboardPage from './components/dashboard/DashboardPage';
import CustomersPageMaterial from './components/customers/CustomersPage';
import ContractsPage from './components/contracts/ContractsPage';
import InstancesPage from './components/instances/InstancesPage';
import UsersPage from './components/users/UsersPage';
import UserSettings from './components/settings/UserSettings';
import ConfirmationDialog from './components/misc/ConfirmationDialog';
import CustomSnackbar from './components/misc/Snackbar';
import { CustomersProvider } from './components/customers/CustomersContext';
import { ContractsProvider } from './components/contracts/ContractsContext';
import { InstancesProvider } from './components/instances/InstancesContext';
import { UsersProvider } from './components/users/UsersContext';
import { MiscProvider } from './components/misc/MiscContext';
import { useKeycloak } from '@react-keycloak/web';



// Inside your App.js or a new component that wraps Routes
function UserInitializer() {
  const { keycloak } = useKeycloak()
  const { dispatch } = useUsers();

  React.useEffect(() => {
    if(keycloak.authenticated) {
     
      usersApi.getUserMe(keycloak.token, (user) => {
        if(typeof user === 'undefined'){
          usersApi.createUserByLogin({
            id: keycloak.idTokenParsed.sub,
            keycloakId: keycloak.idTokenParsed.sub,
            username: keycloak.idTokenParsed.preferred_username,
            email: keycloak.idTokenParsed.email,
            isAdmin: false
          }, keycloak.token, (response) => {
            
            dispatch({ type: 'SET_ME', payload: { me: response.data } });
          })
        } else {
          const roles = keycloak.tokenParsed.resource_access[config.url.KEYCLOAK_CLIENT_ID].roles
          const myUser ={...user, roles: roles}
          dispatch({ type: 'SET_ME', payload: { me: myUser } });
        }
      });

    }
  }, []);
  return null; // This component doesn't render anything
}


function App() {
  const [drawerOpen, setDrawerOpen] = React.useState(true);

  const keycloak = new Keycloak({
    url: `${config.url.KEYCLOAK_BASE_URL}`,
    realm: `${config.url.KEYCLOAK_REALM}`,
    clientId: `${config.url.KEYCLOAK_CLIENT_ID}`,
  })
  const initOptions = { pkceMethod: 'S256' }

  const handleOnEvent = async (event, error) => {
    if (event === 'onAuthSuccess') {
      if (keycloak.authenticated) {
        try {
        } catch (error) {
          console.log("Keycloak request error", error)
        }
      }
    }
  }

  const loadingComponent = (
    <Dimmer inverted active={true} page>
      <Header style={{ color: '#4d4d4d' }} as='h2' icon inverted>
        <Icon loading name='cog' />
        <Header.Content>Keycloak is loading
          <Header.Subheader style={{ color: '#4d4d4d' }}>or running authorization code flow with PKCE</Header.Subheader>
        </Header.Content>
      </Header>
    </Dimmer>
  )

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={initOptions}
      LoadingComponent={loadingComponent}
      onEvent={(event, error) => handleOnEvent(event, error)}
    >
      <CustomersProvider>
        <ContractsProvider>
          <InstancesProvider>
            <UsersProvider>
              <UserInitializer />
              <MiscProvider>
                <Router basename={config.url.BASE_URL}>
                  <AppBar setOpen={setDrawerOpen} open={drawerOpen} />
                  <Routes>
                    <Route path='/' element={<PrivateRoute><DashboardPage /></PrivateRoute>} />
                    <Route path='/customers/:customerId?' element={<PrivateRoute><CustomersPageMaterial /></PrivateRoute>} />
                    <Route path="/contracts/:contractId?" element={<PrivateRoute><ContractsPage /></PrivateRoute>} />
                    <Route path="/instances/:instanceId?" element={<PrivateRoute><InstancesPage /></PrivateRoute>} />
                    <Route path="/users/:userId?" element={<PrivateRoute><UsersPage /></PrivateRoute>} />
                    <Route path="/settings" element={<PrivateRoute><UserSettings /></PrivateRoute>} />
                    <Route path='/keyGenerator' element={<Key />} />
                    <Route path="*" element={<Navigate to="/" />} />
                  </Routes>
                </Router>
                <ConfirmationDialog />
                <CustomSnackbar />
              </MiscProvider>
            </UsersProvider>
          </InstancesProvider>
        </ContractsProvider>
      </CustomersProvider>
    </ReactKeycloakProvider>
  )
}

export default App;
