import React, { useState, useRef, useEffect, useMemo } from 'react';
import Dialog from '../misc/Dialog';
import InstanceForm from './InstanceForm';
import { instancesApi } from '../instances/InstancesAPI'
import { useKeycloak } from '@react-keycloak/web'
import { useMisc } from '../misc/MiscContext'
import { InstanceMappers } from './InstanceMappers'
import { useTranslation } from 'react-i18next';


export default function InstanceFormDialog({ open, dialogType, customers, selectedCustomer, selectedContract, selectedInstance, onSave, onClose }) {
    const { t } = useTranslation();
    const formRef = useRef();
    const { keycloak } = useKeycloak()
    const { state: miscState, dispatch: miscDispatch } = useMisc();

    const handleSaveDialogAdd = (data) => {
        const createInstanceData = InstanceMappers.toInstanceCreateRequestFromFormData(data);
        instancesApi.createInstance(createInstanceData, keycloak.token, ((instance) => {
            miscDispatch({ type: 'SET_SNACKBAR_STATE', payload: { open: true, message: 'Instance added successfully', type: 'success' } });
            if (onSave) onSave();
        }), (error) => {
            miscDispatch({ type: 'SET_SNACKBAR_STATE', payload: { open: true, message: 'Adding instance failed', type: 'error' } });
        });
    };

    const handleSaveDialogEdit = (data) => {
        const updateInstanceRequestBody = InstanceMappers.toInstanceCreateRequestFromFormData(data)
        instancesApi.updateInstance(selectedInstance.id, updateInstanceRequestBody, keycloak.token, ((instance) => {
            miscDispatch({ type: 'SET_SNACKBAR_STATE', payload: { open: true, message: 'Instance updated successfully', type: 'success' } });
            if (onSave) onSave();
        }), (error) => {
            miscDispatch({ type: 'SET_SNACKBAR_STATE', payload: { open: true, message: 'Updating instance failed', type: 'error' } });
        });
    };

    const handleCloseDialog = () => {
        if (onClose) onClose();
    };

    const handleCancelDialog = () => {
        handleCloseDialog();
    };

    const formData = useMemo(() => {
        if (dialogType === "add") return InstanceMappers.toInstanceFormDataDto({ customer: selectedCustomer })
        return dialogType === "edit" && InstanceMappers.toInstanceFormDataDto({customer: selectedCustomer, contract: selectedContract, instance: selectedInstance})
    }, [selectedCustomer, selectedContract, selectedInstance]);

    return (
        <Dialog
            title={dialogType === "add" ? t("instanceFormDialog.addInstance") : t("instanceFormDialog.editInstance")}
            open={open}
            onClose={handleCloseDialog}
            onSave={() => formRef.current.submitForm()}
            onCancel={handleCancelDialog}>
            <InstanceForm
                customers={customers}
                reference={formRef}
                onSubmit={dialogType === "add" ? handleSaveDialogAdd : handleSaveDialogEdit}
                formData={formData}
            />
        </Dialog>
    );
}