import React, { useMemo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline';
import { Stack, Box, Container, Typography, Button } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import AddIcon from '@mui/icons-material/Add';
import UserFormDialog from '../user/UserFormDialog';
import CustomerFormDialog from '../customer/CustomerFormDialog';
import { usersApi } from './UsersAPI'
import { customersApi } from '../customers/CustomersAPI'
import { contractsApi } from '../contracts/ContractsAPI';
import { useKeycloak } from '@react-keycloak/web'
import { useCustomers } from '../customers/CustomersContext'
import { useContracts } from '../contracts/ContractsContext'
import { useInstances } from '../instances/InstancesContext'
import { useUsers } from '../users/UsersContext'
import { useMisc } from '../misc/MiscContext'
import BasicSelect from '../misc/BasicSelect';
import MenuButton from '../misc/MenuButton';
import { download } from '../../utils/Constants';
import UsersTable from './UsersTable';
import SearchInput from '../misc/SearchInput';
import { searchEntries, getEntryById, jsonToCSV } from '../../utils/Constants';
import { useTranslation } from 'react-i18next';

export default function UsersPage() {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak() // Keycloak instance for auth.
    const { userId } = useParams(); // Retrieve the customer ID from the URL if available.


    // State and dispatch functions from context for managing global state.
    const { state: customerState, dispatch: customerDispatch } = useCustomers();
    const { state: contractState, dispatch: contractDispatch } = useContracts();
    const { state: instanceState, dispatch: instanceDispatch } = useInstances();
    const { state: userState, dispatch: userDispatch } = useUsers();
    const { state: miscState, dispatch: miscDispatch } = useMisc();

    // Local component state for managing UI elements search text.
    const [searchText, setSearchText] = useState('');
    const [groupBy, setGroupBy] = useState('customer');
    const [sortBy, setSortBy] = useState('status');

    // Destructuring state for easier access to specific parts of the state.
    const { customers, customerFormDialogState } = customerState;
    const { users, userFormDialogState } = userState;
    const { contracts } = contractState;

    const loadUsers = () => {
        usersApi.getAllUsers(keycloak.token, (users) => {
            userDispatch({ type: 'SET_USERS', payload: { users } })
        });
    }

    const loadContracts = () => {
        contractsApi.getContracts(keycloak.token, (contracts) => {
            contractDispatch({ type: 'SET_CONTRACTS', payload: { contracts } })
        });
    }

    const loadCustomers = () => {
        customersApi.getCustomers(keycloak.token, (customers) => {
            customerDispatch({ type: 'SET_CUSTOMERS', payload: { customers } })
        });
    }

    // Load customers on component mount.
    useEffect(() => {
        loadUsers();
        loadContracts();
        loadCustomers();
    }, []);

    // Denormalise for searching etc. 
    const usersWithCustomersAndContracts = useMemo(() => {
        return users.map(user => {
            const connectedCustomer = customers.filter(customer => customer.id === user.customerId)[0];
            const connectedContracts = contracts.filter(contract => contract.customer.id === user.id);
            return { ...user, customer: connectedCustomer, contracts: connectedContracts };
        });
    }, [users, customers, contracts]);


    //Search and filter functionality. It only searches the contract entity. Because the other entities are loaded when a contract is opened, they are not searched here.
    const filteredUsers = useMemo(() => {
        if (userId) return getEntryById(usersWithCustomersAndContracts, userId);
        if (searchText.length > 0) return searchEntries(usersWithCustomersAndContracts, searchText);
        return users;
    }, [users, customers, contracts, userId, searchText]);

    const handleSearch = (searchTerm) => {
        setSearchText(searchTerm);
    };

    // Dialog open and close functions.
    const handleOpenCustomerDialog = (selectedCustomer = null) => {
        customerDispatch({ type: 'SET_CUSTOMER_FORM_DIALOG_STATE', payload: { open: true, dialogType: selectedCustomer ? "edit" : "add", selectedCustomer: selectedCustomer } })
    }

    const handleOpenUserDialog = (selectedCustomer = null, selectedUser = null) => {
        userDispatch({ type: 'SET_USER_FORM_DIALOG_STATE', payload: { open: true, dialogType: selectedUser ? "edit" : "add", selectedCustomer: selectedCustomer, selectedUser: selectedUser, error: "" } })
    }

    const handleDeleteUser = (userId) => {
        miscDispatch({
            type: 'SET_CONFIRMATION_DIALOG_STATE', payload: {
                open: true,
                title: "Delete User",
                description: "Are you sure you want to delete this user?",
                confirmButtonText: "Delete",
                onConfirm: () => {
                    usersApi.deleteUser(userId, keycloak.token, (() => {
                        loadUsers();
                    }));
                    miscDispatch({ type: 'SET_CONFIRMATION_DIALOG_STATE', payload: { open: false, title: '', description: '', confirmButtonText: '', onConfirm: null } });
                }
            }
        });
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <Container>
                <Stack sx={{ height: '100vh' }} >
                    <Box sx={{ display: "flex", gap: 1 }}>
                        <GroupIcon sx={{ fontSize: 40 }} />
                        <Typography variant="h4" gutterBottom>
                            {userId && typeof filteredUsers[0] !== 'undefined' ? t("usersView.user") + " " + filteredUsers[0].firstName : t("usersView.users")}
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Button onClick={() => handleOpenUserDialog()} variant="contained" startIcon={<AddIcon />}>
                            {t("usersView.addUser")}
                        </Button>
                    </Box>
                    <Typography variant="body1" gutterBottom>
                        {t("usersView.manageUsersHere")}
                    </Typography>
                    <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                        <SearchInput defaultValue="" placeholder= {t("usersView.typeToSearch")} onSearchChange={handleSearch} />
                    </Box>
                    <Box sx={{ marginTop: 1, display: "flex", gap: 2 }}>
                        {/* <BasicSelect
                            label="Sort by"
                            value={sortBy}
                            options={[
                                { value: '', label: 'No sorting' },
                                { value: 'customer', label: 'Status' },
                                { value: 'endDate', label: 'End Date' },
                                { value: 'type', label: 'Type' },
                            ]}
                            onChange={(e) => setSortBy(e.target.value)}
                        /> */}
                        <BasicSelect
                            label= {t("usersView.groupBy")}
                            value={groupBy}
                            options={[
                                { value: '', label: t("usersView.noGrouping") },
                                { value: 'customer', label: t("usersView.customer") },

                            ]}
                            onChange={(e) => setGroupBy(e.target.value)}
                        />
                        <Box sx={{ flexGrow: 1 }} />

                        <MenuButton
                            label={t("usersView.export")}
                            options={[
                                { value: '1', label:  t("usersView.all") },
                            ]}
                            onChange={(value) => {
                                let filteredUsers = [];
                                let fileName = "users";
                                if (value == '1') {
                                    filteredUsers = [...usersWithCustomersAndContracts];
                                }
                                const csv = jsonToCSV(filteredUsers);
                                const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(csv);
                                const date = new Date();
                                download(dataStr, fileName + "-" + date.getFullYear() + '-' + date.getMonth() + '-' + date.getDay() + '-' + date.getHours() + ':' + date.getMinutes() + '.csv');
                            }}
                        />
                    </Box>
                    <Box sx={{ marginTop: 2 }}>
                        <UsersTable
                            users={filteredUsers}
                            groupBy={groupBy}
                            // sortBy={sortBy}
                            handleOpenUserDialog={handleOpenUserDialog}
                            handleOpenCustomerDialog={handleOpenCustomerDialog}
                            handleDeleteUser={handleDeleteUser}
                        />
                    </Box>
                </Stack>
            </Container>

            <UserFormDialog
                open={userFormDialogState.open}
                dialogType={userFormDialogState.dialogType}
                customers={customers}
                selectedCustomer={userFormDialogState.selectedCustomer}
                selectedUser={userFormDialogState.selectedUser}
                error={userFormDialogState.error}
                onClose={() => {
                    userDispatch({ type: 'SET_USER_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null, selectedUser: null, error: "" } })
                }}
                onSave={() => {
                    loadUsers();
                    userDispatch({ type: 'SET_USER_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null, selectedUser: null, error: "" } })
                }}
                onError={(error) => {
                    userDispatch({ type: 'SET_USER_FORM_DIALOG_STATE', payload: { open: true, dialogType: userFormDialogState.dialogType, selectedCustomer: userFormDialogState.selectedCustomer, selectedUser: userFormDialogState.selectedUser, error: error } })
                }}
            />

            <CustomerFormDialog
                open={customerFormDialogState.open}
                dialogType={customerFormDialogState.dialogType}
                selectedCustomer={customerFormDialogState.selectedCustomer}
                onClose={() => {
                    customerDispatch({ type: 'SET_CUSTOMER_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null } })
                }}
                onSave={() => {
                    loadUsers();
                    customerDispatch({ type: 'SET_CUSTOMER_FORM_DIALOG_STATE', payload: { open: false, dialogType: '', selectedCustomer: null } })
                }}
            />
        </React.Fragment>
    );
}