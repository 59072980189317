import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import SearchMegaMenu from '../searchMegaMenu/searchMegaMenu';
import Popper from '@mui/material/Popper';
import Box from '@mui/material/Box';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useKeycloak } from '@react-keycloak/web';
import { searchApi } from './searchApi';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';




const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    minWidth: 500,
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

export default function SearchAppBar() {
    const navigate = useNavigate();
    const { keycloak } = useKeycloak() // Keycloak instance for auth.
    const inputRef = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [searchResults, setSearchResults] = React.useState([]);
    const { t } = useTranslation();

    const onChange = (e) => {
        const { value } = e.target;
        setSearchTerm(value);
        if (value.length > 0) {
            if (value.length === 0) {
                setAnchorEl(null);
            } else {
                setAnchorEl(inputRef.current);
            }
            searchApi.searchBy(value, keycloak.token, (results) => {
                setSearchResults(results);
            });
        }
    }

    const handleClickOnLink = (destination) => {
        setAnchorEl(null);
        setSearchTerm('');
        setSearchResults([]);
        navigate(destination);
    }

    const open = Boolean(anchorEl);

    const id = open ? 'simple-popper' : undefined;

    return (
        <ClickAwayListener onClickAway={() => {
            setAnchorEl(null);
            setSearchTerm('');
        }}>
            <Box>
                <Search aria-describedby={id} ref={inputRef}>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        onFocus={() => { }}
                        value={searchTerm}
                        onChange={onChange}
                        placeholder={t('appBar.search')}
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </Search>
                <Popper id={id} open={open} anchorEl={anchorEl} placement='bottom'>
                    <SearchMegaMenu searchResults={searchResults} handleClickOnLink={handleClickOnLink} />
                </Popper>
            </Box>
        </ClickAwayListener>
    );
}