import dayjs from 'dayjs';

const DUE_SOON_AND_EXPIRED_RECENTLY_THRESHOLD = 30; // days

export function getContractStatus(endDate) {
    if (!endDate) return "U.A."
    const TODAY = dayjs();
    const END = dayjs(endDate);
    let status = TODAY.isBefore(END) ? "Active" : "Expired";
    
    // Due Soon TODAY < END AND TODAY > END - 30 
    if(TODAY.isBefore(END) && TODAY.isAfter(END.subtract(DUE_SOON_AND_EXPIRED_RECENTLY_THRESHOLD, 'days'))) status = "Due Soon";

    // Recently Expired TODAY > END AND TODAY < END + 30
    if(TODAY.isAfter(END) && TODAY.isBefore(END.add(DUE_SOON_AND_EXPIRED_RECENTLY_THRESHOLD, 'days'))) status = "Expired Recently";

    return status;
}


export function sortContracts(contracts, sortBy) {
    // Handle the 'No sorting' case
    if (!sortBy || sortBy === '') {
        return contracts; // return the original array without sorting
    }

    // Clone the contracts to avoid mutating the original array
    let sortedContracts = [...contracts];

    sortedContracts.sort((a, b) => {
        if (sortBy === 'status') {
            // Assuming getStatusByEndDate is defined and returns 'Expired' or 'Active'
            let statusA = getContractStatus(a.endDate);
            let statusB = getContractStatus(b.endDate);
            return statusA.localeCompare(statusB);
        } else if (sortBy === 'endDate') {
            let dateA = new Date(a.endDate), dateB = new Date(b.endDate);
            return dateA - dateB; // sort by date ascending
        } else if (sortBy === 'type') {
            return a.type.localeCompare(b.type); // alphabetical order
        }
        // Default case: should not be reached if sortBy is validated
        return 0;
    });

    return sortedContracts;
}

export function groupContracts(contracts, groupBy) {
    // Handle the 'No grouping' case
    if (!groupBy || groupBy === '') {
        return contracts;
    }
    return contracts.reduce((acc, contract) => {
        let key;
        // Determine the key based on the grouping option
        switch (groupBy) {
            case 'customer':
                key = contract.customer.name;
                break;
            case 'status':
                key = getContractStatus(contract.endDate);
                break;
            case 'type':
                key = contract.type;
                break;
            default:
                throw new Error('Unsupported grouping option');
        }

        // Initialize the group if it doesn't already exist
        if (!acc[key]) {
            acc[key] = [];
        }
        // Add the contract to the appropriate group
        acc[key].push(contract);
        return acc;
    }, {});
}
