
export const UserMappers = {
    toUserFormDataDto,
    toUpdateUserRequestFromFormData,
    toNewUserRequestFromFormData
}


function toUserFormDataDto({ user, customer }) {
    if (user) {
        return {
            customer: user.customer || "",
            firstName: user.firstName || "",
            lastName: user.lastName || "",
            email: user.email || "",
            phoneNumber1: user.phoneNumber1 || "",
            phoneNumber2: user.phoneNumber2 || "",
            isAdmin: user.isAdmin || false,
        }
    }
    else if (customer) {
        return {
            customer: customer || "",
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber1: "",
            phoneNumber2: "",
            isAdmin: false,
        }
    } else {
        return {
            customer: "",
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber1: "",
            phoneNumber2: "",
            isAdmin: false,
        }
    }
}

function toUpdateUserRequestFromFormData(formData) {
    return {
        customer: formData.customer || null,
        username: formData.username,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email.toLowerCase(),
        phoneNumber1: formData.phoneNumber1,
        phoneNumber2: formData.phoneNumber2,
        isAdmin: formData.isAdmin || false,
    }
}

function toNewUserRequestFromFormData(formData) {
    return {
        customer: formData.customer || null,
        username: formData.email.toLowerCase(),
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email.toLowerCase(),
        phoneNumber1: formData.phoneNumber1,
        phoneNumber2: formData.phoneNumber2,
        isAdmin: formData.isAdmin || false,
    }
}

