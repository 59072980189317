import axios from 'axios'
import { config } from '../../Constants'

export const customersApi = {
  getCustomers,
  getCustomer,
  saveCustomer,
  deleteCustomer,
  addCustomerContract,
  getUserMe,
  saveUserMe
}

function getCustomers() {
  return instance.get('/api/customers')
}

function getCustomer(id) {
  return instance.get(`/api/customers/${id}`)
}

function saveCustomer(customer, token) {
  return instance.post('/api/customers', customer, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function deleteCustomer(id, token) {
  return instance.delete(`/api/customers/${id}`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function addCustomerContract(id, contract, token) {
  return instance.post(`/api/customers/${id}/contracts`, contract, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getUserMe(token) {
  return instance.get(`/api/users/me`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function saveUserMe(token, userExtra) {
  return instance.post(`/api/users/me`, userExtra, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

// -- Axios

const instance = axios.create({
  baseURL: config.url.API_BASE_URL
})

instance.interceptors.response.use(response => {
  return response
}, function (error) {
  if (error.response.status === 404) {
    return { status: error.response.status }
  }
  return Promise.reject(error.response)
})

// -- Helper functions

function bearerAuth(token) {
  return `Bearer ${token}`
}