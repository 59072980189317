import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import Comment from '../comment/Comment';
import { TextField, Button, Box } from '@mui/material';
import { data } from './testData';
import { commentsApi } from './CommentsAPI';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';

function Comments({ comments, onSave, onUpdate, onDelete, selectedCustomerId, currentUserId }) {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak() // Keycloak instance for auth.
  const [newCommentText, setNewCommentText] = useState('');


  const handleAddComment = () => {
    if (newCommentText.trim()) {
      commentsApi.createComment({ text: newCommentText, customerId: selectedCustomerId, userId: currentUserId }, keycloak.token, ((comment) => {
        onSave(comment);
      }));
      setNewCommentText(''); // Reset the input after adding
    }
  };

  const handleUpdate = (commentId, newText) => {
    commentsApi.updateComment({ id: commentId, text: newText }, keycloak.token, ((comment) => {
      onUpdate(comment);
    }
    ));
  };

  const handlDeleteComment = (id) => {
    commentsApi.deleteComment(id, keycloak.token, (() => {
      onDelete();
    }
    ));
  }
  return (
    <Box sx={{ width: "100%" }}>
      {comments.map((comment, index) => (
        <Comment
          key={`comment-${index}`}
          createdBy={comment.user}
          currentUserId={currentUserId}
          text={comment.text}
          avatarUrl={comment.avatarUrl}
          creationDate={comment.createdAt}
          onSave={(newText) => handleUpdate(comment.id, newText)}
          onDelete={() => handlDeleteComment(comment.id)}

        />
      ))}
      <Box mt={2}>
        <TextField
          multiline
          label={t('comments.typeComment')}
          variant="outlined"
          fullWidth
          value={newCommentText}
          onChange={(e) => setNewCommentText(e.target.value)}
          sx={{ mb: 1 }} // Add some margin bottom for spacing
        />
        <Box sx={{display: "flex", gap: 1}}>
          <Button
            onClick={handleAddComment}
            variant="contained"
            color="primary"
            disabled={!newCommentText.trim()} // Button is disabled if newCommentText is empty or only contains whitespace
          >
            {t('comments.addComment')}
          </Button>
          {
            newCommentText ? (
              <Button
                onClick={() => setNewCommentText('')}
                color="primary"
              >
                 {t('comments.cancel')}
              </Button>
            ) : null
          }
        </Box>
      </Box>
    </Box>
  );
}

export default Comments;

Comments.propTypes = {
  comments: PropTypes.array,
  onEdit: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  selectedCustomerId: PropTypes.number.isRequired,
  currentUserId: PropTypes.number.isRequired,
};

Comments.defaultProps = {
  comments: [],
  onEdit: () => { },
  onUpdate: () => { },
  onDelete: () => { },
  selectedCustomerId: -1,
  currentUserId: -1,
};
