export function download(dataStr, exportName) {
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }
  export function searchEntries(entries, searchString) {
    if (!searchString) {
        return entries;
    }
    const trimmedSearchString = searchString.trim();
    const searchTerms = trimmedSearchString.toLowerCase().split(/\s+/);
    return entries.filter(entry => {
        // Function to check if any search term is included in a given string
        const includesAnyTerm = (str) => searchTerms.some(term => str?.toLowerCase().includes(term));
        // Check if any of the fields match any search term
        for (const key in entry) {
            if(entry[key] == null) continue;
            if(typeof entry[key] === 'object') {
                if (includesAnyTerm(JSON.stringify(entry[key]))) {
                    return true;
                }
            }
            if (includesAnyTerm(entry[key]?.toString())) {
                return true;
            }
        }
        return false;
    });
}

export function getEntryById(entries, entryId) {
  const entry = entries.find(entries => entries.id == entryId);
  if(entry == null) return entries;
  else return [entry];
}

export function jsonToCSV(jsonData) {
  // Helper function to flatten nested objects
  function flattenObject(obj, parentKey = '', result = {}) {
      for (let [key, value] of Object.entries(obj)) {
          let fullKey = parentKey ? `${parentKey}_${key}` : key;
          if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
              flattenObject(value, fullKey, result);
          } else {
              result[fullKey] = value;
          }
      }
      return result;
  }

  // First, flatten each object in the jsonData
  const flattenedData = jsonData.map(item => flattenObject(item));

  // Extract unique headers dynamically
  const headers = Array.from(flattenedData.reduce((acc, obj) => {
      Object.keys(obj).forEach(key => acc.add(key));
      return acc;
  }, new Set()));

  // Convert each object to a CSV row
  const rows = flattenedData.map(obj => {
      return headers.map(header => {
          // If the object has a key that matches the header, return its value; otherwise, return an empty string
          return obj.hasOwnProperty(header) ? `"${obj[header]}"` : '';
      }).join(',');
  });

  // Combine headers and rows, and add newline characters
  const csvString = [headers.join(','), ...rows].join('\n');

  return csvString;
}
