import axios from 'axios'
import { config } from '../../Constants'

export const customersApi = {
    getCustomers,
    getCustomer,
    createCustomer,
    updateCustomer,
    deleteCustomer,
    addCustomerContract,
    addCustomerContact,
    removeCustomerContact,
    getUserMe,
    saveUserMe,
    uploadFile,
    downloadFile,
    deleteFile,
    getFiles
}


async function getCustomers(token, onCallback) {
    try {
        const response = await instance.get('/api/customers', {
            headers: { 'Authorization': bearerAuth(token) }
        });
        const customers = response.data
        if (onCallback) onCallback(customers)
    } catch (error) {
        console.log(error)
    }
}


function getCustomer(id) {
    return instance.get(`/api/customers/${id}`)
}

async function createCustomer(customer, token, onCallback, onError) {
    try {
        const response = await instance.post('/api/customers', customer, {
            headers: { 'Authorization': bearerAuth(token) }
        })
        const customers = response.data
        if (onCallback) onCallback(customers)
        return customers;
    } catch (error) {
        console.log(error)
        if (onError) onError(error)
    }
}

async function updateCustomer(id, customer, token, onCallback, onError) {
    try {
        const response = await instance.put(`/api/customers/${id}`, customer, {
            headers: { 'Authorization': bearerAuth(token) }
        })
        const updatedCustomer = response.data
        if (onCallback) onCallback(updatedCustomer)
    } catch (error) {
        console.log(error)
        if (onError) onError(error)
    }
}

async function deleteCustomer(id, token, onCallback, onError) {
    try {
        const response = await instance.delete(`/api/customers/${id}`, {
            headers: { 'Authorization': bearerAuth(token) }
        })
        const customer = response.data
        if (onCallback) onCallback(customer)
    } catch (error) {
        console.log(error)
        if (onError) onError(error)
    }
}

async function addCustomerContract(id, contract, token, onCallback) {
    try {
        const response = await instance.post(`/api/customers/${id}/contracts`, contract, {
            headers: { 'Authorization': bearerAuth(token) }
        })
        const customers = response.data
        if (onCallback) onCallback(customers)
    } catch (error) {
        console.log(error)
    }
}

async function addCustomerContact(id, userId, token, onCallback) {
    try {
        const response = await instance.put(`/api/customers/${id}/users/${userId}`, null, {
            headers: { 'Authorization': bearerAuth(token) }
        })
        const customers = response.data
        if (onCallback) onCallback(customers)
    } catch (error) {
        console.log(error)
    }
}

async function removeCustomerContact(id, userId, token, onCallback) {
    try {
        const response = await instance.put(`/api/customers/${id}/users/remove/${userId}`, null, {
            headers: { 'Authorization': bearerAuth(token) }
        })
        const customers = response.data
        if (onCallback) onCallback(customers)
    } catch (error) {
        console.log(error)
    }
}


function getUserMe(token) {
    return instance.get(`/api/users/me`, {
        headers: { 'Authorization': bearerAuth(token) }
    })
}

function saveUserMe(token, userExtra) {
    return instance.post(`/api/users/me`, userExtra, {
        headers: { 'Authorization': bearerAuth(token) }
    })
}

async function uploadFile(customerId, file, token, onCallback, onError) {
    try {
        const formData = new FormData();
        formData.append('file', file);

        const response = await instance.post(`/api/customers/${customerId}/files`, formData, {
            headers: {
                'Authorization': bearerAuth(token),
                'Content-Type': 'multipart/form-data'
            }
        });

        const uploadedFile = response.data;
        if (onCallback) onCallback(uploadedFile);
    } catch (error) {
        console.log(error);
        if (onError) onError(error);
    }
}

async function downloadFile(customerId, fileId, token, onCallback, onError) {
    try {
        const response = await instance.get(`/api/customers/${customerId}/files/${fileId}`, {
            headers: { 'Authorization': bearerAuth(token) },
            responseType: 'blob'
        });

        console.log(response);
        const contentDisposition = response.headers.get('Content-Disposition');
        console.log(contentDisposition)
    

        if (contentDisposition) {
            const filename = contentDisposition.split('filename=')[1].replace(/"/g, '');
            const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            if (onCallback) onCallback(response.data);
        } else {
            throw new Error('Content-Disposition header is missing or improperly formatted.');
        }
    } catch (error) {
        console.log(error);
        if (onError) onError(error);
    }
}
async function deleteFile(customerId, fileId, token, onCallback, onError) {
    try {
        const response = await instance.delete(`/api/customers/${customerId}/files/${fileId}`, {
            headers: { 'Authorization': bearerAuth(token) }
        });

        if (onCallback) onCallback(response.data);
    } catch (error) {
        console.log(error);
        if (onError) onError(error);
    }
}

async function getFiles(customerId, token, onCallback, onError) {
    try {
        const response = await instance.get(`/api/customers/${customerId}/files`, {
            headers: { 'Authorization': bearerAuth(token) }
        });

        if (onCallback) onCallback(response.data);
    } catch(error) {
        console.log(error);
        if (onError) onError(error);
    }
}

// -- Axios

const instance = axios.create({
    baseURL: config.url.API_BASE_URL
})

instance.interceptors.response.use(response => {
    return response
}, function (error) {
    if (error.response.status === 404) {
        return { status: error.response.status }
    }
    return Promise.reject(error.response)
})

// -- Helper functions

function bearerAuth(token) {
    return `Bearer ${token}`
}