import * as React from 'react';
import {
    Avatar,
    Box,
    ClickAwayListener,
    Collapse,
    Grow,
    IconButton,
    Paper,
    Popper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Chip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import KeyIcon from '@mui/icons-material/Key';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
    MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import StorageIcon from '@mui/icons-material/Storage';
import ContactsIcon from '@mui/icons-material/Contacts';
import { MenuList, MenuItem } from '@mui/material';
import AttachmensTable from '../attachments/AttachmentsTable';
import Comments from '../comments/Comments';
import { useTranslation } from 'react-i18next';


import { getContractStatus } from '../contracts/Constants';

function Row(props) {
    const { t } = useTranslation();
    const { me, row, noRows, handleRowOpen, handleCommentsChange, handleOpenCustomerDialog, handleOpenContractDialog, handleOpenUserDialog, handleOpenInstanceDialog, handleOpenUserPicker, handleDeleteCustomer, handleDeleteContract, handleDeleteInstance, handleRemoveUser, handleUploadAttachment, handleDownloadAttachment, handleDeleteAttachment, getLicenseKey } = props;
    const anchorRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const [menuOpen, setMenuOpen] = React.useState(false);

    React.useEffect(() => {
        if (open) {
            if (handleRowOpen) handleRowOpen(row)
        }
    }, [open])

    React.useEffect(() => {
        if (noRows === 1) setOpen(true);
        else setOpen(false);
    }, [noRows]);

    const handleToggle = () => {
        setMenuOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setMenuOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setMenuOpen(false);
        } else if (event.key === 'Escape') {
            setMenuOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [menuOpen]);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {`ID: ${row.id}`}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>

                <TableCell component="th" scope="row">
                    {row.department}
                </TableCell>

                <TableCell align="right">{row.town}</TableCell>
                <TableCell align="right">{row.street}</TableCell>
                <TableCell align="right">{row.country}</TableCell>
                {/* <TableCell align="right">
                    <NavLink
                        to={"/contracts/" + row.id}
                        className={({ isActive, isPending }) =>
                            isPending ? "pending" : isActive ? "active" : ""
                        }
                    >
                        Contracts
                    </NavLink>
                </TableCell> */}
                {/* <TableCell align="right">
                    <NavLink
                        to="/users"
                        className={({ isActive, isPending }) =>
                            isPending ? "pending" : isActive ? "active" : ""
                        }
                    >
                        Users
                    </NavLink>
                </TableCell> */}
                <TableCell align="right">
                    <div>
                        <IconButton color="primary" aria-label="add to shopping cart"
                            ref={anchorRef}
                            id="composition-button"
                            aria-controls={open ? 'composition-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}>
                            <MoreVertIcon />
                        </IconButton>
                        <Popper
                            style={{ zIndex: 1000 }}
                            open={menuOpen}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement="bottom-start"
                            transition

                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                                    }}
                                >
                                    <Paper >
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList
                                                autoFocusItem={menuOpen}
                                                id="composition-menu"
                                                aria-labelledby="composition-button"
                                                onKeyDown={handleListKeyDown}
                                            >
                                                <MenuItem onClick={(e) => {
                                                    handleClose(e);
                                                    handleOpenCustomerDialog(row);
                                                }}>{t('customersTable.menu.edit')}</MenuItem>
                                                <MenuItem onClick={(e) => {
                                                    handleClose(e);
                                                    handleOpenContractDialog(row, null);
                                                }}>{t('customersTable.menu.addContract')}</MenuItem>
                                                <MenuItem onClick={(e) => {
                                                    handleClose(e);
                                                    handleOpenUserPicker({ open: true, selectedCustomer: row });
                                                }}>{t('customersTable.menu.addContact')}</MenuItem>
                                                <MenuItem onClick={(e) => {
                                                    handleClose(e);
                                                    handleOpenInstanceDialog(row);
                                                }}>{t('customersTable.menu.addInstance')}</MenuItem>
                                                <MenuItem onClick={(e) => {
                                                    handleDeleteCustomer(row.id);
                                                    handleClose(e);
                                                }}>{t('customersTable.menu.delete')}</MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Box sx={{ display: "flex", alignItems: "end", gap: 1 }}>
                                <Box>
                                    <MapsHomeWorkIcon sx={{ fontSize: 32 }} />
                                </Box>
                                <Typography variant="h6" gutterBottom component="div" sx={{ marginTop: 2 }}>
                                    {t('customersTable.details.title')}
                                </Typography>
                                <Box sx={{ flexGrow: 1 }} />
                                <Box>
                                    <IconButton color="primary" variant="filled" aria-label='Edit customer' onClick={() => {
                                        handleOpenCustomerDialog(row);
                                    }}>
                                        <EditIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            {/* Customer Address Details */}
                            <Table size="small" aria-label="customer details">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('customersTable.details.street')}</TableCell>
                                        <TableCell>{t('customersTable.details.city')}</TableCell>
                                        <TableCell>{t('customersTable.details.zipCode')}</TableCell>
                                        <TableCell>{t('customersTable.details.country')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={"row-compay-address"}>

                                        <TableCell>{row.street}</TableCell>
                                        <TableCell>{row.town}</TableCell>
                                        <TableCell>{row.zipCode}</TableCell>
                                        <TableCell>{row.country}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                            <Box sx={{ display: "flex", alignItems: "end", gap: 1 }}>
                                <Box>
                                    <QuestionAnswerIcon sx={{ fontSize: 32 }} />
                                </Box>
                                <Typography variant="h6" gutterBottom component="div" sx={{ marginTop: 2 }}>
                                    {t('comments.comments')}
                                </Typography>
                                <Box sx={{ flexGrow: 1 }} />
                           
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "end", gap: 1 }}>
                                <Comments
                                    comments={row.comments}
                                    currentUserId={me?.id}
                                    selectedCustomerId={row.id}
                                    onSave={() => handleCommentsChange(row.id)}
                                    onUpdate={() => handleCommentsChange(row.id)}
                                    onDelete={() => handleCommentsChange(row.id)} />
                            </Box>

                            {/* Contract Details Table */}
                            <Box sx={{ display: "flex", alignItems: "end", gap: 1 }}>
                                <Box>
                                    <HistoryEduIcon sx={{ fontSize: 32 }} />
                                </Box>
                                <Typography variant="h6" gutterBottom component="div" sx={{ marginTop: 2 }}>
                                    {t('customersTable.contracts.contracts')}
                                </Typography>
                                <Box sx={{ flexGrow: 1 }} />
                                <Box>
                                    <IconButton color="primary" aria-label="add contract" onClick={() => {
                                        handleOpenContractDialog(row, null);
                                    }}>
                                        <AddIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Table size="small" aria-label="contract details">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>{t('customersTable.contracts.status')}</TableCell>
                                        <TableCell>{t('customersTable.contracts.start')}</TableCell>
                                        <TableCell>{t('customersTable.contracts.end')}</TableCell>
                                        <TableCell>{t('customersTable.contracts.version')}</TableCell>
                                        <TableCell>{t('customersTable.contracts.userCount')}</TableCell>
                                        <TableCell align="right">{t('customersTable.contracts.actions')}</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {row.contracts && row.contracts.map((contract, index) => {
                                        const status = getContractStatus(contract.endDate);
                                        let statusTableCell = null
                                        if (status === "Active") {
                                            statusTableCell = <Chip label={status} color="success" />
                                        } else if (status === "Expired") {
                                            statusTableCell = <Chip label={status} color="error" />
                                        } else if (status === "Due Soon") {
                                            statusTableCell = <Chip label={status} color="warning" />
                                        } else if (status === "Expired Recently") {
                                            statusTableCell = <Chip label={status} color="error" />
                                        } else {
                                            statusTableCell = <Chip label={status} color="info" />
                                        }
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>{contract.id}</TableCell>
                                                <TableCell>{statusTableCell}</TableCell>
                                                <TableCell>{contract.startDate}</TableCell>
                                                <TableCell>{contract.endDate}</TableCell>
                                                <TableCell>{contract.versionNumber}</TableCell>
                                                <TableCell>{`Task: ${contract.noTask}, Service: ${contract.noService}, Project: ${contract.noProject}`}</TableCell>
                                                <TableCell align="right">
                                                    <IconButton color="primary" onClick={() => {
                                                        handleDeleteContract(row.id, contract.id);
                                                    }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                    <IconButton aria-label="generate license key" color="primary" onClick={() => getLicenseKey(contract.id)}>
                                                        <KeyIcon />
                                                    </IconButton>
                                                    <IconButton color="primary" onClick={() => {
                                                        handleOpenContractDialog(row, contract);
                                                    }}>
                                                        <EditIcon />
                                                    </IconButton>

                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                            <Box sx={{ display: "flex", alignItems: "end", gap: 1 }}>
                                <Box>
                                    <StorageIcon sx={{ fontSize: 32 }} />
                                </Box>
                                <Typography variant="h6" gutterBottom component="div" sx={{ marginTop: 2 }}>
                                    {t('customersTable.instances.instances')}
                                </Typography>
                                <Box sx={{ flexGrow: 1 }} />
                                <Box>
                                    <Box>
                                        <IconButton color="primary" aria-label="add instance" onClick={() => { handleOpenInstanceDialog(row); }}>
                                            <AddIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                            <Table size="small" aria-label="contract details">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>{t('customersTable.instances.status')}</TableCell>
                                        <TableCell>{t('customersTable.instances.contractId')}</TableCell>
                                        <TableCell>{t('customersTable.instances.type')}</TableCell>
                                        <TableCell>{t('customersTable.instances.name')}</TableCell>
                                        <TableCell>{t('customersTable.instances.ipAddress')}</TableCell>
                                        <TableCell align="right">{t('customersTable.instances.actions')}</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {row.instances && row.instances.map((instance, index) => {

                                        let instanceStatusTableCell = null
                                        if (instance.status === "Active") {
                                            instanceStatusTableCell = <Chip label={instance.status} color="success" />
                                        }
                                        else if (instance.status === "Inactive") {
                                            instanceStatusTableCell = <Chip label={instance.status} color="info" />
                                        }
                                        else {
                                            instanceStatusTableCell = <Chip label={instance.status} color="info" />
                                        }

                                        return (
                                            <TableRow key={index}>
                                                <TableCell>{instance.id}</TableCell>
                                                <TableCell>{instanceStatusTableCell}</TableCell>
                                                <TableCell>{instance.contract.id}</TableCell>
                                                <TableCell>{instance.type}</TableCell>
                                                <TableCell>{instance.name}</TableCell>
                                                <TableCell>{instance.ipAddress}</TableCell>
                                                <TableCell align="right">
                                                    <IconButton color="primary" onClick={() => {
                                                        handleDeleteInstance(row.id, instance.id);
                                                    }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                    <IconButton color="primary" onClick={() => {
                                                        handleOpenInstanceDialog(row, instance.contract, instance);
                                                    }}>
                                                        <EditIcon />
                                                    </IconButton>

                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>

                            <Box sx={{ display: "flex", alignItems: "end", gap: 1 }}>
                                <Box>
                                    <ContactsIcon sx={{ fontSize: 32 }} />
                                </Box>
                                <Typography variant="h6" gutterBottom component="div" sx={{ marginTop: 2 }}>
                                    {t('customersTable.contacts.contacts')}
                                </Typography>
                                <Box sx={{ flexGrow: 1 }} />
                                <Box>
                                    <Box>
                                        <IconButton color="primary" aria-label="add contact" onClick={() => {
                                            handleOpenUserPicker({ open: true, selectedCustomer: row });
                                        }}>
                                            <AddIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                            {/* Customer Address Details */}
                            <Table size="small" aria-label="customer contact details">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('customersTable.contacts.avatar')}</TableCell>
                                        <TableCell>{t('customersTable.contacts.contactPerson')}</TableCell>
                                        <TableCell>{t('customersTable.contacts.email')}</TableCell>
                                        <TableCell>{t('customersTable.contacts.phone')}</TableCell>
                                        <TableCell>{t('customersTable.contacts.isAdmin')}</TableCell>
                                        <TableCell align="right">{t('customersTable.contacts.actions')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.users && row.users.map((contactPerson) => (
                                        <TableRow key={contactPerson.email}>
                                            <TableCell><Avatar>{`${contactPerson?.firstName?.charAt(0).toUpperCase()} ${contactPerson?.lastName?.charAt(0).toUpperCase()}`}</Avatar></TableCell>
                                            <TableCell>{`${contactPerson.firstName} ${contactPerson.lastName}`}</TableCell>
                                            <TableCell>{contactPerson.email}</TableCell>
                                            <TableCell>{`${contactPerson.phoneNumber1} ${contactPerson.phoneNumber2}`}</TableCell>
                                            <TableCell>{`${contactPerson.isAdmin}`}</TableCell>
                                            <TableCell align="right">
                                                <IconButton color="primary" onClick={() => {
                                                    handleRemoveUser(row.id, contactPerson.id);
                                                }}>
                                                    <PersonRemoveIcon />
                                                </IconButton>
                                                <IconButton color="primary" onClick={() => {
                                                    handleOpenUserDialog(row, contactPerson);
                                                }}>
                                                    <EditIcon />
                                                </IconButton>

                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                            <AttachmensTable
                                attachments={row.attachments}
                                uploadFile={(file) => handleUploadAttachment(row.id, file)}
                                downloadFile={(fileId) => handleDownloadAttachment(row.id, fileId)}
                                deleteFile={(fileId) => handleDeleteAttachment(row.id, fileId)}
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    );
}

export default function CustomerTable(props) {
    const {
        me,
        customers,
        handleRowOpen,
        handleCommentsChange,
        handleOpenCustomerDialog,
        handleOpenContractDialog,
        handleOpenUserDialog,
        handleOpenInstanceDialog,
        handleOpenUserPicker,
        handleDeleteCustomer,
        handleDeleteContract,
        handleDeleteInstance,
        handleRemoveUser,
        handleUploadAttachment,
        handleDownloadAttachment,
        handleDeleteAttachment,
        getLicenseKey,
    } = props;
    const rows = customers;
    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableBody>
                    {rows.map((row) => (
                        <Row
                            me={me}
                            key={row.name}
                            row={row}
                            noRows={rows.length}
                            handleRowOpen={handleRowOpen}
                            handleCommentsChange={handleCommentsChange}
                            handleOpenCustomerDialog={handleOpenCustomerDialog}
                            handleOpenContractDialog={handleOpenContractDialog}
                            handleOpenUserDialog={handleOpenUserDialog}
                            handleOpenInstanceDialog={handleOpenInstanceDialog}
                            handleOpenUserPicker={handleOpenUserPicker}
                            handleDeleteCustomer={handleDeleteCustomer}
                            handleDeleteContract={handleDeleteContract}
                            handleDeleteInstance={handleDeleteInstance}
                            handleRemoveUser={handleRemoveUser}
                            handleUploadAttachment={handleUploadAttachment}
                            handleDownloadAttachment={handleDownloadAttachment}
                            handleDeleteAttachment={handleDeleteAttachment}
                            getLicenseKey={getLicenseKey}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
